import { Icon } from '@wello-client/common';

import { Button } from '@/components/server';
import { useGlobalStore } from '@/stores/GlobalStore';

import styles from './CardUserActionFooter.module.scss';

export interface CardUserActionFooterProps {
  isThumbUp?: boolean;
  thumbUpCount?: number;
  commentCount?: number;
  isBookmarked?: boolean;
  onClickThumbUp?: () => void;
  onClickBookmark?: () => void;
  onClickComment?: () => void;
  shareLink?: string;
}

export const CardUserActionFooter = ({
  thumbUpCount = 0,
  commentCount = 0,
  isBookmarked,
  isThumbUp,
  onClickThumbUp,
  onClickComment,
  onClickBookmark,
  shareLink,
}: CardUserActionFooterProps) => {
  return (
    <footer className={styles.container}>
      <Button className={styles.button} theme="unset" onClick={onClickThumbUp}>
        <Icon name={isThumbUp ? 'Like-1-Fill' : 'Like-1'} />
        <span>{thumbUpCount.toLocaleString()}</span>
      </Button>
      <Button className={styles.button} theme="unset" onClick={onClickComment}>
        <Icon name="Chat-Bubble-Typing-Oval" />
        <span>{commentCount.toLocaleString()}</span>
      </Button>
      <Button
        aria-label={isBookmarked ? '북마크 제거' : '북마크 추가'}
        className={styles.button}
        theme="unset"
        onClick={onClickBookmark}
      >
        <Icon name={isBookmarked ? 'Bookmark-Fill' : 'Bookmark'} />
        <span>스크랩</span>
      </Button>
      <Button
        className={styles.button}
        theme="unset"
        onClick={() => {
          useGlobalStore
            .getState()
            .setShareLink(shareLink ?? window.location.href);
        }}
      >
        <Icon name="Share-Link" />
        <span>공유</span>
      </Button>
    </footer>
  );
};
