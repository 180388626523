/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { AdminEmailLoginRequest } from '../models/AdminEmailLoginRequest';
import type { B2bEmailLoginRequest } from '../models/B2bEmailLoginRequest';
import type { B2bLoginV2Request } from '../models/B2bLoginV2Request';
import type { DefaultHttpResponse_BeforeLoginResponse_ } from '../models/DefaultHttpResponse_BeforeLoginResponse_';
import type { DefaultHttpResponse_DuplicateMemberInfoResponse_ } from '../models/DefaultHttpResponse_DuplicateMemberInfoResponse_';
import type { DefaultHttpResponse_KbMemberLoginTypeResponse_ } from '../models/DefaultHttpResponse_KbMemberLoginTypeResponse_';
import type { DefaultHttpResponse_List_ListCompanyMemberCompanyDto_ } from '../models/DefaultHttpResponse_List_ListCompanyMemberCompanyDto_';
import type { DefaultHttpResponse_ListUnifiedLoginInfoResponse_ } from '../models/DefaultHttpResponse_ListUnifiedLoginInfoResponse_';
import type { DefaultHttpResponse_OAuthTokenResponse_ } from '../models/DefaultHttpResponse_OAuthTokenResponse_';
import type { DefaultHttpResponse_TokenStatusCheckResponse_ } from '../models/DefaultHttpResponse_TokenStatusCheckResponse_';
import type { InstitutionEmailLoginRequest } from '../models/InstitutionEmailLoginRequest';
import type { InstitutionLoginRequest } from '../models/InstitutionLoginRequest';
import type { SnsLoginRequest } from '../models/SnsLoginRequest';
import type { TokenStatusCheckRequest } from '../models/TokenStatusCheckRequest';
import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';
export class MemberOauthApiService {
    /**
     * admin Email 로그인
     * @returns DefaultHttpResponse_OAuthTokenResponse_ OK
     * @throws ApiError
     */
    public static loginAdminMemberByEmailUsingPost1({
        requestBody,
    }: {
        requestBody?: AdminEmailLoginRequest,
    }): CancelablePromise<DefaultHttpResponse_OAuthTokenResponse_> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/wello/v2/allows/member-oauth/admin/login/email',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                500: `SQL 오류 및 기타 미처리 예외 상황입니다.`,
                502: `외부 API 오류 입니다.`,
            },
        });
    }
    /**
     * admin Token Refresh
     * @returns DefaultHttpResponse_OAuthTokenResponse_ OK
     * @throws ApiError
     */
    public static adminRefreshTokenUsingGet1({
        refreshToken,
    }: {
        /**
         * refresh_token
         */
        refreshToken: string,
    }): CancelablePromise<DefaultHttpResponse_OAuthTokenResponse_> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/wello/v2/allows/member-oauth/admin/refresh_token',
            query: {
                'refresh_token': refreshToken,
            },
            errors: {
                500: `SQL 오류 및 기타 미처리 예외 상황입니다.`,
                502: `외부 API 오류 입니다.`,
            },
        });
    }
    /**
     * admin sns 로그인
     * @returns DefaultHttpResponse_OAuthTokenResponse_ OK
     * @throws ApiError
     */
    public static loginAdminSnsMemberUsingPost1({
        requestBody,
    }: {
        requestBody?: SnsLoginRequest,
    }): CancelablePromise<DefaultHttpResponse_OAuthTokenResponse_> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/wello/v2/allows/member-oauth/admin/sns/login',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                500: `SQL 오류 및 기타 미처리 예외 상황입니다.`,
                502: `외부 API 오류 입니다.`,
            },
        });
    }
    /**
     * 웰로비즈 before Email 로그인
     * @returns DefaultHttpResponse_BeforeLoginResponse_ OK
     * @throws ApiError
     */
    public static beforeLoginB2BMemberByGoogleUsingPost1({
        requestBody,
    }: {
        requestBody?: B2bEmailLoginRequest,
    }): CancelablePromise<DefaultHttpResponse_BeforeLoginResponse_> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/wello/v2/allows/member-oauth/b2b/before/login/email',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                500: `SQL 오류 및 기타 미처리 예외 상황입니다.`,
                502: `외부 API 오류 입니다.`,
            },
        });
    }
    /**
     * 웰로비즈 before SNS 로그인
     * @returns DefaultHttpResponse_List_ListCompanyMemberCompanyDto_ OK
     * @throws ApiError
     */
    public static beforeLoginB2BMemberBySnsUsingPost1({
        requestBody,
    }: {
        requestBody?: B2bLoginV2Request,
    }): CancelablePromise<DefaultHttpResponse_List_ListCompanyMemberCompanyDto_> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/wello/v2/allows/member-oauth/b2b/before/sns-login/',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                500: `SQL 오류 및 기타 미처리 예외 상황입니다.`,
                502: `외부 API 오류 입니다.`,
            },
        });
    }
    /**
     * 웰로비즈 Email 로그인
     * @returns DefaultHttpResponse_OAuthTokenResponse_ OK
     * @throws ApiError
     */
    public static loginB2BMemberByEmailUsingPost1({
        companyId,
        requestBody,
    }: {
        /**
         * company_id
         */
        companyId: number,
        requestBody?: B2bEmailLoginRequest,
    }): CancelablePromise<DefaultHttpResponse_OAuthTokenResponse_> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/wello/v2/allows/member-oauth/b2b/login/email',
            headers: {
                'company_id': companyId,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                500: `SQL 오류 및 기타 미처리 예외 상황입니다.`,
                502: `외부 API 오류 입니다.`,
            },
        });
    }
    /**
     * 웰로비즈 Token Refresh
     * @returns DefaultHttpResponse_OAuthTokenResponse_ OK
     * @throws ApiError
     */
    public static b2BRefreshTokenUsingGet1({
        refreshToken,
    }: {
        /**
         * refresh_token
         */
        refreshToken: string,
    }): CancelablePromise<DefaultHttpResponse_OAuthTokenResponse_> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/wello/v2/allows/member-oauth/b2b/refresh_token',
            query: {
                'refresh_token': refreshToken,
            },
            errors: {
                500: `SQL 오류 및 기타 미처리 예외 상황입니다.`,
                502: `외부 API 오류 입니다.`,
            },
        });
    }
    /**
     * 웰로비즈 SNS 로그인
     * @returns DefaultHttpResponse_OAuthTokenResponse_ OK
     * @throws ApiError
     */
    public static loginB2BMemberByGoogleUsingPost1({
        companyId,
        requestBody,
    }: {
        /**
         * company_id
         */
        companyId: number,
        requestBody?: B2bLoginV2Request,
    }): CancelablePromise<DefaultHttpResponse_OAuthTokenResponse_> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/wello/v2/allows/member-oauth/b2b/sns-login/',
            headers: {
                'company_id': companyId,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                500: `SQL 오류 및 기타 미처리 예외 상황입니다.`,
                502: `외부 API 오류 입니다.`,
            },
        });
    }
    /**
     * 웰로비즈 SNS 통합 계정 확인
     * @returns DefaultHttpResponse_ListUnifiedLoginInfoResponse_ OK
     * @throws ApiError
     */
    public static b2BUnifiedMemberInfoCheckUsingGet1({
        accessToken,
        loginType,
    }: {
        /**
         * SNS access_token
         */
        accessToken: string,
        /**
         * SNS 로그인 타입
         */
        loginType?: string,
    }): CancelablePromise<DefaultHttpResponse_ListUnifiedLoginInfoResponse_> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/wello/v2/allows/member-oauth/b2b/unified/sns-login/',
            query: {
                'access_token': accessToken,
                'loginType': loginType,
            },
            errors: {
                500: `SQL 오류 및 기타 미처리 예외 상황입니다.`,
                502: `외부 API 오류 입니다.`,
            },
        });
    }
    /**
     * 웰로 before SNS 로그인
     * @returns DefaultHttpResponse_DuplicateMemberInfoResponse_ OK
     * @throws ApiError
     */
    public static welloBeforeLoginUsingPost1({
        requestBody,
    }: {
        requestBody?: SnsLoginRequest,
    }): CancelablePromise<DefaultHttpResponse_DuplicateMemberInfoResponse_> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/wello/v2/allows/member-oauth/before/sns/login',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                500: `SQL 오류 및 기타 미처리 예외 상황입니다.`,
                502: `외부 API 오류 입니다.`,
            },
        });
    }
    /**
     * 웰로링크 Email 로그인(기관)
     * @returns DefaultHttpResponse_OAuthTokenResponse_ OK
     * @throws ApiError
     */
    public static loginInstitutionMemberByEmailUsingPost1({
        institutionId,
        requestBody,
    }: {
        /**
         * institution_id
         */
        institutionId: number,
        requestBody?: InstitutionEmailLoginRequest,
    }): CancelablePromise<DefaultHttpResponse_OAuthTokenResponse_> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/wello/v2/allows/member-oauth/institution/login/email',
            headers: {
                'institution_id': institutionId,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                500: `SQL 오류 및 기타 미처리 예외 상황입니다.`,
                502: `외부 API 오류 입니다.`,
            },
        });
    }
    /**
     * 웰로링크 SNS 로그인(기관)
     * @returns DefaultHttpResponse_OAuthTokenResponse_ OK
     * @throws ApiError
     */
    public static loginInstitutionMemberBySnsUsingPost1({
        institutionId,
        requestBody,
    }: {
        /**
         * institution_id
         */
        institutionId: number,
        requestBody?: InstitutionLoginRequest,
    }): CancelablePromise<DefaultHttpResponse_OAuthTokenResponse_> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/wello/v2/allows/member-oauth/institution/sns-login/',
            headers: {
                'institution_id': institutionId,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                500: `SQL 오류 및 기타 미처리 예외 상황입니다.`,
                502: `외부 API 오류 입니다.`,
            },
        });
    }
    /**
     * 웰로링크 SNS 통합 계정 확인(기관)
     * @returns DefaultHttpResponse_ListUnifiedLoginInfoResponse_ OK
     * @throws ApiError
     */
    public static institutionUnifiedMemberInfoCheckUsingGet1({
        accessToken,
        loginType,
    }: {
        /**
         * SNS access_token
         */
        accessToken: string,
        /**
         * 로그인 타입
         */
        loginType: string,
    }): CancelablePromise<DefaultHttpResponse_ListUnifiedLoginInfoResponse_> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/wello/v2/allows/member-oauth/institution/unified/sns-login/',
            query: {
                'access_token': accessToken,
                'login_type': loginType,
            },
            errors: {
                500: `SQL 오류 및 기타 미처리 예외 상황입니다.`,
                502: `외부 API 오류 입니다.`,
            },
        });
    }
    /**
     * kb 연동된 로그인 타입 목록 조회
     * @returns DefaultHttpResponse_KbMemberLoginTypeResponse_ OK
     * @throws ApiError
     */
    public static getKbMemberLoginTypeListUsingGet({
        externalMemberId,
    }: {
        /**
         * kb 식별자 ID
         */
        externalMemberId: string,
    }): CancelablePromise<DefaultHttpResponse_KbMemberLoginTypeResponse_> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/wello/v2/allows/member-oauth/kb-member-login-type',
            query: {
                'external_member_id': externalMemberId,
            },
            errors: {
                500: `SQL 오류 및 기타 미처리 예외 상황입니다.`,
                502: `외부 API 오류 입니다.`,
            },
        });
    }
    /**
     * 웰로 sns 로그인
     * @returns DefaultHttpResponse_OAuthTokenResponse_ OK
     * @throws ApiError
     */
    public static loginWelloSnsMemberUsingPost1({
        requestBody,
    }: {
        requestBody?: SnsLoginRequest,
    }): CancelablePromise<DefaultHttpResponse_OAuthTokenResponse_> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/wello/v2/allows/member-oauth/sns/login',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                500: `SQL 오류 및 기타 미처리 예외 상황입니다.`,
                502: `외부 API 오류 입니다.`,
            },
        });
    }
    /**
     * 웰로 Token Refresh
     * @returns DefaultHttpResponse_OAuthTokenResponse_ OK
     * @throws ApiError
     */
    public static snsRefreshTokenUsingGet1({
        refreshToken,
    }: {
        /**
         * refresh_token
         */
        refreshToken: string,
    }): CancelablePromise<DefaultHttpResponse_OAuthTokenResponse_> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/wello/v2/allows/member-oauth/sns/refresh_token',
            query: {
                'refresh_token': refreshToken,
            },
            errors: {
                500: `SQL 오류 및 기타 미처리 예외 상황입니다.`,
                502: `외부 API 오류 입니다.`,
            },
        });
    }
    /**
     * Token 유효성 체크
     * @returns DefaultHttpResponse_TokenStatusCheckResponse_ OK
     * @throws ApiError
     */
    public static tokenStatusCheckUsingPost({
        requestBody,
    }: {
        requestBody?: TokenStatusCheckRequest,
    }): CancelablePromise<DefaultHttpResponse_TokenStatusCheckResponse_> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/wello/v2/allows/member-oauth/token-status-check',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                500: `SQL 오류 및 기타 미처리 예외 상황입니다.`,
                502: `외부 API 오류 입니다.`,
            },
        });
    }
}
