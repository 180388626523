import { GTM_ID } from './AppGlobalScript';

export const AppGtmNoScript = () => (
  <noscript>
    <iframe
      height="0"
      src={`https://www.googletagmanager.com/ns.html?id=${GTM_ID}`}
      style={{ display: 'none', visibility: 'hidden' }}
      title="GTM"
      width="0"
    />
  </noscript>
);
