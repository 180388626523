interface StorageOptions {
  unit?: 'bytes' | 'KB' | 'MB' | 'GB';
  precision?: number;
}

export const format = {
  phone: (phone?: string) => {
    if (phone?.length !== 11) return phone;

    return `${phone.slice(0, 3)}-${phone.slice(3, 7)}-${phone.slice(7)}`;
  },
  storage: (
    bytes = 0,
    options: StorageOptions = { unit: 'MB', precision: 2 },
  ) => {
    const kiloBytes = bytes / 1024;
    const megaBytes = bytes / 1048576; // 1024 * 1024
    const gigaBytes = bytes / 1073741824; // 1024 * 1024 * 1024

    const { unit = 'MB', precision = 2 } = options;

    switch (unit) {
      case 'bytes':
        return `${bytes} bytes`;
      case 'KB':
        return `${kiloBytes.toFixed(precision)}KB`;
      case 'MB':
        return `${megaBytes.toFixed(precision)}MB`;
      case 'GB':
        return `${gigaBytes.toFixed(precision)}GB`;
      default:
        throw new Error('정의하지 않은 단위 유형이에요.');
    }
  },
};
