export const isKbWebview = () =>
  navigator.userAgent.toLowerCase().match(/liivmate|kbpayia/);

export const nativeCall = (
  callName: string,
  successFunc: string,
  failFunc: string,
  data: string,
) => {
  if (isKbWebview()) {
    const params = encodeURIComponent(data);
    let url = '';

    url = 'liivmate://call?cmd=external';
    url += '&name=MobileWeb.' + callName;
    url += '&successCallback=' + successFunc;
    url += '&failCallback=' + failFunc;
    url += '&params=' + params;

    location.href = url;
  }
};
