import * as yup from 'yup';

type Yup = typeof yup;

/** @deprecated createYupSchemaResolver를 사용해주세요. */
export const createFormSchema = <TBaseInterface extends Object>(
  factory: (yup: Yup) => {
    [K in keyof TBaseInterface]: any;
  },
) => yup.object().shape(factory(yup));
