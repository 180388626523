export * from './html';
export * from './tabs';

export type Size = 'small' | 'medium' | 'large' | 'x-large';

export interface LoginPageParams {
  /** 로그아웃 시켜서 로그인 페이지로 들어옴 */
  logout?: boolean | null;
  /** 로그인 후, redirectOnClose 옵션이 켜져있을때 X버튼 클릭 시 redirect 시켜줄 path*/
  redirect?: string | null;
  /** X 버튼 클릭 시 redirect path로 보내줄지 여부 기본값은 뒤로가기 redirect 없이 활성화 시 home으로 이동*/
  redirectOnClose?: boolean | null;
  /** SNS로그인 페이지 외 다른 링크들을 비활성할지 여부 */
  disableOutlink?: boolean | null;
}

export interface Comment {
  contentId: number;
  commentId: number;
  commentIdIdx: string;
  commentTypeCd: string;
  profileImage?: string;
  nickname: string;
  createdAt?: string;
  contents: string;
  thumbUpCount: number;
  isMyThumbUp: boolean;
  isDeleted: boolean;
  isMine: boolean;
  parentCommentId?: number;
  rootCommentId?: number;
  title?: string;
}

export interface Region {
  region?: string;
  subRegion?: string;
  codeRegion?: string;
  codeSubRegion?: string;
}

export interface RootComment extends Comment {
  childCommentList: Comment[];
}

export interface CrossDomainCallbackPageParams {
  accessToken: string;
  refreshToken: string;
  redirect?: string;
  redirectSearchParams?: {
    [key: string]: string;
  };
}

export type NonEmptyArray<T> = readonly [T, ...T[]];
