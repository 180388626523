import type { HTMLTagProps } from '@/types';

import { useEffect } from 'react';
import { createPortal } from 'react-dom';

import { cleanClassName } from '@wello-client/common/utils';

import { MODAL_CLOSING_STATE, useModalClosing } from '@/hooks/useModalClosing';

import styles from './Drawer.module.scss';

export interface DrawerProps extends HTMLTagProps<'article'> {
  zIndex?: number;
  blurredBackground?: boolean;
  opened?: boolean;
  onClose?: () => void;
  backgroundScroll?: boolean;
  modalContentOverflow?: boolean;
}

export const Drawer = ({
  //* Modal props
  zIndex = 50,

  opened = true,
  onClose,
  backgroundScroll = false,
  modalContentOverflow = true,

  //* HTML article props
  className,
  children,
  ...restArticleProps
}: DrawerProps) => {
  const openingTransition = useModalClosing({
    opened,
    closingDuration: 200,
  });

  useEffect(() => {
    const { classList } = document.body;
    const active = !!openingTransition;

    if (active) {
      classList.add(styles['body-size']);
      if (!backgroundScroll) classList.add(styles['fixed-body']);

      return () => {
        classList.remove(styles['body-size']);
        if (!backgroundScroll) classList.remove(styles['fixed-body']);
      };
    }
  }, [openingTransition, backgroundScroll]);

  return openingTransition ? (
    createPortal(
      <div
        className={cleanClassName(
          `${styles['modal-container']} ${
            openingTransition === MODAL_CLOSING_STATE.CLOSING && styles.closing
          }`,
        )}
        style={{
          zIndex,
        }}
      >
        <div className={styles['modal-wrap']}>
          <div className={styles['background-layer']} onClick={onClose} />
          <article
            {...restArticleProps}
            className={cleanClassName(
              `${styles.modal} ${
                openingTransition === MODAL_CLOSING_STATE.CLOSING &&
                styles.closing
              } ${className}`,
            )}
          >
            <div
              className={cleanClassName(
                `${modalContentOverflow ? styles['modal-contents'] : styles['modal-contents-visible']}`,
              )}
            >
              {children}
            </div>
          </article>
        </div>
      </div>,
      document.body,
    )
  ) : (
    <></>
  );
};
