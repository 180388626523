import type { AccordionContextProviderProps } from './contexts/AccordionContext';
import type { HTMLTagProps } from '@/types';

import { ChevronDown } from 'react-feather';

import { cleanClassName } from '@wello-client/common/utils';

import styles from './AccordionTitle.module.scss';
import { useAccordionContext } from './contexts/AccordionContext';

export type AccordionTitleProps = HTMLTagProps<'dt'> &
  Pick<AccordionContextProviderProps, 'ellipsis'>;

export const AccordionTitle = ({
  children,
  className,
  ellipsis,

  ...restDtProps
}: AccordionTitleProps) => {
  const {
    openedState: [opened, setOpened],
  } = useAccordionContext();

  return (
    <dt
      {...restDtProps}
      className={cleanClassName(
        `${styles['accordion-title-wrap']} ${className}`,
      )}
    >
      <button
        className={styles['accordion-title']}
        type="button"
        onClick={() => setOpened(() => !opened)}
      >
        <div
          className={cleanClassName(
            `${styles['title-contents']}  ${ellipsis && styles.ellipsis}`,
          )}
        >
          {children}
        </div>
        <ChevronDown
          className={cleanClassName(
            `${styles['chevron-down-icon']} ${opened && styles.opened}`,
          )}
        />
      </button>
    </dt>
  );
};
