import { useEffect, useState } from 'react';

/**
 *
 * @description 특정 상황에서 열림 상태의 변경을 막을 수 있는 기능을 제공합니다.
 */
export const useClosableOnClickOpeningState = () => {
  const openingState = useState(false);
  const [opened, setOpened] = openingState;

  let closable = false;

  useEffect(() => {
    if (opened) {
      const handleClickedOutside = () => {
        if (closable) setOpened(false);
      };

      if (window) {
        window.addEventListener('click', handleClickedOutside);

        return () => window.removeEventListener('click', handleClickedOutside);
      }
    }
  }, [closable, opened, setOpened]);

  return {
    openingState,
    setClosableOnClick: (value: boolean) => {
      closable = value;
    },
  };
};
