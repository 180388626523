'use client';

import { Suspense, lazy, useMemo } from 'react';

import { cleanClassName } from '../../utils';

import styles from './index.module.scss';

type IconList =
  | 'Adobe'
  | 'Affordable-And-Clean-Energy'
  | 'Ai-Chip-Spark'
  | 'Ai-Cloud-Spark'
  | 'Ai-Edit-Spark'
  | 'Ai-Email-Generator-Spark'
  | 'Ai-Gaming-Spark'
  | 'Ai-Generate-Landscape-Image-Spark'
  | 'Ai-Generate-Music-Spark'
  | 'Ai-Generate-Portrait-Image-Spark'
  | 'Ai-Generate-Variation-Spark'
  | 'Ai-Navigation-Spark'
  | 'Ai-Network-Spark'
  | 'Ai-Prompt-Spark'
  | 'Ai-Redo-Spark'
  | 'Ai-Science-Spark'
  | 'Ai-Settings-Spark'
  | 'Ai-Technology-Spark'
  | 'Ai-Upscale-Spark'
  | 'Ai-Vehicle-Spark-1'
  | 'Airplane-Disabled'
  | 'Airplane-Enabled'
  | 'Airplane'
  | 'Airport-Plane-Transit'
  | 'Airport-Plane'
  | 'Airport-Security'
  | 'Alien'
  | 'Alt'
  | 'Amazon'
  | 'Ambulance'
  | 'Anchor'
  | 'Android'
  | 'Announcement-Megaphone'
  | 'App-Store'
  | 'Apple'
  | 'Application-Add'
  | 'Arrow-Down-4'
  | 'Arrow-Left'
  | 'Arrow-Right'
  | 'Arrow-Up-2'
  | 'Artificial-Intelligence-Spark'
  | 'Asterisk-1'
  | 'Auto-Flash'
  | 'Back-Camera-1'
  | 'Backpack'
  | 'Bacteria-Virus-Cells-Biology'
  | 'Bag-Dollar'
  | 'Bag-Pound'
  | 'Bag-Rupee'
  | 'Bag-Suitcase-1'
  | 'Bag-Suitcase-2'
  | 'Bag-Won'
  | 'Bag-Yen'
  | 'Bag'
  | 'Baggage'
  | 'Ball'
  | 'Balloon'
  | 'Bandage'
  | 'Bank'
  | 'Battery-Alert-1'
  | 'Battery-Charging'
  | 'Battery-Empty-1'
  | 'Battery-Empty-2'
  | 'Battery-Full-1'
  | 'Battery-Low-1'
  | 'Battery-Medium-1'
  | 'Beach'
  | 'Beanie'
  | 'Beer-Mug'
  | 'Beer-Pitch'
  | 'Bicycle-Bike'
  | 'Bill-1'
  | 'Bill-2'
  | 'Bill-4'
  | 'Bill-Cashless'
  | 'Binance-Circle'
  | 'Bitcoin'
  | 'Blood-Bag-Donation'
  | 'Blood-Donate-Drop'
  | 'Blood-Drop-Donation'
  | 'Bluetooth-Disabled'
  | 'Bluetooth-Searching'
  | 'Bluetooth'
  | 'Bone'
  | 'Book-Reading'
  | 'Bookmark-Fill'
  | 'Bow-Tie'
  | 'Bow'
  | 'Box-Sign'
  | 'Braces-Circle'
  | 'Bracket'
  | 'Braille-Blind'
  | 'Brain-Cognitive'
  | 'Brain'
  | 'Briefcase-Dollar'
  | 'Brightness-1'
  | 'Brightness-2'
  | 'Brightness-3'
  | 'Broken-Link-2'
  | 'Browser-Add'
  | 'Browser-Block'
  | 'Browser-Build'
  | 'Browser-Check'
  | 'Browser-Delete'
  | 'Browser-Hash'
  | 'Browser-Lock'
  | 'Browser-Multiple-Window'
  | 'Browser-Remove'
  | 'Browser-Website-1'
  | 'Browser-Wifi'
  | 'Bug-Antivirus-Debugging'
  | 'Bug-Antivirus-Shield'
  | 'Bug-Virus-Browser'
  | 'Bug-Virus-Document'
  | 'Bug-Virus-Folder'
  | 'Bug'
  | 'Building-2'
  | 'Bullet-List'
  | 'Burger'
  | 'Burrito-Fastfood'
  | 'Bus'
  | 'Business-Card'
  | 'Business-Handshake'
  | 'Business-Idea-Money'
  | 'Business-Profession-Home-Office'
  | 'Business-Progress-Bar-2'
  | 'Business-User-Curriculum'
  | 'Button-Fast-Forward-1'
  | 'Button-Fast-Forward-2'
  | 'Button-Next'
  | 'Button-Pause-2'
  | 'Button-Play'
  | 'Button-Power-1'
  | 'Button-Previous'
  | 'Button-Record-3'
  | 'Button-Rewind-1'
  | 'Button-Rewind-2'
  | 'Button-Stop'
  | 'Cake-Slice'
  | 'Calculator-1'
  | 'Calculator-2'
  | 'Calendar-Add'
  | 'Calendar-Edit'
  | 'Calendar-Jump-To-Date'
  | 'Calendar-Star'
  | 'Call-Center-Support-Service'
  | 'Call-Hang-Up'
  | 'Camera-1'
  | 'Camera-Disabled'
  | 'Camera-Loading'
  | 'Camera-Square'
  | 'Camera-Video'
  | 'Camping-Tent'
  | 'Candy-Cane'
  | 'Cane-1'
  | 'Cane'
  | 'Capitol'
  | 'Car-Battery-Charging'
  | 'Car-Taxi-1'
  | 'Cards'
  | 'Cat-1'
  | 'Cellular-Network-4g'
  | 'Cellular-Network-5g'
  | 'Cellular-Network-Lte'
  | 'Celsius'
  | 'Chair'
  | 'Champagne-Party-Alcohol'
  | 'Chat-Bubble-Oval-Notification'
  | 'Chat-Bubble-Oval-Smiley-1'
  | 'Chat-Bubble-Oval-Smiley-2'
  | 'Chat-Bubble-Oval'
  | 'Chat-Bubble-Square-Block'
  | 'Chat-Bubble-Square-Question'
  | 'Chat-Bubble-Square-Warning'
  | 'Chat-Bubble-Square-Write'
  | 'Chat-Bubble-Text-Square'
  | 'Chat-Bubble-Typing-Oval'
  | 'Chat-Two-Bubbles-Oval'
  | 'Check-Square'
  | 'Check'
  | 'Checkup-Medical-Report-Clipboard'
  | 'Cheese'
  | 'Cherries'
  | 'Chess-Bishop'
  | 'Chess-King'
  | 'Chess-Knight'
  | 'Chess-Pawn'
  | 'Chicken-Grilled-Stream'
  | 'Christian-Cross-1'
  | 'Christian-Cross-2'
  | 'Christianity'
  | 'Chrome'
  | 'Circle-Clock'
  | 'Circle-Flask'
  | 'Circle'
  | 'City-Hall'
  | 'Class-Lesson'
  | 'Clean-Water-And-Sanitation'
  | 'Clipboard-Add'
  | 'Clipboard-Check'
  | 'Clipboard-Remove'
  | 'Closet'
  | 'Cloud-Add'
  | 'Cloud-Block'
  | 'Cloud-Check'
  | 'Cloud-Check-Green'
  | 'Cloud-Data-Transfer'
  | 'Cloud-Gaming-1'
  | 'Cloud-Refresh'
  | 'Cloud-Share'
  | 'Cloud-Warning'
  | 'Cloud-Wifi'
  | 'Cloud'
  | 'Clubs-Symbol'
  | 'Cocktail'
  | 'Code-Analysis'
  | 'Code-Monitor-1'
  | 'Code-Monitor-2'
  | 'Coffee-Bean'
  | 'Coffee-Mug'
  | 'Coffee-Takeaway-Cup'
  | 'Cog'
  | 'Coin-Share'
  | 'Coins-Stack'
  | 'Collaborations-Idea'
  | 'Color-Palette'
  | 'Color-Picker'
  | 'Color-Swatches'
  | 'Comet'
  | 'Command'
  | 'Compass-Navigato'
  | 'Composition-Oval'
  | 'Composition-Vertical'
  | 'Compsition-Horizontal'
  | 'Computer-Chip-1'
  | 'Computer-Chip-2'
  | 'Computer-Pc-Desktop'
  | 'Cone-Shape'
  | 'Contact-Phonebook-2'
  | 'Container'
  | 'Controller-1'
  | 'Controller-Wireless'
  | 'Controller'
  | 'Convert-Pdf-2'
  | 'Copy-Paste'
  | 'Creative-Commons'
  | 'Credit-Card-1'
  | 'Credit-Card-2'
  | 'Crop-Selection'
  | 'Crown'
  | 'Crutch'
  | 'Css-Three'
  | 'Curly-Brackets'
  | 'Cursor-Click'
  | 'Customer-Support-1'
  | 'Cut'
  | 'Cyborg-2'
  | 'Cyborg'
  | 'Dangerous-Zone-Sign'
  | 'Dark-Dislay-Mode'
  | 'Dashboard-3'
  | 'Dashboard-Circle'
  | 'Database-Check'
  | 'Database-Lock'
  | 'Database-Refresh'
  | 'Database-Remove'
  | 'Database-Server-1'
  | 'Database-Server-2'
  | 'Database-Setting'
  | 'Database-Subtract-2-Raid-Storage-Code-Disk-Programming-Database-Array-Hard-Disc-Minus'
  | 'Database'
  | 'Decent-Work-And-Economic-Growth'
  | 'Definition-Search-Book'
  | 'Delete-1'
  | 'Delete-Keyboard'
  | 'Descending-Number-Order'
  | 'Desktop-Chat'
  | 'Desktop-Check'
  | 'Desktop-Code'
  | 'Desktop-Delete'
  | 'Desktop-Dollar'
  | 'Desktop-Emoji'
  | 'Desktop-Favorite-Star'
  | 'Desktop-Game'
  | 'Desktop-Help'
  | 'Device-Database-Encryption-1'
  | 'Dhammajak'
  | 'Diamond-2'
  | 'Diamonds-Symbol'
  | 'Dice-1'
  | 'Dice-2'
  | 'Dice-3'
  | 'Dice-4'
  | 'Dice-5'
  | 'Dice-6'
  | 'Dices-Entertainment-Gaming-Dices'
  | 'Dictionary-Language-Book'
  | 'Disable-Bell-Notification'
  | 'Disable-Heart'
  | 'Discord'
  | 'Discount-Percent-Badge'
  | 'Discount-Percent-Circle'
  | 'Discount-Percent-Coupon'
  | 'Discount-Percent-Cutout'
  | 'Discount-Percent-Fire'
  | 'Discussion-Converstion-Reply'
  | 'Division-Circle'
  | 'Dna'
  | 'Dollar-Coin-1'
  | 'Dollar-Coin'
  | 'Donut'
  | 'Download-Box-1'
  | 'Download-Circle'
  | 'Download-Computer'
  | 'Download-File'
  | 'Dressing-Table'
  | 'Drone'
  | 'Dropbox'
  | 'Ear-Hearing'
  | 'Earpods'
  | 'Earth-1'
  | 'Earth-Airplane'
  | 'Edit-Image-Photo'
  | 'Eject'
  | 'Electric-Cord-1'
  | 'Electric-Cord-3'
  | 'Emergency-Exit'
  | 'Empty-Clipboard'
  | 'Epic-Games-1'
  | 'Equal-Sign'
  | 'Erlenmeyer-Flask'
  | 'Esports'
  | 'Ethereum-Circle'
  | 'Ethereum'
  | 'Euro'
  | 'Expand-Horizontal-1'
  | 'Expand-Window-2'
  | 'Expand'
  | 'Eye-Optic'
  | 'Face-Scan-1'
  | 'Facebook-1'
  | 'Factorial'
  | 'Fahrenheit'
  | 'Fastforward-Clock'
  | 'Figma'
  | 'File-Add-Alternate'
  | 'File-Code-1'
  | 'File-Delete-Alternate'
  | 'File-Remove-Alternate'
  | 'Film-Roll-1'
  | 'Film-Slate'
  | 'Filter-2'
  | 'Fingerprint-1'
  | 'Fingerprint-2'
  | 'Fire-Alarm-2'
  | 'Fire-Extinguisher-Sign'
  | 'Fireworks-Rocket'
  | 'Fist'
  | 'Fit-To-Height-Square'
  | 'Flash-1'
  | 'Flash-2'
  | 'Flash-3'
  | 'Flash-Off'
  | 'Flip-Vertical-Arrow-2'
  | 'Flip-Vertical-Circle-1'
  | 'Flip-Vertical-Square-2'
  | 'Floppy-Disk'
  | 'Flower-1'
  | 'Flower'
  | 'Flu-Mask'
  | 'Focus-Points'
  | 'Folder-Add'
  | 'Folder-Check'
  | 'Folder-Delete'
  | 'Fork-Knife'
  | 'Fork-Spoon'
  | 'Fragile'
  | 'Front-Camera'
  | 'Galaxy-1'
  | 'Galaxy-2'
  | 'Gameboy'
  | 'Gas-Station-Fuel-Petroleum'
  | 'Gender-Equality'
  | 'Gif-Format'
  | 'Gift-2'
  | 'Gift'
  | 'Give-Gift'
  | 'Glasses'
  | 'Global-Learning'
  | 'Gmail'
  | 'Gold'
  | 'Good-Health-And-Well-Being'
  | 'Google-Drive'
  | 'Google'
  | 'Graduation-Cap'
  | 'Gramophone'
  | 'Graph-Arrow-Decrease'
  | 'Graph-Arrow-Increase'
  | 'Graph-Bar-Decrease'
  | 'Graph-Bar-Increase'
  | 'Graph-Dot'
  | 'Graph'
  | 'Group-Meeting-Call'
  | 'Half-Star-1'
  | 'Hand-Cursor'
  | 'Hand-Grab'
  | 'Hand-Held-Tablet-Drawing'
  | 'Hand-Held-Tablet-Writing'
  | 'Hand-Held'
  | 'Hang-Up-1'
  | 'Hang-Up-2'
  | 'Happy-Face'
  | 'Hard-Disk'
  | 'Hard-Drive-1'
  | 'Heading-1-Paragraph-Styles-Heading'
  | 'Heading-2-Paragraph-Styles-Heading'
  | 'Heading-3-Paragraph-Styles-Heading'
  | 'Health-Care-2'
  | 'Hearing-Deaf-1'
  | 'Hearing-Deaf-2'
  | 'Heart-Fill'
  | 'Heart-Rate-Pulse-Graph'
  | 'Heart-Rate-Search'
  | 'Heart'
  | 'Hearts-Symbol'
  | 'Help-Chat-2'
  | 'Help-Question-1'
  | 'Hexagram'
  | 'Hierarchy-2'
  | 'Hierarchy-4'
  | 'Hierarchy-7'
  | 'Hierarchy-10'
  | 'Hierarchy-13'
  | 'Hierarchy-14'
  | 'High-Speed-Train-Front'
  | 'Hinduism'
  | 'Home-3'
  | 'Home-4'
  | 'Horizontal-Menu-Circle'
  | 'Hospital-Sign-Circle'
  | 'Hospital-Sign-Square'
  | 'Hot-Spring'
  | 'Hotel-Air-Conditioner'
  | 'Hotel-Bed-2'
  | 'Hotel-Laundry'
  | 'Hotel-One-Star'
  | 'Hotel-Shower-Head'
  | 'Hotel-Two-Star'
  | 'Humidity-None'
  | 'Ice-Cream-2'
  | 'Ice-Cream-3'
  | 'Image-Blur'
  | 'Image-Saturation'
  | 'Inbox-Block'
  | 'Inbox-Favorite-Heart'
  | 'Inbox-Favorite'
  | 'Inbox-Lock'
  | 'Inbox-Tray-1'
  | 'Inbox-Tray-2'
  | 'Incognito-Mode'
  | 'Incoming-Call'
  | 'Industry-Innovation-And-Infrastructure'
  | 'Information-Circle'
  | 'Information-Desk-Customer'
  | 'Information-Desk'
  | 'Input-Box'
  | 'Insert-Cloud-Video'
  | 'Insert-Side'
  | 'Insert-Top-Left'
  | 'Insert-Top-Right'
  | 'Instagram'
  | 'Insurance-Hand'
  | 'Investment-Selection'
  | 'Invisible-1'
  | 'Invisible-2'
  | 'Iron'
  | 'Islam'
  | 'Jump-Object'
  | 'Justice-Hammer'
  | 'Justice-Scale-1'
  | 'Justice-Scale-2'
  | 'Key'
  | 'Keyboard-Virtual'
  | 'Keyboard-Wireless-2'
  | 'Keyboard'
  | 'Keyhole-Lock-Circle'
  | 'Ladder'
  | 'Landscape-2'
  | 'Landscape-Setting'
  | 'Laptop-Camera'
  | 'Laptop-Charging'
  | 'Lasso-Tool'
  | 'Layers-1'
  | 'Layers-2'
  | 'Layout-Window-1'
  | 'Layout-Window-2'
  | 'Layout-Window-8'
  | 'Layout-Window-11'
  | 'Leaf'
  | 'Lemon-Fruit-Seasoning'
  | 'Lift-Disability'
  | 'Lift'
  | 'Lightbulb'
  | 'Like-1-Fill'
  | 'Like-1'
  | 'Link-Chain'
  | 'Linkedin'
  | 'Lipstick'
  | 'Live-Video'
  | 'Local-Storage-Folder'
  | 'Location-Compass-1'
  | 'Location-Pin-3'
  | 'Location-Pin-Disabled'
  | 'Location-Target-1'
  | 'Lock-Rotation'
  | 'Log'
  | 'Login-1'
  | 'Logout-1'
  | 'Loop-1'
  | 'Lost-And-Found'
  | 'Magic-Wand-2'
  | 'Magnifying-Glass-Circle'
  | 'Magnifying-Glass'
  | 'Mail-Incoming'
  | 'Mail-Search'
  | 'Mail-Send-Email-Message'
  | 'Mail-Send-Envelope'
  | 'Mail-Send-Reply-All'
  | 'Make-Up-Brush'
  | 'Man-Symbol'
  | 'Manual-Book'
  | 'Map-Fold'
  | 'Markdown-Circle-Programming'
  | 'Markdown-Document-Programming'
  | 'Medical-Bag'
  | 'Medical-Cross-Sign-Healthcare'
  | 'Medical-Cross-Symbol'
  | 'Medical-Files-Report-History'
  | 'Medical-Ribbon-1'
  | 'Medical-Search-Diagnosis'
  | 'Megaphone-2'
  | 'Meta'
  | 'Microscope-Observation-Sciene'
  | 'Microwave'
  | 'Milkshake'
  | 'Minimize-Window-2'
  | 'Missed-Call'
  | 'Mobile-Phone-Camera'
  | 'Module-Puzzle-1'
  | 'Module-Puzzle-3'
  | 'Module-Three'
  | 'Moon-Cloud'
  | 'Mouse-Wireless-1'
  | 'Mouse-Wireless'
  | 'Mouse'
  | 'Moustache'
  | 'Mouth-Lip'
  | 'Move-Left'
  | 'Move-Right'
  | 'Multiple-File-2'
  | 'Music-Equalizer'
  | 'Music-Folder-Song'
  | 'Music-Note-1'
  | 'Music-Note-2'
  | 'Music-Note-Off-1'
  | 'Music-Note-Off-2'
  | 'Navigation-Arrow-Off'
  | 'Navigation-Arrow-On'
  | 'Necklace'
  | 'Necktie'
  | 'Netflix'
  | 'Network'
  | 'New-File'
  | 'New-Folder'
  | 'New-Sticky-Note'
  | 'News-Paper'
  | 'Next'
  | 'Nintendo-Switch'
  | 'No-Poverty'
  | 'Not-Equal-Sign'
  | 'Notification-Alarm-2'
  | 'Notification-Application-1'
  | 'Notification-Application-2'
  | 'Notification-Message-Alert'
  | 'Nurse-Assistant-Emergency'
  | 'Nurse-Hat'
  | 'Octopus'
  | 'Office-Building-1'
  | 'Office-Worker'
  | 'Ok-Hand'
  | 'One-Finger-Drag-Horizontal'
  | 'One-Finger-Drag-Vertical'
  | 'One-Finger-Hold'
  | 'One-Finger-Tap'
  | 'One-Vesus-One'
  | 'Online-Medical-Call-Service'
  | 'Online-Medical-Service-Monitor'
  | 'Online-Medical-Web-Service'
  | 'Open-Book'
  | 'Open-Umbrella'
  | 'Orientation-Landscape'
  | 'Orientation-Portrait'
  | 'Outgoing-Call'
  | 'Pacman'
  | 'Padlock-Square-1'
  | 'Page-Setting'
  | 'Paint-Bucket'
  | 'Paint-Palette'
  | 'Paintbrush-1'
  | 'Paintbrush-2'
  | 'Paperclip-1'
  | 'Parachute-Drop'
  | 'Paragraph'
  | 'Parking-Sign'
  | 'Parliament'
  | 'Party-Popper'
  | 'Passport'
  | 'Pathfinder-Divide'
  | 'Pathfinder-Exclude'
  | 'Pathfinder-Intersect'
  | 'Pathfinder-Merge'
  | 'Pathfinder-Minus-Front-1'
  | 'Pathfinder-Trim'
  | 'Pathfinder-Union'
  | 'Payment-10'
  | 'Payment-Cash-Out-3'
  | 'Paypal'
  | 'Peace-Hand'
  | 'Peace-Symbol'
  | 'Pen-3'
  | 'Pen-Draw'
  | 'Pen-Tool'
  | 'Pencil'
  | 'Pentagon'
  | 'Pet-Paw'
  | 'Petri-Dish-Lab-Equipment'
  | 'Pets-Allowed'
  | 'Pharmacy'
  | 'Phone-Mobile-Phone'
  | 'Phone-Qr'
  | 'Phone-Ringing-1'
  | 'Phone-Ringing-2'
  | 'Phone'
  | 'Pi-Symbol-Circle'
  | 'Pictures-Folder-Memories'
  | 'Pie-Chart'
  | 'Piggy-Bank'
  | 'Planet'
  | 'Play-List-4'
  | 'Play-List-5'
  | 'Play-List-8'
  | 'Play-List-9'
  | 'Play-List-Folder'
  | 'Play-Station'
  | 'Play-Store'
  | 'Podium'
  | 'Polaroid-Four'
  | 'Politics-Compaign'
  | 'Politics-Speech'
  | 'Politics-Vote-2'
  | 'Polka-Dot-Circle'
  | 'Polygon'
  | 'Pool-Ladder'
  | 'Popcorn'
  | 'Pork-Meat'
  | 'Potted-Flower-Tulip'
  | 'Praying-Hand'
  | 'Prescription-Pills-Drugs-Healthcare'
  | 'Printer'
  | 'Production-Belt'
  | 'Projector-Board'
  | 'Pyramid-Shape'
  | 'Qr-Code'
  | 'Quality-Education'
  | 'Quotation-2'
  | 'Radio'
  | 'Radioactive-2'
  | 'Rain-Cloud'
  | 'Rainbow'
  | 'Receipt-Add'
  | 'Receipt-Check'
  | 'Receipt-Subtract'
  | 'Receipt'
  | 'Recording-Tape-Bubble-Circle'
  | 'Recording-Tape-Bubble-Square'
  | 'Recycle-1'
  | 'Recycle-Bin-2'
  | 'Reduced-Inequalities'
  | 'Refrigerator'
  | 'Return-2'
  | 'Ringing-Bell-Notification'
  | 'Rock-And-Roll-Hand'
  | 'Rock-Slide'
  | 'Rose'
  | 'Rotate-Angle-45'
  | 'Round-Cap'
  | 'Rss-Square'
  | 'Rss-Symbol'
  | 'Sad-Face'
  | 'Safe-Vault'
  | 'Sail-Ship'
  | 'Satellite-Dish'
  | 'Scanner-3'
  | 'Scanner-Bar-Code'
  | 'Scanner'
  | 'School-Bus-Side'
  | 'Screen-1'
  | 'Screen-2'
  | 'Screen-Broadcast'
  | 'Screen-Curve'
  | 'Screensaver-Monitor-Wallpaper'
  | 'Script-2'
  | 'Search-Dollar'
  | 'Search-Visual'
  | 'Select-Circle-Area-1'
  | 'Send-Email'
  | 'Serving-Dome'
  | 'Share-Link'
  | 'Shelf'
  | 'Shell'
  | 'Shield-1'
  | 'Shield-2'
  | 'Shield-Check'
  | 'Shield-Cross'
  | 'Shift'
  | 'Shipment-Add'
  | 'Shipment-Check'
  | 'Shipment-Download'
  | 'Shipment-Remove'
  | 'Shipment-Upload'
  | 'Shipping-Box-1'
  | 'Shipping-Truck'
  | 'Shopping-Bag-Hand-Bag-2'
  | 'Shopping-Basket-1'
  | 'Shopping-Basket-2'
  | 'Shopping-Cart-1'
  | 'Shopping-Cart-2'
  | 'Shopping-Cart-3'
  | 'Shopping-Cart-Add'
  | 'Shopping-Cart-Check'
  | 'Shopping-Cart-Subtract'
  | 'Shovel-Rake'
  | 'Shredder'
  | 'Shrimp'
  | 'Shrink-Horizontal-1'
  | 'Shuffle'
  | 'Sigma'
  | 'Sign-At'
  | 'Sign-Cross-Square'
  | 'Sign-Hashtag'
  | 'Signage-3'
  | 'Signage-4'
  | 'Signal-Full'
  | 'Signal-Loading'
  | 'Signal-Low'
  | 'Signal-Medium'
  | 'Signal-None'
  | 'Skull-1'
  | 'Slack'
  | 'Sleep'
  | 'Smiley-Angry'
  | 'Smiley-Cool'
  | 'Smiley-Crying-1'
  | 'Smiley-Cute'
  | 'Smiley-Drool'
  | 'Smiley-Emoji-Kiss-Nervous'
  | 'Smiley-Emoji-Terrified'
  | 'Smiley-Grumpy'
  | 'Smiley-Happy'
  | 'Smiley-In-Love'
  | 'Smiley-Kiss'
  | 'Smiley-Laughing-3'
  | 'Smiley-Mask'
  | 'Smiley-Nauseas'
  | 'Smiley-Smirk'
  | 'Smiley-Sparks'
  | 'Smiley-Surprised'
  | 'Smiley-Throw-Up'
  | 'Smiley-Very-Shocked'
  | 'Smoke-Detector'
  | 'Smoking-Area'
  | 'Snorkle'
  | 'Snow-Flake'
  | 'Song-Recommendation'
  | 'Sort-Descending'
  | 'Sos-Help-Emergency-Sign'
  | 'Spades-Symbol'
  | 'Speaker-1'
  | 'Speaker-2'
  | 'Spiral-Shape'
  | 'Split-Vertical'
  | 'Spotify'
  | 'Spray-Paint'
  | 'Sprout'
  | 'Square-Brackets-Circle'
  | 'Square-Cap'
  | 'Square-Clock'
  | 'Square-Root-X-Circle'
  | 'Star-2'
  | 'Star-Badge'
  | 'Startup'
  | 'Steering-Wheel'
  | 'Steps-Number'
  | 'Stethoscope'
  | 'Stock'
  | 'Store-1'
  | 'Store-2'
  | 'Store-Computer'
  | 'Straight-Cap'
  | 'Straight-Face'
  | 'Strategy-Tasks'
  | 'Strawberry'
  | 'Stream'
  | 'Street-Road'
  | 'Street-Sign'
  | 'Subscription-Cashflow'
  | 'Subtract-1'
  | 'Subtract-Circle'
  | 'Subtract-Square'
  | 'Sun-Cloud'
  | 'Synchronize-Disable'
  | 'Synchronize-Warning'
  | 'Syringe'
  | 'Table-Lamp-1'
  | 'Tablet-Capsule'
  | 'Tag-1'
  | 'Tag'
  | 'Take-Off'
  | 'Tall-Hat'
  | 'Tape-Cassette-Record'
  | 'Target-3'
  | 'Target'
  | 'Task-List'
  | 'Tea-Cup'
  | 'Telegram'
  | 'Telescope'
  | 'Test-Tube'
  | 'Text-Flow-Rows'
  | 'Text-Square'
  | 'Text-Style'
  | 'TextFieldX'
  | 'Thermometer'
  | 'Threat-Browser-1'
  | 'Threat-Document'
  | 'Threat-Folder'
  | 'Ticket-1'
  | 'Tickets'
  | 'Tidal-Wave'
  | 'Tiktok'
  | 'Tinder'
  | 'Toast'
  | 'Toilet-Man'
  | 'Toilet-Sign-Man-Woman-2'
  | 'Toilet-Women'
  | 'Tooth'
  | 'Traffic-Cone'
  | 'Transfer-Motorcycle'
  | 'Transfer-Van'
  | 'Tree-2'
  | 'Tree-3'
  | 'Trending-Content'
  | 'Triangle-Flag'
  | 'Trophy'
  | 'Twitter'
  | 'Two-Finger-Drag-Hotizontal'
  | 'Two-Finger-Tap'
  | 'Underline-Text-1'
  | 'Upload-Box-1'
  | 'Upload-Circle'
  | 'Upload-Computer'
  | 'Upload-File'
  | 'Usb-Drive'
  | 'User-Add-Plus'
  | 'User-Check-Validate'
  | 'User-Circle-Single'
  | 'User-Identifier-Card'
  | 'User-Multiple-Circle'
  | 'User-Multiple-Group'
  | 'User-Profile-Focus'
  | 'User-Protection-2'
  | 'User-Remove-Subtract'
  | 'User-Single-Neutral-Male'
  | 'User-Sync-Online-In-Person'
  | 'Vertical-Slider-Square'
  | 'Video-Swap-Camera'
  | 'Virtual-Reality'
  | 'Virus-Antivirus'
  | 'Visible'
  | 'Voice-Mail-Off'
  | 'Voice-Mail'
  | 'Voice-Scan-2'
  | 'Volcano'
  | 'Volume-Down'
  | 'Volume-Level-High'
  | 'Volume-Level-Low'
  | 'Volume-Level-Off'
  | 'Volume-Mute'
  | 'Volume-Off'
  | 'Vpn-Connection'
  | 'Vr-Headset-1'
  | 'Vr-Headset-2'
  | 'Waiting-Appointments-Calendar'
  | 'Wallet-Purse'
  | 'Wallet'
  | 'Waning-Cresent-Moon'
  | 'Warehouse-1'
  | 'Warning-Octagon'
  | 'Warning-Triangle'
  | 'Watch-1'
  | 'Watch-2'
  | 'Watch-Circle-Charging'
  | 'Watch-Circle-Heartbeat-Monitor-1'
  | 'Watch-Circle-Heartbeat-Monitor-2'
  | 'Watch-Circle-Menu'
  | 'Watch-Circle-Time'
  | 'Water-Glass'
  | 'Wave-Signal-Square'
  | 'Wave-Signal'
  | 'Waving-Hand'
  | 'Web'
  | 'Webcam-Video-Circle'
  | 'Webcam-Video-Off'
  | 'Webcam-Video'
  | 'Webcam'
  | 'Whatsapp'
  | 'Wheelchair-1'
  | 'Wheelchair'
  | 'Widget'
  | 'Wifi-Antenna'
  | 'Wifi-Disabled'
  | 'Wifi-Horizontal'
  | 'Wifi-Router'
  | 'Wifi'
  | 'Wind-Flow-1'
  | 'Wind-Flow-2'
  | 'Windmill'
  | 'Windows'
  | 'Wine'
  | 'Woman-Symbol'
  | 'Workspace-Desk'
  | 'Wrench'
  | 'Xbox'
  | 'Xrp-Circle'
  | 'Yin-Yang-Symbol'
  | 'Yuan-Circle'
  | 'Yuan'
  | 'Zero-Hunger'
  | 'Zodiac-1'
  | 'Zodiac-2'
  | 'Zodiac-3'
  | 'Zodiac-4'
  | 'Zodiac-5'
  | 'Zodiac-6'
  | 'Zodiac-7'
  | 'Zodiac-8'
  | 'Zodiac-9'
  | 'Zodiac-10'
  | 'Zodiac-11'
  | 'Zodiac-12'
  | 'Add-1'
  | 'Add-Bell-Notification'
  | 'Add-Circle'
  | 'Add-Layer-2'
  | 'Add-Square'
  | 'Alarm-Clock'
  | 'Align-Back-1'
  | 'Align-Center'
  | 'Align-Front-1'
  | 'Align-Left'
  | 'Align-Right'
  | 'Ampersand'
  | 'Archive-Box'
  | 'Arrow-Bend-Left-Down-2'
  | 'Arrow-Bend-Right-Down-2'
  | 'Arrow-Crossover-Down'
  | 'Arrow-Crossover-Left'
  | 'Arrow-Crossover-Right'
  | 'Arrow-Crossover-Up'
  | 'Arrow-Cursor-1'
  | 'Arrow-Cursor-2'
  | 'Arrow-Curvy-Up-Down-1'
  | 'Arrow-Curvy-Up-Down-2'
  | 'Arrow-Down-2'
  | 'Arrow-Down-Dashed-Square'
  | 'Arrow-Expand'
  | 'Arrow-Infinite-Loop'
  | 'Arrow-Move'
  | 'Arrow-Reload-Horizontal-1'
  | 'Arrow-Reload-Horizontal-2'
  | 'Arrow-Reload-Vertical-1'
  | 'Arrow-Reload-Vertical-2'
  | 'Arrow-Roadmap'
  | 'Arrow-Round-Left'
  | 'Arrow-Round-Right'
  | 'Arrow-Shrink-Diagonal-1'
  | 'Arrow-Shrink-Diagonal-2'
  | 'Arrow-Shrink'
  | 'Arrow-Transfer-Diagonal-1'
  | 'Arrow-Transfer-Diagonal-2'
  | 'Arrow-Transfer-Diagonal-3'
  | 'Arrow-Up-1'
  | 'Arrow-Up-Dashed-Square'
  | 'Ascending-Number-Order'
  | 'Attribution'
  | 'Blank-Calendar'
  | 'Blank-Notepad'
  | 'Block-Bell-Notification'
  | 'Bomb'
  | 'Bookmark'
  | 'Issue-history'
  | 'Issue-history'
  | 'Issue-register';
export interface IconProps extends Omit<React.SVGProps<SVGSVGElement>, 'ref'> {
  name: IconList;
  size?: number;
  color?: React.CSSProperties['fill'];
  alt?: string;
}

export const Icon = ({
  name,
  size = 24,
  height,
  width,
  color,
  alt,
  className,
}: IconProps) => {
  const Svg = useMemo(() => lazy(() => import(`./svg/${name}.svg`)), [name]);

  return (
    <Suspense fallback={<div style={{ width: size, height: size }} />}>
      <div
        style={{
          width: width || size,
          height: height || size,
        }}
      >
        <Svg
          alt={alt || name}
          className={cleanClassName(
            `${styles.icon} ${className ? className : styles['default-color']}`,
          )}
          color={color}
          viewBox={`0 0 ${size} ${size}`}
        />
      </div>
    </Suspense>
  );
};
