'use client';

import { useEffect } from 'react';

import { createError } from '@wello-client/common/utils';
import { usePathname, useSearchParams } from 'next/navigation';
import { create } from 'zustand';

import { ROUTES, SEARCH_PARAMS } from '@/constants';
import { useAuthStore } from '@/stores/AuthStore';
import { useDeviceInfoStore } from '@/stores/DeviceStore';
import { FAMILY_PROFILE_UPDATE_PAGE_TYPE } from '@/types';

import { moveBackInfo } from '../_utils/moveBackInfo';
import { isKbWebview } from '../_utils/navtiveCall';
import { setTopMenuV2 } from '../_utils/setTopMenuV2';

export const KB_DUMMY_PATHNAME = 'kbpostparam';
export const KB_DUMMY_PATHNAME_SPLIT_POINT = '__';

interface KbHistoryStore {
  historyList: string[];
  setHistoryList: (setter: (history: string[]) => string[]) => void;
}

const kbHistoryStore = create<KbHistoryStore>((set) => ({
  historyList: [],
  setHistoryList: (setter) =>
    set((state) => ({ historyList: setter(state.historyList) })),
}));

export const KbHistoryStack = () => {
  const searchParams = useSearchParams();
  const pathname = usePathname();
  const osType = useDeviceInfoStore((state) => state.osType);
  const isLogin = useAuthStore((state) => state.isLogin);

  useEffect(() => {
    if (!pathname) return;

    if (!isKbWebview()) {
      const allowedPath = ['/login', '/join', '/terms', '/easy-certification'];
      const isAllowedPath = allowedPath.some((path) => pathname.includes(path));

      if (!isAllowedPath) {
        const originalWello = process.env.NEXT_PUBLIC_DOMAIN;
        if (!originalWello)
          throw createError({
            return_message: 'NEXT_PUBLIC_DOMAIN is not defined',
          });

        location.href = location.href.replace(location.origin, originalWello);
      }
    }
  }, [pathname]);

  useEffect(() => {
    setTopMenuV2('', '', {
      menuBt: 'Y',
      preBt: 'N',
      close: 'N',
      title: '맞춤형 정책지원금 알림',
      preAction: 'B',
      target: '',
    });
  }, []);

  useEffect(() => {
    if (!(pathname && searchParams)) return;

    if (
      isKbWebview() &&
      isLogin &&
      !pathname.includes(ROUTES.CROSS_DOMAIN_CALLBACK.pathname)
    ) {
      let kbDummyPathname = pathname;
      const params: { [key: string]: string } = {};

      searchParams.forEach((value, key) => {
        if (key === SEARCH_PARAMS.POST_REDIRECT) return;
        const lastChar = kbDummyPathname.slice(-1);

        //! ⚠️ KB 쪽에서 SearchParams가 달라도 Pathname이 같으면 같은 페이지로 인식하기 때문에 Pathname에 파라미터를 붙여주는 로직 미들웨어에서 벗겨내는 로직으로 페이지 이동
        kbDummyPathname =
          kbDummyPathname +
          `${lastChar === '/' ? '' : '/'}${KB_DUMMY_PATHNAME}${KB_DUMMY_PATHNAME_SPLIT_POINT}${key}${KB_DUMMY_PATHNAME_SPLIT_POINT}${value}`;

        params[key] = value;
      });

      const url = new URL(kbDummyPathname, location.origin).toString();

      const { historyList, setHistoryList } = kbHistoryStore.getState();

      const lastHistory = historyList[historyList.length - 1];

      const isNewHistory = lastHistory !== url;

      if (!isNewHistory) return;

      const addHistoryStack = () => {
        moveBackInfo(url, params, 'Y');

        setHistoryList((history) => [...history, url]);
      };

      const addHistoryStackWithoutParams = () => {
        const url = new URL(pathname, location.origin).toString();

        moveBackInfo(url, params, 'Y');

        setHistoryList((history) => [...history, url]);
      };

      switch (pathname) {
        //! ⚠️ 해당 페이지는 히스토리를 쌓지 않음
        case ROUTES.FAMILY_PROFILE_UPDATE.pathname({
          updateType: FAMILY_PROFILE_UPDATE_PAGE_TYPE.ADD,
        }):
        case ROUTES.FAMILY_PROFILE_UPDATE.pathname({
          updateType: FAMILY_PROFILE_UPDATE_PAGE_TYPE.EDIT,
        }):
          return;

        //! ⚠️ 해당 페이지는 replace가 불가능한 KB URL 관리 특성상 파라미터 변경을 감지하면 히스토리가 무한하게 쌓임
        case ROUTES.SEARCH.pathname:
          return addHistoryStackWithoutParams();

        default:
          return addHistoryStack();
      }
    }
  }, [searchParams, pathname, osType, isLogin]);

  return <></>;
};
