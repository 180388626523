import type { HTMLTagProps } from '@/types';

import { cleanClassName } from '@wello-client/common/utils';

import styles from './Skeleton.module.scss';

export type SkeletonProps = Omit<HTMLTagProps<'div'>, 'children'>;

export const Skeleton = ({
  //* HTML div props
  className,
  ...restDivProps
}: SkeletonProps) => (
  <div
    {...restDivProps}
    className={cleanClassName(`${styles.skeleton} ${className}`)}
  >
    <div className={styles['light-reflection-wrap']}>
      <div className={styles['light-reflection']} />
    </div>
  </div>
);
