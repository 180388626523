'use client';

import { useLayoutEffect } from 'react';

import { useShallow } from 'zustand/react/shallow';

import { STORAGE_KEY } from '@/constants';

import { useGlobalStore } from './useGlobalStore';

export const useMountGlobalStore = () => {
  const [setIsMounted, setHistoryMap, setCurrentHistoryKey] = useGlobalStore(
    useShallow((state) => [
      state.setIsMounted,
      state.setHistoryMap,
      state.setCurrentHistoryKey,
    ]),
  );

  useLayoutEffect(() => {
    setIsMounted(true);

    const historyListString = sessionStorage.getItem(STORAGE_KEY.HISTORY_MAP);

    const lastHistoryKeyString = sessionStorage.getItem(
      STORAGE_KEY.CURRENT_HISTORY_KEY,
    );

    const hasHistory = historyListString && lastHistoryKeyString;

    if (hasHistory) {
      setCurrentHistoryKey(Number(lastHistoryKeyString));
      setHistoryMap(new Map(JSON.parse(historyListString)));
    } else {
      setCurrentHistoryKey(history.length - 1);
      //! ⚠️ 세션 스토리지에 저장
      setHistoryMap((prevHistoryMap) => prevHistoryMap);
    }
  }, [setCurrentHistoryKey, setHistoryMap, setIsMounted]);
};
