import type { HTMLTagProps, Size } from '@/types';

import { cleanClassName } from '@wello-client/common/utils';

import { Spinner } from '@/components/server';

import styles from './Button.module.scss';

type ButtonTheme = 'unset' | 'primary' | 'primary-outline' | 'sub-outline';

export interface ButtonProps extends Omit<HTMLTagProps<'button'>, 'ref'> {
  theme?: ButtonTheme;
  shape?: 'rectangle' | 'capsule';
  size?: Size;
  icon?: React.ReactNode;
  loading?: boolean;
  disabled?: boolean;
}

export const Button = ({
  theme = 'primary',
  shape = 'rectangle',
  size = 'large',
  type = 'button',
  loading = false,
  icon,
  className,
  disabled = false,
  children,
  ...restProps
}: ButtonProps) => {
  return (
    <button
      {...restProps}
      className={cleanClassName(
        `${styles.button} ${theme !== 'unset' && styles[theme]} ${
          styles[size]
        } ${styles[shape]} ${className}`,
      )}
      disabled={loading || disabled}
      type={type}
    >
      {loading ? (
        <Spinner className={styles['loading-spinner']} size={size} />
      ) : null}
      {icon ? <div className={styles.icon}>{icon}</div> : null}
      {children}
    </button>
  );
};
