import { createError, type ServerResponse } from '@wello-client/common/utils';
import axios from 'axios';

import { ENV } from '@/constants';

interface ApplyListRequestProps {
  socialId?: number;
  page: number;
  size: number;
}

type valueProps = {
  code: string | null;
  value: string | null;
};

type userDetailProps = {
  esntlId: string | null;
  birthDate1: string | null;
  birthDate2: string | null;
  userEducation: string | null;
  userWorkType: string | null;
  userImmigrant: string | null;
  userImmigrantPeriod: string | null;
  userMarriage: string | null;
  userChild: string | null;
  userParticipation: string | null;
};

type registerProps = {
  esntlId: string | null;
  userId: string | null;
  userNm: string | null;
  userZip: string | null;
  userAdres: string | null;
  userEmail: string | null;
  groupId: string | null;
  userSe: string | null;
  orgnztId: string | null;
  userMbtlnum: string | null;
  youthUserDetail: userDetailProps;
  userDetailAdres: string | null;
};

export type ApplyListDetail = {
  id: number;
  adviceFormDate: string | null;
  adviceFormTime: string | null;
  adviceUserMemo: string | null;
  adviceFormStatus: valueProps | null;
  adviceCategory1: valueProps | null;
  adviceCategory2: valueProps | null;
  adviceCategory3: valueProps | null;
  adviceWork: valueProps[];
  adviceResidence: valueProps[];
  adviceSpace: valueProps[];
  adviceEducation: valueProps[];
  adviceFinance: valueProps[];
  adviceWelfare: valueProps[];
  adviceCulture: valueProps[];
  adviceParticipation: valueProps[];
  userEducation: valueProps | null;
  userWorkType: valueProps | null;
  userImmigrant: valueProps | null;
  userImmigrantPeriod: valueProps | null;
  userMarriage: valueProps;
  userChild: valueProps;
  userParticipation: valueProps;
  frstRegister: registerProps;
  frstRegistPnttm: string | null;
};

export interface ApplyListResponse extends ServerResponse {
  context: {
    content: ApplyListDetail[];
    pageable: {
      pageNumber: number;
    };
    last: boolean;
    totalPages: number;
    totalElements: number;
  };
}

export const fetchJejuApplyList = async ({
  socialId,
  page,
  size,
}: ApplyListRequestProps) => {
  try {
    const response = await axios.get<ApplyListResponse>(
      `${ENV.NEXT_PUBLIC_JEJU_API_DOMAIN}/api/wello/api101?socialId=${socialId}&page=${page}&size=${size}`,
      {
        headers: {
          Authorization: `Basic ${ENV.NEXT_PUBLIC_JEJU_YOUTHDREAM_API_KEY}`,
        },
      },
    );

    return response.data.context;
  } catch (error) {
    throw createError();
  }
};
