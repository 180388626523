import { ROUTES, MODULE_ROUTES } from '@/constants';

export const findCurrentRoute = (pathname: string | null) => {
  if (!pathname) return;

  const allRoutes = { ...ROUTES, ...MODULE_ROUTES };

  for (const key in allRoutes) {
    const routeName = key as keyof typeof allRoutes;

    const route = allRoutes[routeName];

    if (typeof route.pathname === 'string') {
      if (route.pathname === pathname) {
        return route;
      }
    } else {
      const splitedRoutePathname = route.pathname({} as any).split('/');
      const splitedCurrentPathname = pathname.split('/');

      if (splitedRoutePathname.length !== splitedCurrentPathname.length)
        continue;

      splitedCurrentPathname.every((path, index) => {
        const partOfRoutePathname = splitedRoutePathname[index];

        if (partOfRoutePathname === 'undefined') return true;

        return path === partOfRoutePathname;
      });
    }
  }
};
