/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { ApiKeyIssuanceRequest } from '../models/ApiKeyIssuanceRequest';
import type { CreateExternalClientRequest } from '../models/CreateExternalClientRequest';
import type { DefaultHttpResponseDetailAddressResponse } from '../models/DefaultHttpResponseDetailAddressResponse';
import type { DefaultHttpResponseDetailAgreementResponse } from '../models/DefaultHttpResponseDetailAgreementResponse';
import type { DefaultHttpResponseDetailEligibilityCheckResponse } from '../models/DefaultHttpResponseDetailEligibilityCheckResponse';
import type { DefaultHttpResponseDetailNoticeResponse } from '../models/DefaultHttpResponseDetailNoticeResponse';
import type { DefaultHttpResponseDetailReportHisResponse } from '../models/DefaultHttpResponseDetailReportHisResponse';
import type { DefaultHttpResponseDetailReportStatusCheckResponse } from '../models/DefaultHttpResponseDetailReportStatusCheckResponse';
import type { DefaultHttpResponseListCountryResponse } from '../models/DefaultHttpResponseListCountryResponse';
import type { DefaultHttpResponseListIclLoanInterestChangeHisResponse } from '../models/DefaultHttpResponseListIclLoanInterestChangeHisResponse';
import type { DefaultHttpResponseListLoanInterestResponse } from '../models/DefaultHttpResponseListLoanInterestResponse';
import type { DefaultHttpResponseListReportHisResponse } from '../models/DefaultHttpResponseListReportHisResponse';
import type { DefaultHttpResponseListUniversityResponse } from '../models/DefaultHttpResponseListUniversityResponse';
import type { DefaultHttpResponseVoid } from '../models/DefaultHttpResponseVoid';
import type { DeptReportBasicRequest } from '../models/DeptReportBasicRequest';
import type { DetailNoticeRequest } from '../models/DetailNoticeRequest';
import type { DetailReportHisRequest } from '../models/DetailReportHisRequest';
import type { KosafKeyRequest } from '../models/KosafKeyRequest';
import type { ListCountryRequest } from '../models/ListCountryRequest';
import type { ListLoanInterestRequest } from '../models/ListLoanInterestRequest';
import type { ListUniversityRequest } from '../models/ListUniversityRequest';
import type { SaveReportRequest } from '../models/SaveReportRequest';
import type { SendDeptReportAppPushRequest } from '../models/SendDeptReportAppPushRequest';
import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';
export class Service {
    /**
     * 채무자신고 내역 저장 (각종 정보 제공 동의 및 채무자신고내역 저장)
     * @returns DefaultHttpResponseVoid OK
     * @throws ApiError
     */
    public static saveReport({
        memberOAuthId,
        policyApplyId,
        requestBody,
    }: {
        /**
         * 멤버 ID
         */
        memberOAuthId: number,
        /**
         * 정책신청 ID
         */
        policyApplyId: number,
        requestBody: SaveReportRequest,
    }): CancelablePromise<DefaultHttpResponseVoid> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/wello/v1/dept-reporting/report',
            headers: {
                'memberOAuthId': memberOAuthId,
                'policyApplyId': policyApplyId,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                401: `Unauthorized`,
                500: `Internal Server Error`,
                502: `Bad Gateway`,
            },
        });
    }
    /**
     * 귀속연도의 정기 채무자신고 완료여부 조회
     * @returns DefaultHttpResponseDetailReportStatusCheckResponse OK
     * @throws ApiError
     */
    public static getReportStatusCheck({
        requestBody,
    }: {
        requestBody: DetailReportHisRequest,
    }): CancelablePromise<DefaultHttpResponseDetailReportStatusCheckResponse> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/wello/v1/dept-reporting/report-status-check',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                401: `Unauthorized`,
                500: `Internal Server Error`,
                502: `Bad Gateway`,
            },
        });
    }
    /**
     * 채무자신고 이력 목록 조회
     * @returns DefaultHttpResponseListReportHisResponse OK
     * @throws ApiError
     */
    public static getReportHistoryList({
        requestBody,
    }: {
        requestBody: DeptReportBasicRequest,
    }): CancelablePromise<DefaultHttpResponseListReportHisResponse> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/wello/v1/dept-reporting/report-history/list',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                401: `Unauthorized`,
                500: `Internal Server Error`,
                502: `Bad Gateway`,
            },
        });
    }
    /**
     * 채무자신고 신고 내용중 단건 상세 조회(신고내역 상세내역조회)
     * @returns DefaultHttpResponseDetailReportHisResponse OK
     * @throws ApiError
     */
    public static getReportHistory({
        requestBody,
    }: {
        requestBody: DetailReportHisRequest,
    }): CancelablePromise<DefaultHttpResponseDetailReportHisResponse> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/wello/v1/dept-reporting/report-history/detail',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                401: `Unauthorized`,
                500: `Internal Server Error`,
                502: `Bad Gateway`,
            },
        });
    }
    /**
     * 채무자신고대상자 대출 이자 조회 (취업후 학자금대출 전월말 기준 이자 조회)
     * @returns DefaultHttpResponseListLoanInterestResponse OK
     * @throws ApiError
     */
    public static getLoanInterestList({
        requestBody,
    }: {
        requestBody: ListLoanInterestRequest,
    }): CancelablePromise<DefaultHttpResponseListLoanInterestResponse> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/wello/v1/dept-reporting/loan-interest',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                401: `Unauthorized`,
                500: `Internal Server Error`,
                502: `Bad Gateway`,
            },
        });
    }
    /**
     * kosaf health-check
     * @returns DefaultHttpResponseVoid OK
     * @throws ApiError
     */
    public static healthCheck({
        requestBody,
    }: {
        requestBody: KosafKeyRequest,
    }): CancelablePromise<DefaultHttpResponseVoid> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/wello/v1/dept-reporting/health-check',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                401: `Unauthorized`,
                500: `Internal Server Error`,
                502: `Bad Gateway`,
            },
        });
    }
    /**
     * kosaf 암호화 키 갱신
     * @returns DefaultHttpResponseVoid OK
     * @throws ApiError
     */
    public static encKeyRenewal({
        requestBody,
    }: {
        requestBody: KosafKeyRequest,
    }): CancelablePromise<DefaultHttpResponseVoid> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/wello/v1/dept-reporting/enckey-renewal',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                401: `Unauthorized`,
                500: `Internal Server Error`,
                502: `Bad Gateway`,
            },
        });
    }
    /**
     * 신고가능여부 조회
     * @returns DefaultHttpResponseDetailEligibilityCheckResponse OK
     * @throws ApiError
     */
    public static getEligibilityCheck({
        requestBody,
    }: {
        requestBody: DeptReportBasicRequest,
    }): CancelablePromise<DefaultHttpResponseDetailEligibilityCheckResponse> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/wello/v1/dept-reporting/eligibility-check',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                401: `Unauthorized`,
                500: `Internal Server Error`,
                502: `Bad Gateway`,
            },
        });
    }
    /**
     * 앱 푸시 발송 요청
     * @returns DefaultHttpResponseVoid OK
     * @throws ApiError
     */
    public static sendDeptReportAppPush({
        requestBody,
    }: {
        requestBody: SendDeptReportAppPushRequest,
    }): CancelablePromise<DefaultHttpResponseVoid> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/wello/v1/dept-reporting/app-push',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                401: `Unauthorized`,
                500: `Internal Server Error`,
                502: `Bad Gateway`,
            },
        });
    }
    /**
     * kosaf API 키 재발급
     * @returns DefaultHttpResponseVoid OK
     * @throws ApiError
     */
    public static apikeyRenewal({
        requestBody,
    }: {
        requestBody: KosafKeyRequest,
    }): CancelablePromise<DefaultHttpResponseVoid> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/wello/v1/dept-reporting/apikey-renewal',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                401: `Unauthorized`,
                500: `Internal Server Error`,
                502: `Bad Gateway`,
            },
        });
    }
    /**
     * kosaf API 키 & 암호화 키 발급
     * @returns DefaultHttpResponseVoid OK
     * @throws ApiError
     */
    public static apikeyIssuance({
        requestBody,
    }: {
        requestBody: ApiKeyIssuanceRequest,
    }): CancelablePromise<DefaultHttpResponseVoid> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/wello/v1/dept-reporting/apikey-issuance',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                401: `Unauthorized`,
                500: `Internal Server Error`,
                502: `Bad Gateway`,
            },
        });
    }
    /**
     * 채무자신고 대상자 주소정보 조회
     * @returns DefaultHttpResponseDetailAddressResponse OK
     * @throws ApiError
     */
    public static getAddress({
        requestBody,
    }: {
        requestBody: DeptReportBasicRequest,
    }): CancelablePromise<DefaultHttpResponseDetailAddressResponse> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/wello/v1/dept-reporting/address',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                401: `Unauthorized`,
                500: `Internal Server Error`,
                502: `Bad Gateway`,
            },
        });
    }
    /**
     * 외부 클라이어트 생성 요청
     * @returns DefaultHttpResponseVoid OK
     * @throws ApiError
     */
    public static saveExternalClient({
        requestBody,
    }: {
        requestBody: CreateExternalClientRequest,
    }): CancelablePromise<DefaultHttpResponseVoid> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/wello/v1/allows/external-client',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                401: `Unauthorized`,
                500: `Internal Server Error`,
                502: `Bad Gateway`,
            },
        });
    }
    /**
     * 재단 대학명 조회
     * @returns DefaultHttpResponseListUniversityResponse OK
     * @throws ApiError
     */
    public static getUniversityList({
        request,
    }: {
        request: ListUniversityRequest,
    }): CancelablePromise<DefaultHttpResponseListUniversityResponse> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/wello/v1/dept-reporting/university',
            query: {
                'request': request,
            },
            errors: {
                401: `Unauthorized`,
                500: `Internal Server Error`,
                502: `Bad Gateway`,
            },
        });
    }
    /**
     * 채무자신고 공지사항 조회
     * @returns DefaultHttpResponseDetailNoticeResponse OK
     * @throws ApiError
     */
    public static getNotice({
        request,
    }: {
        request: DetailNoticeRequest,
    }): CancelablePromise<DefaultHttpResponseDetailNoticeResponse> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/wello/v1/dept-reporting/notice',
            query: {
                'request': request,
            },
            errors: {
                401: `Unauthorized`,
                500: `Internal Server Error`,
                502: `Bad Gateway`,
            },
        });
    }
    /**
     * ICL대출상품의 금리변동내역 조회
     * @returns DefaultHttpResponseListIclLoanInterestChangeHisResponse OK
     * @throws ApiError
     */
    public static getIclLoanInterestChangeHis(): CancelablePromise<DefaultHttpResponseListIclLoanInterestChangeHisResponse> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/wello/v1/dept-reporting/icl-loan-interest-change-history',
            errors: {
                401: `Unauthorized`,
                500: `Internal Server Error`,
                502: `Bad Gateway`,
            },
        });
    }
    /**
     * 재단 국가명 조회
     * @returns DefaultHttpResponseListCountryResponse OK
     * @throws ApiError
     */
    public static getCountryList({
        request,
    }: {
        request: ListCountryRequest,
    }): CancelablePromise<DefaultHttpResponseListCountryResponse> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/wello/v1/dept-reporting/country',
            query: {
                'request': request,
            },
            errors: {
                401: `Unauthorized`,
                500: `Internal Server Error`,
                502: `Bad Gateway`,
            },
        });
    }
    /**
     * 소득 및 재산 정보 제공 동의 약관 조회
     * @returns DefaultHttpResponseDetailAgreementResponse OK
     * @throws ApiError
     */
    public static getAgreement(): CancelablePromise<DefaultHttpResponseDetailAgreementResponse> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/wello/v1/dept-reporting/agreement',
            errors: {
                401: `Unauthorized`,
                500: `Internal Server Error`,
                502: `Bad Gateway`,
            },
        });
    }
}
