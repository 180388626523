'use client';

import type { AppModalOptions } from '@/stores/DeviceStore';

import { useEffect } from 'react';

import { ROUTES } from '@/constants';
import { useCustomRouter } from '@/hooks/useCustomRouter';
import { useDeviceInfoStore } from '@/stores/DeviceStore';

import { MESSAGE_KEYWORD, type MessageResponse } from '..';
import { useMountAppData } from '../_hooks/useMountAppData';

interface AppRouter extends Omit<ReturnType<typeof useCustomRouter>, 'back'> {
  back: VoidFunction;
}

export type FlutterInAppWebViewWindow = typeof window & {
  flutter_inappwebview: {
    callHandler: (handlerName: string, ...args: any[]) => Promise<any>;
  };
  webkit: {
    messageHandlers: {
      'wello-channel': {
        postMessage: (message: string) => void;
      };
    };
  };
  router: AppRouter;
  postMessage: (response: string) => void;
  openAppModal: (modalType: AppModalOptions['type']) => void;
};

export const AppController = () => {
  const setAppMessageLog = useDeviceInfoStore(
    (state) => state.setAppMessageLog,
  );

  const router = useCustomRouter();

  useEffect(() => {
    const _window = window as FlutterInAppWebViewWindow;

    _window.router = {
      ...router,
      back: () => {},
    };

    const { setAppModal } = useDeviceInfoStore.getState();

    //* 🌱 앱에서 웹의 모달을 열때 사용할 메소드를 할당해줌
    _window.openAppModal = (type) => {
      setAppModal({
        isOpened: true,
        type,
      });
    };

    _window.postMessage = (response: string) => {
      const responseObj: MessageResponse = JSON.parse(response);

      if (responseObj.isSuccess) {
        switch (responseObj.type) {
          case MESSAGE_KEYWORD.LOGIN: {
            const { accessKey } = responseObj.data as {
              accessKey: string;
            };

            router.push(
              ROUTES.AUTH.withSearchParams({
                searchParams: {
                  'social-login-token': accessKey,
                  'login-type': responseObj.params?.loginType,
                },
              }),
            );

            break;
          }
        }
      }

      setAppMessageLog((prev) => {
        prev.set(responseObj.id, responseObj);

        return prev;
      });
    };
  }, [router, setAppMessageLog]);

  useMountAppData();

  return <></>;
};
