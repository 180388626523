export const getSubDomain = (value?: string) => {
  const hostname = value ?? location.hostname;

  const mainDomain = hostname.split('.').slice(-2).join('.');
  const subDomain = hostname
    .replace(`.${mainDomain}`, '')
    .replace(/^(http:\/\/|https:\/\/)/, '');

  return subDomain || null;
};
