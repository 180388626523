import type { MessageResponse } from '@/modules/wello-app';

import { create } from 'zustand';

export type OsType = 'android' | 'ios' | 'mac' | 'windows' | null;
type ViewportType = 'desktop' | 'tablet' | 'mobile';

export interface DeviceTokenData {
  apns_token: string;
  fcm_token: string;
  os: string;
}

/**
 * @description 커스텀 웰로 서비스를 사용하는 서브 도메인 등록
 */
export enum SUB_DOMAIN {
  WELLO = 'www',
  WELLO_APP = 'app',
  KB_PAY = 'kbpay',
}

export enum WEBVIEW_TYPE {
  WELLO_APP = 'wello',
  KB_PAY = 'kbpay',
}

type AppMessageLog = Map<string, MessageResponse>;

export interface AppModalOptions {
  isOpened: boolean;
  type: 'permission' | 'exit' | 'restart';
}

export interface DeviceInfoStore {
  isMounted: boolean;
  setIsMounted: (isMounted: boolean) => void;

  webviewType?: WEBVIEW_TYPE;
  setWebviewType: (webviewType?: WEBVIEW_TYPE) => void;

  /** Firebase Cloud Messaging */
  fcmToken: string | null;
  setFcmToken: (firebaseToken: string | null) => void;

  tempDeviceTokenData?: DeviceTokenData;
  setTempDeviceTokenData: (deviceTokenData: DeviceTokenData) => void;

  deviceTokenData?: DeviceTokenData;
  setDeviceTokenData: (deviceTokenData: DeviceTokenData) => void;

  osType?: OsType;
  setOsType: (osType: OsType) => void;

  appMessageLog: Map<string, MessageResponse>;
  setAppMessageLog: (
    setAppMessageLogAction: (appMessageLog: AppMessageLog) => AppMessageLog,
  ) => void;

  viewportType?: ViewportType;
  setViewportType: (viewportType: ViewportType) => void;

  subDomain?: SUB_DOMAIN | null;
  setSubDomain: (subDomain: SUB_DOMAIN | null) => void;

  appVersion?: string;
  setAppVersion: (appVersion: string) => void;

  appModal: AppModalOptions;
  setAppModal: (appModalType: AppModalOptions) => void;
}

export const useDeviceInfoStore = create<DeviceInfoStore>((set) => ({
  isMounted: false,
  setIsMounted: (isMounted) => set({ isMounted }),

  setWebviewType: (webviewType) => set({ webviewType }),

  fcmToken: null,
  setFcmToken: (fcmToken) => set({ fcmToken }),

  setTempDeviceTokenData: (deviceTokenData) =>
    set({ tempDeviceTokenData: deviceTokenData }),
  setDeviceTokenData: (deviceTokenData) => set({ deviceTokenData }),

  appMessageLog: new Map(),
  setAppMessageLog: (setAppMessageLogAction) =>
    set(({ appMessageLog }) => ({
      appMessageLog: new Map(setAppMessageLogAction(appMessageLog)),
    })),

  setOsType: (osType) => set({ osType }),

  setViewportType: (viewportType) => set({ viewportType }),

  setSubDomain: (subDomain) => set({ subDomain }),

  setAppVersion: (appVersion) => set({ appVersion }),

  appModal: {
    isOpened: false,
    type: 'permission',
  },
  setAppModal: (appModal) => set({ appModal }),
}));
