export enum BANNER_CONTENTS_TYPE {
  COMMON = 'BANNER_CONTENTS_TYPE_COMMON',
  CONTENTS = 'BANNER_CONTENTS_TYPE_CONTENTS',
}

export enum LOGIN_TYPE {
  EMAIL = 'MEMBER_LOGIN_TYPE_EMAIL',
  GOOGLE = 'MEMBER_LOGIN_TYPE_GOOGLE',
  NAVER = 'MEMBER_LOGIN_TYPE_NAVER',
  KAKAO = 'MEMBER_LOGIN_TYPE_KAKAO',
  APPLE = 'MEMBER_LOGIN_TYPE_APPLE',
}

export enum COMMENT_TYPE {
  POLICY = 'WELLO_COMMENT_TYPE_POLICY',
  POLICY_INFO = 'WELLO_COMMENT_TYPE_BEST_CONTENTS',
  HOMETOWN_NEWS = 'WELLO_COMMENT_TYPE_HOMETOWN_NEWS',
}

export enum COMMENT_SORT_TYPE {
  POPULARITY = 'POPULARITY',
  ENROLL = 'ENROLL',
  LATEST = 'LATEST',
}

export enum COMMENT_STATUS {
  DELETED = 'WELLO_COMMENT_STATUS_DELETE',
}

export enum PROVISION_TYPE {
  'C09-00' = '현금',
  'C09-01' = '현물',
  'C09-02' = '이용권',
  'C09-03' = '서비스',
  'C09-04' = '의료지원',
  'C09-05' = '상담/법률지원',
  'C09-06' = '문화/여가지원',
  'C09-07' = '민원',
  'C09-08' = '봉사/기부',
  'C09-10' = '교육지원',
  'C09-11' = '주거지원',
  'C09-09' = '기타',
}

export enum LIKE_TYPE {
  /** 정책 */
  POLICY = 'WELLO_LIKE_TYPE_POLICY',
  /** 정책 소식 */
  POLICY_INFO = 'WELLO_LIKE_TYPE_BEST_CONTENTS',
  /** 댓글 */
  COMMENT = 'WELLO_LIKE_TYPE_COMMENT',
  /** 댓글 */
  HOMETOWN_NEWS = 'WELLO_LIKE_TYPE_HOMETOWN_NEWS',
}

export enum BOOKMARK_TYPE {
  /** 정책 */
  POLICY = 'WELLO_WISHLIST_CONTENT_TYPE_POLICY',
  /** 정책 소식 */
  POLICY_INFO = 'WELLO_WISHLIST_CONTENT_TYPE_BEST_CONTENTS',
  /** 동네 소식 */
  HOMETOWN_NEWS = 'WELLO_WISHLIST_CONTENT_TYPE_HOMETOWN_NEWS',
}

export enum SEARCH_POLICY_CODE {
  C01 = 'C01',
  C02 = 'C02',
  C03 = 'C03',
  C04 = 'C04',
  C05 = 'C05',
  C06 = 'C06',
  C07 = 'C07',
  C12 = 'C12',
}

export enum WELLO_POLICY_CODE_TYPE {
  ETC = 'WELLO_POLICY_CODE_TYPE_ETC',
  INTEREST = 'WELLO_POLICY_CODE_TYPE_INTEREST',
  REQUIRED = 'WELLO_POLICY_CODE_TYPE_REQUIRED',
}

export enum WELLO_POLICY_CODE_FILTER {
  ADD = 'WELLO_POLICY_CODE_FILTER_ADD',
  BASIC = 'WELLO_POLICY_CODE_FILTER_BASIC',
}

export const INQUIRY_CATEGORY = {
  CONCIERGE: { code: 'INQUIRY_CATEGORY_POLICY', value: '컨시어지문의' },
  POLICY: { code: 'INQUIRY_CATEGORY_SERVICE', value: '정책문의' },
  SERVICE: { code: 'INQUIRY_CATEGORY_ERROR', value: '서비스제안' },
  ERROR: { code: 'INQUIRY_CATEGORY_USE', value: '오류신고' },
  USE: { code: 'INQUIRY_CATEGORY_USE', value: '이용문의' },
  ETC: { code: 'INQUIRY_CATEGORY_ETC', value: '기타' },
} as const;

export const INQUIRY_STATUS = {
  COMPLETE: {
    code: 'INQUIRY_STATUS_COMPLETE',
    value: '답변 완료',
  },
  WAITING: {
    code: 'INQUIRY_STATUS_WAITING',
    value: '답변 대기',
  },
} as const;

export enum MEMBER_JOIN_STATUS {
  /** 닉네임 없음 */
  NO_NICKNAME = 'WELLO_MEMBER_FILTER_SAVE_STATUS_NICK',
  /** 필터 아예 없음 */
  EMPTY_FILTER = 'WELLO_MEMBER_FILTER_SAVE_STATUS_EMPTY',
  /** 기본 필터 부족 */
  WEAKNESS_FILTER = 'WELLO_MEMBER_FILTER_SAVE_STATUS_WEAKNESS',
  /**
   * 전화번호 없음
   * @description 실제 MEMBER_FILTER_SAVE_STATUS는 아니지만 전화번호가 없으면 완료가 아님
   */
  NO_PHONE = 'MEMBER_STATUS_MISSING_CELLPHONE',
  /** 기본 필터 저장 완료 */
  COMPLETE = 'WELLO_MEMBER_FILTER_SAVE_STATUS_COMPLETE',
}

export enum TERM_CATEGORY {
  /** 서비스 이용약관 */
  SERVICE_TERMS = 'use_service',
  /** 개인정보 처리방침 */
  PRIVACY_POLICY = 'privacy_policy',
  /** 마케팅 이용동의 */
  MARKETING_TERMS = 'marketing',
  /** 공모전 참여 서약 및 저작권 안내 */
  EVENT_PLEDGE = 'wello_event_pledge',
  /** 공모전 개인정보 처리방침 */
  EVENT_PRIVACY_POLICY = 'wello_event_privacy_policy',
}

export enum VERIFICATION_TYPE {
  SIGN_UP = 'VERIFICATION_TYPE_WELLO_SIGN_UP',
  PROFILE_UPDATE = 'VERIFICATION_TYPE_WELLO_PROFILE_UPDATE',
  POLICY_APPLY = 'VERIFICATION_TYPE_WELLO_POLICY_APPLY',
}

export enum EASY_LOGIN_USAGE_CODE {
  /** 회원가입 */
  SIGN_UP = '01001',
  /** 정보변경 */
  UPDATE_INFO = '01002',
  /** ID 찾기 */
  FIND_ID = '01003',
  /** 비밀번호 찾기 */
  FIND_PASSWORD = '01004',
  /** 본인 확인용 */
  VERIFICATION = '01005',
  /** 성인 인증 */
  ADULT_VERIFICATION = '01006',
  /** 상품 구매/결제 */
  PURCHASE_PAYMENT = '01007',
  /** 기타 */
  OTHER = '01999',
}
