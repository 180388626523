export const getOsType = () => {
  const { userAgent } = navigator;

  return /windows/i.test(userAgent)
    ? 'windows'
    : /android/i.test(userAgent)
      ? 'android'
      : /iPad|iPhone|iPod|ios/.test(userAgent)
        ? 'ios'
        : /Mac/.test(userAgent)
          ? 'mac'
          : null;
};
