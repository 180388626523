/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { DefaultHttpResponse_ListWelloSeoResponse_ } from '../models/DefaultHttpResponse_ListWelloSeoResponse_';
import type { DefaultHttpResponse_WelloSeoPolicyTotalCountResponse_ } from '../models/DefaultHttpResponse_WelloSeoPolicyTotalCountResponse_';
import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';
export class WelloSeoService {
    /**
     * 웰로 SEO 목록 조회
     * @returns DefaultHttpResponse_ListWelloSeoResponse_ OK
     * @throws ApiError
     */
    public static metaPolicyWishlistUsingGet2({
        page,
        size,
        welloSeoType,
    }: {
        /**
         * 페이지
         */
        page: number,
        /**
         * 사이즈 10~20 기본: 10
         */
        size: number,
        /**
         * SEO 조회 TYPE(POLICY, BEST_CONTENTS, HOMETOWN_NEWS)
         */
        welloSeoType: 'BEST_CONTENTS' | 'HOMETOWN_NEWS' | 'POLICY',
    }): CancelablePromise<DefaultHttpResponse_ListWelloSeoResponse_> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/wello/v2/allows/seo',
            query: {
                'page': page,
                'size': size,
                'welloSeoType': welloSeoType,
            },
            errors: {
                500: `SQL 오류 및 기타 미처리 예외 상황입니다.`,
                502: `외부 API 오류 입니다.`,
            },
        });
    }
    /**
     * 웰로 SEO 총 개수 조회
     * @returns DefaultHttpResponse_WelloSeoPolicyTotalCountResponse_ OK
     * @throws ApiError
     */
    public static metaPolicyWishlistUsingGet3({
        welloSeoType,
    }: {
        /**
         * SEO 조회 TYPE(POLICY, BEST_CONTENTS, HOMETOWN_NEWS)
         */
        welloSeoType: 'BEST_CONTENTS' | 'HOMETOWN_NEWS' | 'POLICY',
    }): CancelablePromise<DefaultHttpResponse_WelloSeoPolicyTotalCountResponse_> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/wello/v2/allows/seo/count',
            query: {
                'welloSeoType': welloSeoType,
            },
            errors: {
                500: `SQL 오류 및 기타 미처리 예외 상황입니다.`,
                502: `외부 API 오류 입니다.`,
            },
        });
    }
}
