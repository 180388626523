/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { DefaultHttpResponse_Void_ } from '../models/DefaultHttpResponse_Void_';
import type { NiceSafeKeyRequest } from '../models/NiceSafeKeyRequest';
import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';
export class NiceService {
    /**
     * Nice SafeKey 발급요청
     * @returns DefaultHttpResponse_Void_ OK
     * @throws ApiError
     */
    public static sendNiceSafeKeyUsingPost({
        requestBody,
    }: {
        requestBody?: NiceSafeKeyRequest,
    }): CancelablePromise<DefaultHttpResponse_Void_> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/wello/v2/auth/nice-safe-key',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                500: `SQL 오류 및 기타 미처리 예외 상황입니다.`,
                502: `외부 API 오류 입니다.`,
            },
        });
    }
    /**
     * Nice 신용인증 성공시 이동될 URL
     * @returns DefaultHttpResponse_Void_ OK
     * @throws ApiError
     */
    public static checkPlusSendResultUsingGet(): CancelablePromise<DefaultHttpResponse_Void_> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/wello/v2/auth/nice-safe-key/check-plus-send-result',
            errors: {
                500: `SQL 오류 및 기타 미처리 예외 상황입니다.`,
                502: `외부 API 오류 입니다.`,
            },
        });
    }
    /**
     * Nice 신용인증 실패시 이동될 URL
     * @returns DefaultHttpResponse_Void_ OK
     * @throws ApiError
     */
    public static checkPlusFailUsingGet(): CancelablePromise<DefaultHttpResponse_Void_> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/wello/v2/auth/nice-safe-key/check-plus_fail',
            errors: {
                500: `SQL 오류 및 기타 미처리 예외 상황입니다.`,
                502: `외부 API 오류 입니다.`,
            },
        });
    }
}
