import type { Size } from '@/types';

import { cleanClassName } from '@wello-client/common/utils';

import styles from './Spinner.module.scss';

interface SpinnerProps {
  size?: Size | 'x-large';
  className?: string;
}

export const Spinner = ({ size = 'medium', className }: SpinnerProps) => {
  return (
    <div
      className={cleanClassName(
        `${styles.spinner} ${styles[size]} ${className}`,
      )}
    >
      <div className={styles.loader} />
    </div>
  );
};
