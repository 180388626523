import { type ServerResponse } from '@wello-client/common/utils';
import { createError } from '@wello-client/common/utils/normalizeServerRequest';
import axios from 'axios';

import { ENV } from '@/constants';

interface TermDetails {
  id: number;
  termsId: string;
  termsName: string;
  termsContent: string;
  termsStartDate: string;
}

interface TermApiResponse extends ServerResponse {
  context: TermDetails[];
}

export const fetchJejuTermList = async () => {
  try {
    const response = await axios.get<TermApiResponse>(
      `${ENV.NEXT_PUBLIC_JEJU_API_DOMAIN}/api/wello/api001`,
      {
        headers: {
          Authorization: `Basic ${ENV.NEXT_PUBLIC_JEJU_YOUTHDREAM_API_KEY}`,
        },
      },
    );

    return response.data.context;
  } catch (error) {
    throw createError();
  }
};
