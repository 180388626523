/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { DefaultHttpResponse_Void_ } from '../models/DefaultHttpResponse_Void_';
import type { JoinEventRequest } from '../models/JoinEventRequest';
import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';
export class KbPayApiService {
    /**
     * 이벤트 참여 요청
     * @returns DefaultHttpResponse_Void_ OK
     * @throws ApiError
     */
    public static joinEventUsingPost({
        requestBody,
    }: {
        requestBody?: JoinEventRequest,
    }): CancelablePromise<DefaultHttpResponse_Void_> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/wello/v2/allows/kb',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                500: `SQL 오류 및 기타 미처리 예외 상황입니다.`,
                502: `외부 API 오류 입니다.`,
            },
        });
    }
}
