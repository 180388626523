import type { FlutterInAppWebViewWindow } from '@/modules/wello-app';

import { useEffect, useLayoutEffect, useState } from 'react';
import { createPortal } from 'react-dom';
import { Settings } from 'react-feather';

import { Icon } from '@wello-client/common';
import { cleanClassName } from '@wello-client/common/utils';
import Link from 'next/link';
import { usePathname } from 'next/navigation';

import BellIcon from '@/assets/svgs/bell.svg';
import Logo from '@/assets/svgs/logo.svg';
import { Button } from '@/components/server';
import { MODULE_ROUTES, ROUTES, SELECTOR } from '@/constants';
import { useCustomRouter } from '@/hooks/useCustomRouter';
import { WEBVIEW_TYPE, useDeviceInfoStore } from '@/stores/DeviceStore';
import { useGlobalStore } from '@/stores/GlobalStore';

import styles from './PageNavigation.module.scss';

interface PageNavigationProps {
  className?: string;
  title?: string | React.ReactNode;
  children?: React.ReactNode;
  logo?: boolean;
  searchButton?: boolean;
  bellButton?: boolean;
  shareButton?: boolean;
  settingsButton?: boolean;
  backButton?: boolean;
  onClick?: VoidFunction;
  onClickBack?: VoidFunction | string;
}

export const PageNavigation = ({
  logo,
  title,
  children,
  backButton = true,
  searchButton,
  bellButton,
  shareButton,
  settingsButton,
  onClick,
  onClickBack,
  className,
}: PageNavigationProps) => {
  const router = useCustomRouter();

  const setShareLink = useGlobalStore((state) => state.setShareLink);
  const [isKbWebview, setAppModal] = useDeviceInfoStore((state) => [
    state.webviewType === WEBVIEW_TYPE.KB_PAY,
    state.setAppModal,
  ]);

  useEffect(() => {
    const _window = window as FlutterInAppWebViewWindow;

    if (_window.router) {
      _window.router.back = backButton
        ? () => router.back()
        : () => {
            setAppModal({ isOpened: true, type: 'exit' });
          };

      return () => {
        _window.router.back = () => {};
      };
    }
  }, [backButton, router, setAppModal]);

  const [pageNavWrapper, setPageNavWrapper] = useState<HTMLElement | null>(
    null,
  );

  useLayoutEffect(() => {
    setPageNavWrapper(document.getElementById(SELECTOR.PAGE_NAV_WRAPPER));
  }, []);

  const pathname = usePathname();
  if (isKbWebview && pathname !== MODULE_ROUTES.KB_HOME.pathname) return null;

  return (
    <div className={styles['nav-size']}>
      {pageNavWrapper
        ? createPortal(
            <nav className={`${styles.nav} ${styles['nav-size']} ${className}`}>
              <div className={styles['item-container']}>
                {logo ? (
                  <Link aria-label="웰로" href={ROUTES.HOME.pathname}>
                    <Logo className={styles.logo} />
                  </Link>
                ) : null}
                {backButton ? (
                  <Button
                    aria-label="뒤로 가기"
                    className={styles['nav-item']}
                    theme="unset"
                    type="button"
                    onClick={
                      onClickBack
                        ? typeof onClickBack === 'string'
                          ? () => router.replace(onClickBack)
                          : onClickBack
                        : () => router.back()
                    }
                  >
                    <Icon name="Arrow-Left" />
                  </Button>
                ) : null}
              </div>
              <h3
                className={cleanClassName(
                  `${styles.title} ${onClick && styles['cursor-pointer']}`,
                )}
                onClick={onClick}
              >
                {title}
              </h3>
              <div className={`${styles['item-container']} ${styles.right}`}>
                {children}
                {shareButton ? (
                  <Button
                    aria-label="공유하기"
                    className={styles['nav-item']}
                    theme="unset"
                    onClick={() => {
                      setShareLink(location.href);
                    }}
                  >
                    <Icon name="Share-Link" />
                  </Button>
                ) : null}
                {settingsButton ? (
                  <Button
                    aria-label="설정"
                    className={styles['nav-item']}
                    theme="unset"
                    onClick={() => router.push(ROUTES.SETTINGS.pathname)}
                  >
                    <Settings className={styles.icon} />
                  </Button>
                ) : null}
                {searchButton ? (
                  <Button
                    aria-label="검색으로 이동"
                    className={styles['nav-item']}
                    theme="unset"
                    onClick={() => router.push(ROUTES.SEARCH.pathname)}
                  >
                    <Icon name="Magnifying-Glass" />
                  </Button>
                ) : null}
                {bellButton ? (
                  <Button
                    aria-label="알림으로 이동"
                    className={styles['nav-item']}
                    theme="unset"
                    onClick={() => router.push(ROUTES.PUSH.pathname)}
                  >
                    <BellIcon className={styles.bell} />
                  </Button>
                ) : null}
              </div>
            </nav>,
            pageNavWrapper,
          )
        : null}
    </div>
  );
};
