/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { DefaultHttpResponse } from '../models/DefaultHttpResponse';
import type { DefaultHttpResponse_DetailMemberWelloNicknameResponse_ } from '../models/DefaultHttpResponse_DetailMemberWelloNicknameResponse_';
import type { DefaultHttpResponse_DetailWelloLandingResponse_ } from '../models/DefaultHttpResponse_DetailWelloLandingResponse_';
import type { DefaultHttpResponse_DeviceInfoMessageReceiveResponse_ } from '../models/DefaultHttpResponse_DeviceInfoMessageReceiveResponse_';
import type { DefaultHttpResponse_ListBannerResponse_ } from '../models/DefaultHttpResponse_ListBannerResponse_';
import type { DefaultHttpResponse_ListLiveWelloCommentResponse_ } from '../models/DefaultHttpResponse_ListLiveWelloCommentResponse_';
import type { DefaultHttpResponse_ListNoticeResponse_ } from '../models/DefaultHttpResponse_ListNoticeResponse_';
import type { DefaultHttpResponse_ListWelloAppPushResponse_ } from '../models/DefaultHttpResponse_ListWelloAppPushResponse_';
import type { DefaultHttpResponse_ListWelloCommentResponse_ } from '../models/DefaultHttpResponse_ListWelloCommentResponse_';
import type { DefaultHttpResponse_MetaCodeAllResponse_ } from '../models/DefaultHttpResponse_MetaCodeAllResponse_';
import type { DefaultHttpResponse_NewWelloAppPushCntDto_ } from '../models/DefaultHttpResponse_NewWelloAppPushCntDto_';
import type { DefaultHttpResponse_NoticeDto_ } from '../models/DefaultHttpResponse_NoticeDto_';
import type { DefaultHttpResponse_SituationListResponse_ } from '../models/DefaultHttpResponse_SituationListResponse_';
import type { DefaultHttpResponse_SituationPolicyListResponse_ } from '../models/DefaultHttpResponse_SituationPolicyListResponse_';
import type { DefaultHttpResponse_Void_ } from '../models/DefaultHttpResponse_Void_';
import type { DefaultHttpResponse_WelloPickListResponse_ } from '../models/DefaultHttpResponse_WelloPickListResponse_';
import type { DefaultHttpResponse_WelloPickPolicyListResponse_ } from '../models/DefaultHttpResponse_WelloPickPolicyListResponse_';
import type { MessageReceiveUpdateRequest } from '../models/MessageReceiveUpdateRequest';
import type { SaveLogAppLoginRequest } from '../models/SaveLogAppLoginRequest';
import type { SaveWelloDeviceInfoRequest } from '../models/SaveWelloDeviceInfoRequest';
import type { SaveWelloNonmemberRegionRequest } from '../models/SaveWelloNonmemberRegionRequest';
import type { SendExtAppPushRequest } from '../models/SendExtAppPushRequest';
import type { UpdateWelloAppPushReadRequest } from '../models/UpdateWelloAppPushReadRequest';
import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';
export class ApiService {
    /**
     * 배너 목록 조회
     * 배너 목록을 조회합니다
     * @returns DefaultHttpResponse_ListBannerResponse_ OK
     * @throws ApiError
     */
    public static bannerListUsingGet1({
        contentsTypeCd,
    }: {
        /**
         * 콘텐츠 타입 코드 - (Parent Code) BANNER_CONTENTS_TYPE
         */
        contentsTypeCd: string,
    }): CancelablePromise<DefaultHttpResponse_ListBannerResponse_> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/wello/v2/allows/banner',
            query: {
                'contents_type_cd': contentsTypeCd,
            },
            errors: {
                500: `SQL 오류 및 기타 미처리 예외 상황입니다.`,
                502: `외부 API 오류 입니다.`,
            },
        });
    }
    /**
     * 블록리스트 초기화
     * 블록리스트 추가된 경우 초기화 합니다
     * @returns DefaultHttpResponse_Void_ OK
     * @throws ApiError
     */
    public static commonCodeInitUsingPost2({
        password,
    }: {
        /**
         * password
         */
        password: string,
    }): CancelablePromise<DefaultHttpResponse_Void_> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/wello/v2/allows/blocklist/reset',
            query: {
                'password': password,
            },
            errors: {
                500: `SQL 오류 및 기타 미처리 예외 상황입니다.`,
                502: `외부 API 오류 입니다.`,
            },
        });
    }
    /**
     * 앱 로그인 요청결과 저장
     * @returns DefaultHttpResponse_Void_ OK
     * @throws ApiError
     */
    public static saveLoginResultUsingPost1({
        requestBody,
    }: {
        requestBody?: SaveLogAppLoginRequest,
    }): CancelablePromise<DefaultHttpResponse_Void_> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/wello/v2/allows/log-app-login',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                500: `SQL 오류 및 기타 미처리 예외 상황입니다.`,
                502: `외부 API 오류 입니다.`,
            },
        });
    }
    /**
     * 신규 닉네임 조회
     * 신규 닉네임을 조회합니다
     * @returns DefaultHttpResponse_DetailMemberWelloNicknameResponse_ OK
     * @throws ApiError
     */
    public static noticeListUsingGet1(): CancelablePromise<DefaultHttpResponse_DetailMemberWelloNicknameResponse_> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/wello/v2/allows/member-wello/nickname',
            errors: {
                500: `SQL 오류 및 기타 미처리 예외 상황입니다.`,
                502: `외부 API 오류 입니다.`,
            },
        });
    }
    /**
     * 메타코드 정보 조회
     * 메타코드 및 메타코드의 하위 코드까지 조회합니다
     * @returns DefaultHttpResponse_MetaCodeAllResponse_ OK
     * @throws ApiError
     */
    public static metaCodeAllUsingGet1({
        metaCode,
        step,
    }: {
        /**
         * 메타코드
         */
        metaCode: string,
        /**
         * 스탭
         */
        step?: number,
    }): CancelablePromise<DefaultHttpResponse_MetaCodeAllResponse_> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/wello/v2/allows/meta-code',
            query: {
                'meta_code': metaCode,
                'step': step,
            },
            errors: {
                500: `SQL 오류 및 기타 미처리 예외 상황입니다.`,
                502: `외부 API 오류 입니다.`,
            },
        });
    }
    /**
     * 공통코드 초기화
     * 공통코드가 추가된 경우 초기화 합니다
     * @returns DefaultHttpResponse OK
     * @throws ApiError
     */
    public static commonCodeInitUsingPost3({
        password,
    }: {
        /**
         * password
         */
        password: string,
    }): CancelablePromise<DefaultHttpResponse> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/wello/v2/allows/meta-code/reset',
            query: {
                'password': password,
            },
            errors: {
                500: `SQL 오류 및 기타 미처리 예외 상황입니다.`,
                502: `외부 API 오류 입니다.`,
            },
        });
    }
    /**
     * 공지사항 조회
     * 공지사항을 조회합니다
     * @returns DefaultHttpResponse_ListNoticeResponse_ OK
     * @throws ApiError
     */
    public static noticeListUsingGet2(): CancelablePromise<DefaultHttpResponse_ListNoticeResponse_> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/wello/v2/allows/cs/notice',
            errors: {
                500: `SQL 오류 및 기타 미처리 예외 상황입니다.`,
                502: `외부 API 오류 입니다.`,
            },
        });
    }
    /**
     * 공지사항 조회
     * 공지사항을 조회합니다
     * @returns DefaultHttpResponse_NoticeDto_ OK
     * @throws ApiError
     */
    public static noticeUsingGet1({
        noticeId,
    }: {
        /**
         * 공지사항 번호
         */
        noticeId: number,
    }): CancelablePromise<DefaultHttpResponse_NoticeDto_> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/wello/v2/allows/cs/notice/{notice_id}',
            path: {
                'notice_id': noticeId,
            },
            errors: {
                500: `SQL 오류 및 기타 미처리 예외 상황입니다.`,
                502: `외부 API 오류 입니다.`,
            },
        });
    }
    /**
     * 정책 꾸러미 목록 조회
     * 정책 꾸러미 목록을 조회합니다
     * @returns DefaultHttpResponse_SituationListResponse_ OK
     * @throws ApiError
     */
    public static situationListUsingGet3(): CancelablePromise<DefaultHttpResponse_SituationListResponse_> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/wello/v2/allows/contents/situation',
            errors: {
                500: `SQL 오류 및 기타 미처리 예외 상황입니다.`,
                502: `외부 API 오류 입니다.`,
            },
        });
    }
    /**
     * 정책 꾸러미 정책 목록 조회
     * 정책 꾸러미 정책 목록을 조회합니다
     * @returns DefaultHttpResponse_SituationPolicyListResponse_ OK
     * @throws ApiError
     */
    public static getSituationPolicyListUsingGet3({
        situationId,
    }: {
        /**
         * 정책 꾸러미 번호
         */
        situationId: number,
    }): CancelablePromise<DefaultHttpResponse_SituationPolicyListResponse_> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/wello/v2/allows/contents/situation/{situation_id}',
            path: {
                'situation_id': situationId,
            },
            errors: {
                500: `SQL 오류 및 기타 미처리 예외 상황입니다.`,
                502: `외부 API 오류 입니다.`,
            },
        });
    }
    /**
     * 웰로 앱 푸시 목록 조회
     * @returns DefaultHttpResponse_ListWelloAppPushResponse_ OK
     * @throws ApiError
     */
    public static welloAppPushListUsingGet1({
        fcmToken,
        page,
        size,
        welloAppPushCategoryId,
    }: {
        /**
         * 디바이스 토큰
         */
        fcmToken: string,
        /**
         * 페이지
         */
        page: number,
        /**
         * 사이즈(10~)
         */
        size: number,
        /**
         * 앱 푸시 카테고리 ID
         */
        welloAppPushCategoryId?: number,
    }): CancelablePromise<DefaultHttpResponse_ListWelloAppPushResponse_> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/wello/v2/allows/wello-app-push',
            query: {
                'fcm_token': fcmToken,
                'page': page,
                'size': size,
                'wello_app_push_category_id': welloAppPushCategoryId,
            },
            errors: {
                500: `SQL 오류 및 기타 미처리 예외 상황입니다.`,
                502: `외부 API 오류 입니다.`,
            },
        });
    }
    /**
     * 웰로 앱 푸시 읽음 처리
     * @returns DefaultHttpResponse_Void_ OK
     * @throws ApiError
     */
    public static updateWelloAppPushReadUsingPut3({
        requestBody,
    }: {
        requestBody?: UpdateWelloAppPushReadRequest,
    }): CancelablePromise<DefaultHttpResponse_Void_> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/wello/v2/allows/wello-app-push',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                500: `SQL 오류 및 기타 미처리 예외 상황입니다.`,
                502: `외부 API 오류 입니다.`,
            },
        });
    }
    /**
     * 웰로 앱 푸시 알림 건수 조회
     * @returns DefaultHttpResponse_NewWelloAppPushCntDto_ OK
     * @throws ApiError
     */
    public static newWelloAppPushCntUsingGet1({
        fcmToken,
    }: {
        /**
         * fcm_token
         */
        fcmToken: string,
    }): CancelablePromise<DefaultHttpResponse_NewWelloAppPushCntDto_> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/wello/v2/allows/wello-app-push/count',
            query: {
                'fcm_token': fcmToken,
            },
            errors: {
                500: `SQL 오류 및 기타 미처리 예외 상황입니다.`,
                502: `외부 API 오류 입니다.`,
            },
        });
    }
    /**
     * 웰로 앱 푸시(외부) 요청
     * @returns DefaultHttpResponse_Void_ OK
     * @throws ApiError
     */
    public static sendExtAppPushUsingPost({
        requestBody,
    }: {
        requestBody?: SendExtAppPushRequest,
    }): CancelablePromise<DefaultHttpResponse_Void_> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/wello/v2/allows/wello-app-push/ext',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                500: `SQL 오류 및 기타 미처리 예외 상황입니다.`,
                502: `외부 API 오류 입니다.`,
            },
        });
    }
    /**
     * 웰로 앱 푸시 트래킹&읽음 처리
     * @returns DefaultHttpResponse_Void_ OK
     * @throws ApiError
     */
    public static updateWelloAppPushReadUsingPost({
        welloAppPushReceiverId,
    }: {
        /**
         * wello_app_push_receiver_id
         */
        welloAppPushReceiverId: number,
    }): CancelablePromise<DefaultHttpResponse_Void_> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/wello/v2/allows/wello-app-push/{wello_app_push_receiver_id}',
            path: {
                'wello_app_push_receiver_id': welloAppPushReceiverId,
            },
            errors: {
                500: `SQL 오류 및 기타 미처리 예외 상황입니다.`,
                502: `외부 API 오류 입니다.`,
            },
        });
    }
    /**
     * 댓글 목록 조회
     * @returns DefaultHttpResponse_ListWelloCommentResponse_ OK
     * @throws ApiError
     */
    public static getMetaPolicyCommentListUsingGet1({
        commentTypeCd,
        contentId,
        page,
        size,
        commentSort,
    }: {
        /**
         * 댓글 타입 cd - (Parent Code) WELLO_COMMENT_TYPE
         */
        commentTypeCd: string,
        /**
         * 컨텐츠 ID - policy_id, best_contents_id, hometown_news_id
         */
        contentId: number,
        /**
         * 페이지
         */
        page: number,
        /**
         * 사이즈
         */
        size: number,
        /**
         * 정렬순서 - POPULARITY, ENROLL, LATEST
         */
        commentSort?: 'ENROLL' | 'LATEST' | 'POPULARITY',
    }): CancelablePromise<DefaultHttpResponse_ListWelloCommentResponse_> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/wello/v2/allows/wello-comment',
            query: {
                'comment_sort': commentSort,
                'comment_type_cd': commentTypeCd,
                'content_id': contentId,
                'page': page,
                'size': size,
            },
            errors: {
                500: `SQL 오류 및 기타 미처리 예외 상황입니다.`,
                502: `외부 API 오류 입니다.`,
            },
        });
    }
    /**
     * 웰로 실시간 정책 의견
     * @returns DefaultHttpResponse_ListLiveWelloCommentResponse_ OK
     * @throws ApiError
     */
    public static getLiveWelloCommentListUsingGet3({
        commentTypeCd,
        size,
    }: {
        /**
         * 댓글 타입 cd - (Parent Code) WELLO_COMMENT_TYPE
         */
        commentTypeCd?: string,
        /**
         * 사이즈 (default 3)
         */
        size?: number,
    }): CancelablePromise<DefaultHttpResponse_ListLiveWelloCommentResponse_> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/wello/v2/allows/wello-comment/live',
            query: {
                'comment_type_cd': commentTypeCd,
                'size': size,
            },
            errors: {
                500: `SQL 오류 및 기타 미처리 예외 상황입니다.`,
                502: `외부 API 오류 입니다.`,
            },
        });
    }
    /**
     * 웰로 디바이스 정보 저장
     * @returns DefaultHttpResponse_Void_ OK
     * @throws ApiError
     */
    public static saveAllowsWelloDeviceInfoUsingPost1({
        requestBody,
    }: {
        requestBody?: SaveWelloDeviceInfoRequest,
    }): CancelablePromise<DefaultHttpResponse_Void_> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/wello/v2/allows/wello-device-info',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                500: `SQL 오류 및 기타 미처리 예외 상황입니다.`,
                502: `외부 API 오류 입니다.`,
            },
        });
    }
    /**
     * 알림수신여부 조회
     * 알림수신 여부를 조회합니다
     * @returns DefaultHttpResponse_DeviceInfoMessageReceiveResponse_ OK
     * @throws ApiError
     */
    public static messageReceiveUsingGet1({
        fcmToken,
        os,
    }: {
        /**
         * 디바이스 토큰
         */
        fcmToken: string,
        /**
         * 디바이스 OS
         */
        os: string,
    }): CancelablePromise<DefaultHttpResponse_DeviceInfoMessageReceiveResponse_> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/wello/v2/allows/wello-device-info/message-receive',
            query: {
                'fcm_token': fcmToken,
                'os': os,
            },
            errors: {
                500: `SQL 오류 및 기타 미처리 예외 상황입니다.`,
                502: `외부 API 오류 입니다.`,
            },
        });
    }
    /**
     * 알림수신여부 수정
     * 알림수신 여부를 수정합니다
     * @returns DefaultHttpResponse_Void_ OK
     * @throws ApiError
     */
    public static updateMessageReceiveUsingPut1({
        requestBody,
    }: {
        requestBody?: MessageReceiveUpdateRequest,
    }): CancelablePromise<DefaultHttpResponse_Void_> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/wello/v2/allows/wello-device-info/message-receive',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                500: `SQL 오류 및 기타 미처리 예외 상황입니다.`,
                502: `외부 API 오류 입니다.`,
            },
        });
    }
    /**
     * 웰로 랜딩페이지 데이터 조회
     * @returns DefaultHttpResponse_DetailWelloLandingResponse_ OK
     * @throws ApiError
     */
    public static detailWelloLandingUsingGet1(): CancelablePromise<DefaultHttpResponse_DetailWelloLandingResponse_> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/wello/v2/allows/wello-landing',
            errors: {
                500: `SQL 오류 및 기타 미처리 예외 상황입니다.`,
                502: `외부 API 오류 입니다.`,
            },
        });
    }
    /**
     * 비회원 대상지역 정보 저장
     * @returns DefaultHttpResponse_Void_ OK
     * @throws ApiError
     */
    public static saveWelloNonmemberRegionUsingPost1({
        requestBody,
    }: {
        requestBody?: SaveWelloNonmemberRegionRequest,
    }): CancelablePromise<DefaultHttpResponse_Void_> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/wello/v2/allows/wello-nonmember-region',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                500: `SQL 오류 및 기타 미처리 예외 상황입니다.`,
                502: `외부 API 오류 입니다.`,
            },
        });
    }
    /**
     * 웰로 대상별 정책 - 대상 조회
     * 웰로 대상별 정책 - 대상 정보를 조회합니다
     * @returns DefaultHttpResponse_WelloPickListResponse_ OK
     * @throws ApiError
     */
    public static welloPickListUsingGet3(): CancelablePromise<DefaultHttpResponse_WelloPickListResponse_> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/wello/v2/allows/contents/pick',
            errors: {
                500: `SQL 오류 및 기타 미처리 예외 상황입니다.`,
                502: `외부 API 오류 입니다.`,
            },
        });
    }
    /**
     * 웰로 대상별 정책 조회
     * 웰로 대상별 정책 정보를 조회합니다
     * @returns DefaultHttpResponse_WelloPickPolicyListResponse_ OK
     * @throws ApiError
     */
    public static welloPickPolicyListUsingGet3({
        welloPickId,
    }: {
        /**
         * 웰로 대상별 정책 - 대상 번호
         */
        welloPickId: number,
    }): CancelablePromise<DefaultHttpResponse_WelloPickPolicyListResponse_> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/wello/v2/allows/contents/pick/{wello_pick_id}',
            path: {
                'wello_pick_id': welloPickId,
            },
            errors: {
                500: `SQL 오류 및 기타 미처리 예외 상황입니다.`,
                502: `외부 API 오류 입니다.`,
            },
        });
    }
}
