import type { Metadata, Viewport } from 'next';

import { PRODUCT_DOMAIN } from './env';

export const DEFAULT_META = {
  generator: 'Next.js',
  applicationName: '웰로',
  metadataBase: new URL(PRODUCT_DOMAIN),
  manifest: `${PRODUCT_DOMAIN}/manifest.json`,
  keywords: ['웰로', '정책', '취업', '육아', '정부', '보조금', '혜택'],
  title: '웰로 - 5,200만 국민을 위한 개인 맞춤형 정책 추천 서비스',
  authors: [
    {
      name: '주식회사 웰로',
      url: 'https://www.wello.info',
    },
  ],
  publisher: '주식회사 웰로',
  description:
    '내게 딱 맞는 정책을 매일 추천해드려요. ㅡ 청년부터 노년까지, 취업&창업·육아·대출 등 다양한 분야의 보조금·지원금 혜택을 찾아보세요!',
  openGraph: {
    images: '/assets/pngs/default_og_20240405.png',
    title: '웰로 - 5,200만 국민을 위한 개인 맞춤형 정책 추천 서비스',
    siteName: '웰로',
  },
} satisfies Metadata;

export const DEFAULT_VIEWPORT = {
  width: 'device-width',
  initialScale: 1,
  maximumScale: 1,
  minimumScale: 1,
  userScalable: false,
  themeColor: '#ffffff',
} satisfies Viewport;
