import type {
  COMMENT_SORT_TYPE,
  EASY_LOGIN_USAGE_CODE,
  LOGIN_TYPE,
  TERM_CATEGORY,
} from '@/api';
import type { CustomPolicyFilterType } from '@/hooks/useGetFilterMetaData';
import type { PROXY_TARGET } from '@/pages/api/proxy/[...all]';
import type {
  MyInfoTab,
  FAMILY_PROFILE_UPDATE_PAGE_TYPE,
  EasyCertificationParams,
} from '@/types';
import type { ERROR_CODE } from '@wello-client/common/utils';

import { createRoute } from '@/utils';

export const ROUTES = {
  TEST: createRoute('/test'),

  HOME: createRoute('/'),

  LOGIN: createRoute<{
    searchParams: {
      logout?: boolean;
      redirect?: string;
      disableOutlink?: boolean;
    };
  }>('/login', {
    hasBottomNav: false,
  }),

  PEEC_EVENT: createRoute('/event/peec', {
    hasBottomNav: false,
  }),

  PEEC_APPLY: createRoute('/event/peec/apply', {
    hasBottomNav: false,
  }),

  AUTH: createRoute<{
    searchParams: {
      'login-type'?: LOGIN_TYPE;
      'social-login-token'?: string;
      code?: string;
      state?: string;
    };
  }>('/login/auth', {
    hasBottomNav: false,
  }),

  PROFILE: createRoute('/my-info'),

  PUSH: createRoute('/push', {
    hasBottomNav: false,
  }),

  PROFILE_TAB: createRoute<{
    searchParams: { tab: MyInfoTab };
  }>('/my-info'),
  PROFILE_EDIT: createRoute('/my-info/edit'),

  FAMILY_PROFILE: createRoute('/my-info/settings/family', {
    hasBottomNav: false,
  }),

  FAMILY_PROFILE_UPDATE: createRoute<{
    dynamicPath: {
      updateType: FAMILY_PROFILE_UPDATE_PAGE_TYPE;
    };
    searchParams: {
      familyId?: number;
    };
  }>(({ updateType }) => `/my-info/settings/family/${updateType}`, {
    hasBottomNav: false,
  }),

  FAMILY_FILTER_UPDATE: createRoute<{
    dynamicPath: {
      updateType: FAMILY_PROFILE_UPDATE_PAGE_TYPE;
    };
    searchParams: {
      familyId?: number;
    };
  }>(({ updateType }) => `/my-info/settings/family/${updateType}/filter`, {
    hasBottomNav: false,
  }),

  SEARCH: createRoute<{
    searchParams: {
      /** @description 검색어 */
      q: string;
    };
  }>('/search'),

  POLICY_SEARCH: createRoute('/policy/search'),

  POLICY_SEARCH_REGION: createRoute('/policy/search/region'),

  POLICY_SEARCH_AGE: createRoute('/policy/search/age'),

  POLICY_SEARCH_DETAIL: createRoute('/policy/search/detail'),

  RECOMMEND_POLICY_LIST: createRoute<{
    searchParams: { familyId?: number };
  }>('/recommend-policy'),

  POLICY_DETAIL: createRoute<{
    dynamicPath: { idIdx: string };
  }>(({ idIdx }) => `/policy/${idIdx}`, {
    hasBottomNav: false,
  }),

  POLICY_INFO_LIST: createRoute('/policy-info'),

  HOMETOWN_NEWS: createRoute('/hometown-news'),

  HOMETOWN_NEWS_DETAIL: createRoute<{
    dynamicPath: { idIdx: string };
  }>(({ idIdx }) => `/hometown-news/${idIdx}`, {
    hasBottomNav: false,
  }),

  HOMETOWN_NEWS_SEARCH: createRoute('/hometown-news/search', {
    hasBottomNav: false,
  }),

  /**@name 설정 */
  SETTINGS: createRoute('/my-info/settings', {
    hasBottomNav: false,
  }),

  /**@name 알림설정 */
  NOTIFICATION_SETTINGS: createRoute('/my-info/settings/notifications'),

  /**@name 계정연동 */
  ACCOUNT_LINKING: createRoute('/my-info/settings/account-linking'),

  /**@name 공지사항*/
  NOTICE: createRoute('/my-info/settings/notice'),

  /**@name 공지사항_상세 */
  NOTICE_DETAIL: createRoute<{
    dynamicPath: { id: number };
  }>(({ id }) => `/my-info/settings/notice/${id}`),

  /**@name 자주_묻는_질문 */
  FAQ: createRoute('/my-info/settings/faq'),

  /**@name 고객_문의 */
  INQUIRY_LIST: createRoute('/my-info/settings/inquiry', {
    hasBottomNav: false,
  }),

  /**@name 문의_하기 */
  INQUIRY_EDIT: createRoute('/my-info/settings/inquiry/register'),

  /**@name 문의_상세 */
  INQUIRY_DETAIL: createRoute<{
    dynamicPath: { id: number };
  }>(({ id }) => `/my-info/settings/inquiry/${id}`, {
    hasBottomNav: false,
  }),

  /**@name 회원탈퇴 */
  RESIGN_MEMBER: createRoute('/my-info/settings/resign-member'),

  POLICY_INFO_DETAIL: createRoute<{
    dynamicPath: { idIdx: string };
  }>(({ idIdx }) => `/policy-info/${idIdx}`, {
    hasBottomNav: false,
  }),

  PICK_POLICY_LIST: createRoute<{ dynamicPath: { id: number } }>(
    ({ id }) => `/pick/${id}`,
    {
      hasBottomNav: false,
    },
  ),

  SITUATION_POLICY_LIST: createRoute<{
    dynamicPath: { id: number };
  }>(({ id }) => `/situation/${id}`, {
    hasBottomNav: false,
  }),

  /** @name 맞춤_정책_필터_수정 */
  MY_POLICY_FILTER: createRoute('/my-info/edit/filter', {
    hasBottomNav: false,
  }),

  HOMETOWN_NEWS_DETAIL_COMMENT_REPLY: createRoute<{
    dynamicPath: {
      contentId?: number;
      commentId: number;
    };
    searchParams: {
      sortType?: COMMENT_SORT_TYPE;
    };
  }>(({ contentId, commentId }) => `/hometown-news/${contentId}/${commentId}`, {
    hasBottomNav: false,
  }),

  POLICY_DETAIL_COMMENT_REPLY: createRoute<{
    dynamicPath: {
      contentId?: number;
      commentId: number;
    };
    searchParams: {
      sortType?: COMMENT_SORT_TYPE;
    };
  }>(({ contentId, commentId }) => `/policy/${contentId}/${commentId}`, {
    hasBottomNav: false,
  }),

  /**@name 장학재단 관련 */
  POLICY_KOSAF_APPLY: createRoute('/policy/apply/1', {
    hasBottomNav: false,
  }),
  POLICY_REPORT: createRoute('/policy/report', {
    hasBottomNav: false,
  }),

  POLICY_REPORT_DETAIL: createRoute<{
    searchParams: {
      /** @description 장학재단 신고 내역 상세 */
      report: string;
      reportYear: string;
      reportDate: string;
    };
  }>('/policy/report/detail'),

  /**@name 제주도 관련 */
  JEJU_APPLY: createRoute('/jeju/apply', {
    hasBottomNav: false,
  }),
  JEJU_REPORT: createRoute('/jeju/report', {
    hasBottomNav: false,
  }),
  JEJU_REPORT_DETAIL: createRoute<{
    dynamicPath: { id: number };
  }>(({ id }) => `/jeju/report/${id}`, { hasBottomNav: false }),

  POLICY_CONSENT: createRoute('/policy/consent', { hasBottomNav: false }),
  POLICY_NOTICE: createRoute('/policy/notice', { hasBottomNav: false }),
  POLICY_NOTICE_DETAIL: createRoute<{
    dynamicPath: { year: number };
  }>(({ year }) => `/policy/notice/${year}`, { hasBottomNav: false }),

  POLICY_INFO_DETAIL_COMMENT_REPLY: createRoute<{
    dynamicPath: {
      contentId?: number;
      commentId: number;
    };
    searchParams: {
      sortType?: COMMENT_SORT_TYPE;
    };
  }>(({ contentId, commentId }) => `/policy-info/${contentId}/${commentId}`, {
    hasBottomNav: false,
  }),

  CROSS_DOMAIN_AUTH: createRoute<{
    searchParams: {
      accessToken: string;
      refreshToken: string;
    };
  }>('/login/auth/cross-domain', {
    hasBottomNav: false,
  }),

  CROSS_DOMAIN_CALLBACK: createRoute<{
    searchParams: {
      accessToken: string;
      refreshToken: string;
      redirect?: string;
      redirectSearchParams?: string;
    };
  }>('/login/auth/cross-domain/callback'),

  JOIN_USER_INFO: createRoute<{
    searchParams: {
      verificationId?: number;
    };
  }>('/join/user-info', {
    hasBottomNav: false,
  }),

  JOIN_FILTER_SELECT: createRoute('/join/filter', {
    hasBottomNav: false,
  }),

  JOIN_FILTER: createRoute<{
    dynamicPath: { filter: CustomPolicyFilterType };
  }>(({ filter }) => `/join/filter/${filter}`, {
    hasBottomNav: false,
  }),

  JOIN_INTERESTED_FILTER: createRoute('/join/interested', {
    hasBottomNav: false,
  }),

  TERMS: createRoute<{
    dynamicPath: { terms: TERM_CATEGORY };
  }>(({ terms }) => `/terms/${terms}`, {
    hasBottomNav: false,
  }),

  EASY_CERTIFICATION: createRoute<{
    searchParams: EasyCertificationParams;
  }>('/easy-certification', {
    hasBottomNav: false,
  }),
  EASY_CERTIFICATION_CALLBACK: createRoute<{
    searchParams: EasyCertificationParams & {
      encryptMOKKeyToken?: string;
      txId?: string;
      usageCode?: EASY_LOGIN_USAGE_CODE;
    };
  }>('/easy-certification/callback', {
    hasBottomNav: false,
  }),

  ERROR: createRoute<{
    dynamicPath: { code: ERROR_CODE };
  }>(({ code }) => `/error/${code}`, {
    hasBottomNav: false,
  }),

  NICE_CERTIFICATION: createRoute('/easy-certification/nice', {
    hasBottomNav: false,
  }),
  NICE_RESULT: createRoute<{
    dynamicPath: {
      result: 'pending' | 'success' | 'fail';
    };
  }>(({ result }) => `/easy-certification/nice/${result}`),
} as const;

export const API_ROUTES = {
  NAVER_AUTH: createRoute('/api/auth/naver'),
  GOOGLE_AUTH: createRoute('/api/auth/google'),
  APPLE_AUTH: createRoute('/api/auth/apple'),
  APPLE_TOKEN: createRoute('/api/auth/apple/token'),
  REDIRECT: createRoute('/api/redirect'),
  MOBILE_OK_REQUEST: createRoute<{
    searchParams: EasyCertificationParams & {
      usageCode: EASY_LOGIN_USAGE_CODE;
    };
  }>('/api/mobile-ok'),
  MOBILE_OK_CALLBACK: createRoute<{
    searchParams: EasyCertificationParams & {
      usageCode: EASY_LOGIN_USAGE_CODE;
      txId: string;
    };
  }>('/api/mobile-ok/callback'),
  JEJU_APP_API: createRoute('/api/jeju-app'),
  PROXY: createRoute<{
    dynamicPath: { endpoint: keyof typeof PROXY_TARGET };
  }>(({ endpoint }) => `/api/proxy/${endpoint}`),
} as const;

export const MODULE_ROUTES = {
  KB_HOME: createRoute('/kb-home'),
};
