import { createError } from '.';

export function assert(
  condition: unknown,
  error: Error | string = new Error(),
): asserts condition {
  if (!condition) {
    if (typeof error === 'string') {
      throw createError({ return_message: error });
    } else {
      throw createError();
    }
  }
}
