import { useRef } from 'react';

/**
 * @description state의 값을 구독하지 않고 최신 값을 가져올 수 있는 getter를 반환
 */
export const useGetter = <T>(value: T) => {
  const valueRef = useRef(value);

  valueRef.current = value;

  const getterRef = useRef(() => valueRef.current);

  return getterRef.current;
};
