import type { ServerResponse } from '@wello-client/common/utils';

import { createError } from '@wello-client/common/utils';
import axios from 'axios';

import { ENV } from '@/constants';

export interface ScheduleDetail {
  id: { adviceFormDate: string; adviceFormTime: string };
  adviceFormMaxCount: number;
  isOpen: boolean;
  isHoliday: string;
  formCount: number;
}

interface ScheduleApiResponse extends ServerResponse {
  context: ScheduleDetail[];
}

export const fetchJejuSchedule = async (startDate: string) => {
  try {
    const response = await axios.get<ScheduleApiResponse>(
      `${ENV.NEXT_PUBLIC_JEJU_API_DOMAIN}/api/wello/api100?startDate=${startDate}&endDate=${startDate}`,
      {
        headers: {
          Authorization: `Basic ${ENV.NEXT_PUBLIC_JEJU_YOUTHDREAM_API_KEY}`,
        },
      },
    );

    return response.data.context;
  } catch (error) {
    throw createError();
  }
};
