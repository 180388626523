/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { BasicSaveMemberFilterRequest } from '../models/BasicSaveMemberFilterRequest';
import type { CreateMemberFamilyInfoRequest } from '../models/CreateMemberFamilyInfoRequest';
import type { DefaultHttpResponse } from '../models/DefaultHttpResponse';
import type { DefaultHttpResponse_BestContentsWishlistResponse_ } from '../models/DefaultHttpResponse_BestContentsWishlistResponse_';
import type { DefaultHttpResponse_CreateWelloMemberFamilyResponse_ } from '../models/DefaultHttpResponse_CreateWelloMemberFamilyResponse_';
import type { DefaultHttpResponse_DuplicateMemberInfoResponse_ } from '../models/DefaultHttpResponse_DuplicateMemberInfoResponse_';
import type { DefaultHttpResponse_HometownNewsWishlistResponse_ } from '../models/DefaultHttpResponse_HometownNewsWishlistResponse_';
import type { DefaultHttpResponse_InterestFilterResponse_ } from '../models/DefaultHttpResponse_InterestFilterResponse_';
import type { DefaultHttpResponse_ListDislikeResponse_ } from '../models/DefaultHttpResponse_ListDislikeResponse_';
import type { DefaultHttpResponse_ListLiveWelloCommentResponse_ } from '../models/DefaultHttpResponse_ListLiveWelloCommentResponse_';
import type { DefaultHttpResponse_ListMyWelloCommentResponse_ } from '../models/DefaultHttpResponse_ListMyWelloCommentResponse_';
import type { DefaultHttpResponse_ListWelloAppPushResponse_ } from '../models/DefaultHttpResponse_ListWelloAppPushResponse_';
import type { DefaultHttpResponse_ListWelloCommentResponse_ } from '../models/DefaultHttpResponse_ListWelloCommentResponse_';
import type { DefaultHttpResponse_ListWelloMemberFamilyResponse_ } from '../models/DefaultHttpResponse_ListWelloMemberFamilyResponse_';
import type { DefaultHttpResponse_MemberContentCountResponse_ } from '../models/DefaultHttpResponse_MemberContentCountResponse_';
import type { DefaultHttpResponse_MemberFilterResponse_ } from '../models/DefaultHttpResponse_MemberFilterResponse_';
import type { DefaultHttpResponse_MemberOAuthResponse_ } from '../models/DefaultHttpResponse_MemberOAuthResponse_';
import type { DefaultHttpResponse_MetaPolicyWishlistResponse_ } from '../models/DefaultHttpResponse_MetaPolicyWishlistResponse_';
import type { DefaultHttpResponse_ProvisionTypeRankResponse_ } from '../models/DefaultHttpResponse_ProvisionTypeRankResponse_';
import type { DefaultHttpResponse_RecommendPolicySearchResponse_ } from '../models/DefaultHttpResponse_RecommendPolicySearchResponse_';
import type { DefaultHttpResponse_SituationListResponse_ } from '../models/DefaultHttpResponse_SituationListResponse_';
import type { DefaultHttpResponse_SituationPolicyListResponse_ } from '../models/DefaultHttpResponse_SituationPolicyListResponse_';
import type { DefaultHttpResponse_Void_ } from '../models/DefaultHttpResponse_Void_';
import type { DefaultHttpResponse_WelloPickListResponse_ } from '../models/DefaultHttpResponse_WelloPickListResponse_';
import type { DefaultHttpResponse_WelloPickPolicyListResponse_ } from '../models/DefaultHttpResponse_WelloPickPolicyListResponse_';
import type { DeleteMemberInfoRequest } from '../models/DeleteMemberInfoRequest';
import type { DeleteWelloMemberFamilyRequest } from '../models/DeleteWelloMemberFamilyRequest';
import type { FitSaveMemberFilterRequest } from '../models/FitSaveMemberFilterRequest';
import type { InterestSaveMemberFilterRequest } from '../models/InterestSaveMemberFilterRequest';
import type { MemberOAuthWithdrawRequest } from '../models/MemberOAuthWithdrawRequest';
import type { MessageReceiveUpdateRequest } from '../models/MessageReceiveUpdateRequest';
import type { SaveAndDeleteWelloLikeRequest } from '../models/SaveAndDeleteWelloLikeRequest';
import type { SaveMemberInfoRequestV3 } from '../models/SaveMemberInfoRequestV3';
import type { SaveWelloCommentReportRequest } from '../models/SaveWelloCommentReportRequest';
import type { SaveWelloCommentRequest } from '../models/SaveWelloCommentRequest';
import type { SaveWelloDeviceInfoRequest } from '../models/SaveWelloDeviceInfoRequest';
import type { UpdateMemberInfoRequestV3 } from '../models/UpdateMemberInfoRequestV3';
import type { UpdateMemberVerificationRequest } from '../models/UpdateMemberVerificationRequest';
import type { UpdateWelloAppPushReadRequest } from '../models/UpdateWelloAppPushReadRequest';
import type { UpdateWelloCommentRequest } from '../models/UpdateWelloCommentRequest';
import type { UpdateWelloMemberFamilyInfoRequest } from '../models/UpdateWelloMemberFamilyInfoRequest';
import type { WishlistRequest } from '../models/WishlistRequest';
import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';
export class ApiService {
    /**
     * 제외정책 목록조회
     * 제외정책 목록을 조회합니다
     * @returns DefaultHttpResponse_ListDislikeResponse_ OK
     * @throws ApiError
     */
    public static dislikeUsingGet1({
        codeProvisionType,
    }: {
        /**
         * 지원유형[C09-XX]
         */
        codeProvisionType?: string,
    }): CancelablePromise<DefaultHttpResponse_ListDislikeResponse_> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/wello/v2/auth/policy/dislike',
            query: {
                'code_provision_type': codeProvisionType,
            },
            errors: {
                500: `SQL 오류 및 기타 미처리 예외 상황입니다.`,
                502: `외부 API 오류 입니다.`,
            },
        });
    }
    /**
     * 제외정책 등록
     * 제외정책을 등록합니다
     * @returns DefaultHttpResponse OK
     * @throws ApiError
     */
    public static saveWishlistUsingPost3({
        policyId,
    }: {
        /**
         * 정책 번호
         */
        policyId: number,
    }): CancelablePromise<DefaultHttpResponse> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/wello/v2/auth/policy/dislike/{policy_id}',
            path: {
                'policy_id': policyId,
            },
            errors: {
                500: `SQL 오류 및 기타 미처리 예외 상황입니다.`,
                502: `외부 API 오류 입니다.`,
            },
        });
    }
    /**
     * 제외정책-삭제
     * 제외정책을 삭제합니다
     * @returns DefaultHttpResponse OK
     * @throws ApiError
     */
    public static removeWishlistUsingDelete2({
        policyId,
    }: {
        /**
         * 정책 번호
         */
        policyId: number,
    }): CancelablePromise<DefaultHttpResponse> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/wello/v2/auth/policy/dislike/{policy_id}',
            path: {
                'policy_id': policyId,
            },
            errors: {
                500: `SQL 오류 및 기타 미처리 예외 상황입니다.`,
                502: `외부 API 오류 입니다.`,
            },
        });
    }
    /**
     * 기본 정보 저장
     * @returns DefaultHttpResponse_Void_ OK
     * @throws ApiError
     */
    public static saveBasicFilterUsingPost({
        requestBody,
    }: {
        requestBody?: BasicSaveMemberFilterRequest,
    }): CancelablePromise<DefaultHttpResponse_Void_> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/wello/v2/auth/member-filter/basic',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                500: `SQL 오류 및 기타 미처리 예외 상황입니다.`,
                502: `외부 API 오류 입니다.`,
            },
        });
    }
    /**
     * 맞춤 정보 저장
     * @returns DefaultHttpResponse_Void_ OK
     * @throws ApiError
     */
    public static saveFitFilterUsingPost({
        requestBody,
    }: {
        requestBody?: FitSaveMemberFilterRequest,
    }): CancelablePromise<DefaultHttpResponse_Void_> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/wello/v2/auth/member-filter/fit',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                500: `SQL 오류 및 기타 미처리 예외 상황입니다.`,
                502: `외부 API 오류 입니다.`,
            },
        });
    }
    /**
     * 관심 필터 조회
     * @returns DefaultHttpResponse_InterestFilterResponse_ OK
     * @throws ApiError
     */
    public static interestFilterUsingGet1({
        welloMemberFamilyId,
    }: {
        /**
         * wello_member_family_id
         */
        welloMemberFamilyId?: number,
    }): CancelablePromise<DefaultHttpResponse_InterestFilterResponse_> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/wello/v2/auth/member-filter/interest',
            query: {
                'wello_member_family_id': welloMemberFamilyId,
            },
            errors: {
                500: `SQL 오류 및 기타 미처리 예외 상황입니다.`,
                502: `외부 API 오류 입니다.`,
            },
        });
    }
    /**
     * 관심 필터 코드 저장
     * @returns DefaultHttpResponse_Void_ OK
     * @throws ApiError
     */
    public static saveInterestFilterUsingPost({
        requestBody,
    }: {
        requestBody?: InterestSaveMemberFilterRequest,
    }): CancelablePromise<DefaultHttpResponse_Void_> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/wello/v2/auth/member-filter/interest',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                500: `SQL 오류 및 기타 미처리 예외 상황입니다.`,
                502: `외부 API 오류 입니다.`,
            },
        });
    }
    /**
     * 필터 조회(관심필터 제외)
     * @returns DefaultHttpResponse_MemberFilterResponse_ OK
     * @throws ApiError
     */
    public static requiredFilterUsingGet1({
        welloMemberFamilyId,
    }: {
        /**
         * wello_member_family_id
         */
        welloMemberFamilyId?: number,
    }): CancelablePromise<DefaultHttpResponse_MemberFilterResponse_> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/wello/v2/auth/member-filter/required',
            query: {
                'wello_member_family_id': welloMemberFamilyId,
            },
            errors: {
                500: `SQL 오류 및 기타 미처리 예외 상황입니다.`,
                502: `외부 API 오류 입니다.`,
            },
        });
    }
    /**
     * 유저 프로필 이미지 업로드
     * 유저 프로필 이미지를 업로드 한다.
     * @returns DefaultHttpResponse_Void_ OK
     * @throws ApiError
     */
    public static fileUploadUsingPost1({
        formData,
    }: {
        formData?: {
            /**
             * profile_image
             */
            profile_image: Blob;
        },
    }): CancelablePromise<DefaultHttpResponse_Void_> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/wello/v2/auth/member',
            formData: formData,
            mediaType: 'multipart/form-data',
            errors: {
                500: `SQL 오류 및 기타 미처리 예외 상황입니다.`,
                502: `외부 API 오류 입니다.`,
            },
        });
    }
    /**
     * 회원 탈퇴
     * 회원 탈퇴요청을 처리합니다
     * @returns DefaultHttpResponse_Void_ OK
     * @throws ApiError
     */
    public static resignMemberUsingDelete1({
        requestBody,
    }: {
        requestBody?: MemberOAuthWithdrawRequest,
    }): CancelablePromise<DefaultHttpResponse_Void_> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/wello/v2/auth/member',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                500: `SQL 오류 및 기타 미처리 예외 상황입니다.`,
                502: `외부 API 오류 입니다.`,
            },
        });
    }
    /**
     * 메인 페이지 멤버 컨텐츠 별 건수
     * @returns DefaultHttpResponse_MemberContentCountResponse_ OK
     * @throws ApiError
     */
    public static memberContentCountUsingGet1(): CancelablePromise<DefaultHttpResponse_MemberContentCountResponse_> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/wello/v2/auth/member/content-count',
            errors: {
                500: `SQL 오류 및 기타 미처리 예외 상황입니다.`,
                502: `외부 API 오류 입니다.`,
            },
        });
    }
    /**
     * 알림수신여부 수정
     * 알림수신 여부를 수정합니다
     * @returns DefaultHttpResponse_Void_ OK
     * @throws ApiError
     */
    public static messageReceiveUsingPut1({
        requestBody,
    }: {
        requestBody?: MessageReceiveUpdateRequest,
    }): CancelablePromise<DefaultHttpResponse_Void_> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/wello/v2/auth/member/message-receive',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                500: `SQL 오류 및 기타 미처리 예외 상황입니다.`,
                502: `외부 API 오류 입니다.`,
            },
        });
    }
    /**
     * 지원유형별 맞춤정책 건수
     * 지원유형별 맞춤정책 건수를 조회합니다
     * @returns DefaultHttpResponse_ProvisionTypeRankResponse_ OK
     * @throws ApiError
     */
    public static memberPolicyListUsingGet1(): CancelablePromise<DefaultHttpResponse_ProvisionTypeRankResponse_> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/wello/v2/auth/member/policy/provision-type-count',
            errors: {
                500: `SQL 오류 및 기타 미처리 예외 상황입니다.`,
                502: `외부 API 오류 입니다.`,
            },
        });
    }
    /**
     * 유저 프로필 조회
     * 가입된 유저의 프로필을 조회합니다
     * @returns DefaultHttpResponse_MemberOAuthResponse_ OK
     * @throws ApiError
     */
    public static memberUsingGet1(): CancelablePromise<DefaultHttpResponse_MemberOAuthResponse_> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/wello/v2/auth/member/profile',
            errors: {
                500: `SQL 오류 및 기타 미처리 예외 상황입니다.`,
                502: `외부 API 오류 입니다.`,
            },
        });
    }
    /**
     * 유저 정보 수정
     * 가입된 유저 정보를 수정합니다
     * @returns DefaultHttpResponse_Void_ OK
     * @throws ApiError
     */
    public static updateMemberNickNameUsingPut1({
        requestBody,
    }: {
        requestBody?: UpdateMemberInfoRequestV3,
    }): CancelablePromise<DefaultHttpResponse_Void_> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/wello/v2/auth/member/profile',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                500: `SQL 오류 및 기타 미처리 예외 상황입니다.`,
                502: `외부 API 오류 입니다.`,
            },
        });
    }
    /**
     * 유저 가입정보 등록
     * @returns DefaultHttpResponse_Void_ OK
     * @throws ApiError
     */
    public static saveMemberNickNameUsingPost1({
        requestBody,
    }: {
        requestBody?: SaveMemberInfoRequestV3,
    }): CancelablePromise<DefaultHttpResponse_Void_> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/wello/v2/auth/member/profile',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                500: `SQL 오류 및 기타 미처리 예외 상황입니다.`,
                502: `외부 API 오류 입니다.`,
            },
        });
    }
    /**
     * 회원 신규 맞춤정책 조회
     * @returns DefaultHttpResponse_RecommendPolicySearchResponse_ OK
     * @throws ApiError
     */
    public static memberNewPolicyListUsingGet1({
        page,
        size,
        codeProvisionType,
        welloMemberFamilyId,
    }: {
        /**
         * 페이지
         */
        page: number,
        /**
         * 사이즈
         */
        size: number,
        /**
         * 지원유형(C09)
         */
        codeProvisionType?: string,
        /**
         * 가족 ID
         */
        welloMemberFamilyId?: number,
    }): CancelablePromise<DefaultHttpResponse_RecommendPolicySearchResponse_> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/wello/v2/auth/member/recommend/policy',
            query: {
                'code_provision_type': codeProvisionType,
                'page': page,
                'size': size,
                'wello_member_family_id': welloMemberFamilyId,
            },
            errors: {
                500: `SQL 오류 및 기타 미처리 예외 상황입니다.`,
                502: `외부 API 오류 입니다.`,
            },
        });
    }
    /**
     * 연동된 소셜계정 목록
     * @returns DefaultHttpResponse_DuplicateMemberInfoResponse_ OK
     * @throws ApiError
     */
    public static memberSocialAccountsUsingGet1(): CancelablePromise<DefaultHttpResponse_DuplicateMemberInfoResponse_> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/wello/v2/auth/member/social-accounts',
            errors: {
                500: `SQL 오류 및 기타 미처리 예외 상황입니다.`,
                502: `외부 API 오류 입니다.`,
            },
        });
    }
    /**
     * 연동된 소셜계정 해제
     * @returns DefaultHttpResponse_DuplicateMemberInfoResponse_ OK
     * @throws ApiError
     */
    public static deleteMemberSocialAccountsUsingDelete1({
        requestBody,
    }: {
        requestBody?: DeleteMemberInfoRequest,
    }): CancelablePromise<DefaultHttpResponse_DuplicateMemberInfoResponse_> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/wello/v2/auth/member/social-accounts',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                500: `SQL 오류 및 기타 미처리 예외 상황입니다.`,
                502: `외부 API 오류 입니다.`,
            },
        });
    }
    /**
     * 본인인증 ID로 유저정보 수정
     * @returns DefaultHttpResponse_Void_ OK
     * @throws ApiError
     */
    public static updateMemberVerificationUsingPut({
        requestBody,
    }: {
        requestBody?: UpdateMemberVerificationRequest,
    }): CancelablePromise<DefaultHttpResponse_Void_> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/wello/v2/auth/member/verification',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                500: `SQL 오류 및 기타 미처리 예외 상황입니다.`,
                502: `외부 API 오류 입니다.`,
            },
        });
    }
    /**
     * 정책 꾸러미 목록 조회
     * 정책 꾸러미 목록을 조회합니다
     * @returns DefaultHttpResponse_SituationListResponse_ OK
     * @throws ApiError
     */
    public static situationListUsingGet2(): CancelablePromise<DefaultHttpResponse_SituationListResponse_> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/wello/v2/auth/contents/situation',
            errors: {
                500: `SQL 오류 및 기타 미처리 예외 상황입니다.`,
                502: `외부 API 오류 입니다.`,
            },
        });
    }
    /**
     * 정책 꾸러미 정책 목록 조회
     * 정책 꾸러미 정책 목록을 조회합니다
     * @returns DefaultHttpResponse_SituationPolicyListResponse_ OK
     * @throws ApiError
     */
    public static getSituationPolicyListUsingGet2({
        situationId,
    }: {
        /**
         * 정책 꾸러미 번호
         */
        situationId: number,
    }): CancelablePromise<DefaultHttpResponse_SituationPolicyListResponse_> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/wello/v2/auth/contents/situation/{situation_id}',
            path: {
                'situation_id': situationId,
            },
            errors: {
                500: `SQL 오류 및 기타 미처리 예외 상황입니다.`,
                502: `외부 API 오류 입니다.`,
            },
        });
    }
    /**
     * 웰로 앱 푸시 목록 조회
     * @returns DefaultHttpResponse_ListWelloAppPushResponse_ OK
     * @throws ApiError
     */
    public static getWebWelloAppPushListUsingGet({
        fcmToken,
        page,
        size,
        welloAppPushCategoryId,
    }: {
        /**
         * 디바이스 토큰
         */
        fcmToken: string,
        /**
         * 페이지
         */
        page: number,
        /**
         * 사이즈(10~)
         */
        size: number,
        /**
         * 앱 푸시 카테고리 ID
         */
        welloAppPushCategoryId?: number,
    }): CancelablePromise<DefaultHttpResponse_ListWelloAppPushResponse_> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/wello/v2/auth/wello-app-push',
            query: {
                'fcm_token': fcmToken,
                'page': page,
                'size': size,
                'wello_app_push_category_id': welloAppPushCategoryId,
            },
            errors: {
                500: `SQL 오류 및 기타 미처리 예외 상황입니다.`,
                502: `외부 API 오류 입니다.`,
            },
        });
    }
    /**
     * 웰로 앱 푸시 읽음 처리
     * @returns DefaultHttpResponse_Void_ OK
     * @throws ApiError
     */
    public static updateWelloAppPushReadUsingPut2({
        requestBody,
    }: {
        requestBody?: UpdateWelloAppPushReadRequest,
    }): CancelablePromise<DefaultHttpResponse_Void_> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/wello/v2/auth/wello-app-push',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                500: `SQL 오류 및 기타 미처리 예외 상황입니다.`,
                502: `외부 API 오류 입니다.`,
            },
        });
    }
    /**
     * 신청한 바로신청 목록 조회
     * @returns DefaultHttpResponse_Void_ OK
     * @throws ApiError
     */
    public static getWelloApplyPolicyListUsingGet(): CancelablePromise<DefaultHttpResponse_Void_> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/wello/v2/auth/apply-policy',
            errors: {
                500: `SQL 오류 및 기타 미처리 예외 상황입니다.`,
                502: `외부 API 오류 입니다.`,
            },
        });
    }
    /**
     * 웰로 댓글 목록 조회
     * @returns DefaultHttpResponse_ListWelloCommentResponse_ OK
     * @throws ApiError
     */
    public static getWelloCommentListUsingGet1({
        commentTypeCd,
        contentId,
        page,
        size,
        commentSort,
    }: {
        /**
         * 댓글 타입 cd - (Parent Code) WELLO_COMMENT_TYPE
         */
        commentTypeCd: string,
        /**
         * 컨텐츠 ID - policy_id, best_contents_id, hometown_news_id
         */
        contentId: number,
        /**
         * 페이지
         */
        page: number,
        /**
         * 사이즈
         */
        size: number,
        /**
         * 정렬순서 - POPULARITY, ENROLL, LATEST
         */
        commentSort?: 'ENROLL' | 'LATEST' | 'POPULARITY',
    }): CancelablePromise<DefaultHttpResponse_ListWelloCommentResponse_> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/wello/v2/auth/wello-comment',
            query: {
                'comment_sort': commentSort,
                'comment_type_cd': commentTypeCd,
                'content_id': contentId,
                'page': page,
                'size': size,
            },
            errors: {
                500: `SQL 오류 및 기타 미처리 예외 상황입니다.`,
                502: `외부 API 오류 입니다.`,
            },
        });
    }
    /**
     * 웰로 댓글 등록
     * @returns DefaultHttpResponse_Void_ OK
     * @throws ApiError
     */
    public static saveWelloCommentUsingPost1({
        requestBody,
    }: {
        requestBody?: SaveWelloCommentRequest,
    }): CancelablePromise<DefaultHttpResponse_Void_> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/wello/v2/auth/wello-comment',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                500: `SQL 오류 및 기타 미처리 예외 상황입니다.`,
                502: `외부 API 오류 입니다.`,
            },
        });
    }
    /**
     * 웰로 실시간 정책 의견
     * @returns DefaultHttpResponse_ListLiveWelloCommentResponse_ OK
     * @throws ApiError
     */
    public static getLiveWelloCommentListUsingGet2({
        commentTypeCd,
        size,
    }: {
        /**
         * 댓글 타입 cd - (Parent Code) WELLO_COMMENT_TYPE
         */
        commentTypeCd?: string,
        /**
         * 사이즈 (default 3)
         */
        size?: number,
    }): CancelablePromise<DefaultHttpResponse_ListLiveWelloCommentResponse_> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/wello/v2/auth/wello-comment/live',
            query: {
                'comment_type_cd': commentTypeCd,
                'size': size,
            },
            errors: {
                500: `SQL 오류 및 기타 미처리 예외 상황입니다.`,
                502: `외부 API 오류 입니다.`,
            },
        });
    }
    /**
     * 웰로 유저가 작성한 댓글 목록
     * @returns DefaultHttpResponse_ListMyWelloCommentResponse_ OK
     * @throws ApiError
     */
    public static getMyWelloCommentListUsingGet1({
        page,
        size,
        commentSort,
    }: {
        /**
         * 페이지
         */
        page: number,
        /**
         * 사이즈
         */
        size: number,
        /**
         * 정렬순서 - POPULARITY, ENROLL, LATEST
         */
        commentSort?: 'ENROLL' | 'LATEST' | 'POPULARITY',
    }): CancelablePromise<DefaultHttpResponse_ListMyWelloCommentResponse_> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/wello/v2/auth/wello-comment/my',
            query: {
                'comment_sort': commentSort,
                'page': page,
                'size': size,
            },
            errors: {
                500: `SQL 오류 및 기타 미처리 예외 상황입니다.`,
                502: `외부 API 오류 입니다.`,
            },
        });
    }
    /**
     * 웰로 댓글 수정
     * @returns DefaultHttpResponse_Void_ OK
     * @throws ApiError
     */
    public static updateWelloCommentUsingPut1({
        welloCommentId,
        requestBody,
    }: {
        /**
         * 댓글 ID
         */
        welloCommentId: number,
        requestBody?: UpdateWelloCommentRequest,
    }): CancelablePromise<DefaultHttpResponse_Void_> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/wello/v2/auth/wello-comment/{wello_comment_id}',
            path: {
                'wello_comment_id': welloCommentId,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                500: `SQL 오류 및 기타 미처리 예외 상황입니다.`,
                502: `외부 API 오류 입니다.`,
            },
        });
    }
    /**
     * 웰로 댓글 삭제
     * @returns DefaultHttpResponse_Void_ OK
     * @throws ApiError
     */
    public static deleteWelloCommentUsingPut1({
        welloCommentId,
    }: {
        /**
         * 댓글 ID
         */
        welloCommentId: number,
    }): CancelablePromise<DefaultHttpResponse_Void_> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/wello/v2/auth/wello-comment/{wello_comment_id}/delete',
            path: {
                'wello_comment_id': welloCommentId,
            },
            errors: {
                500: `SQL 오류 및 기타 미처리 예외 상황입니다.`,
                502: `외부 API 오류 입니다.`,
            },
        });
    }
    /**
     * 댓글 신고 등록
     * @returns DefaultHttpResponse_Void_ OK
     * @throws ApiError
     */
    public static saveWelloCommentReportUsingPost1({
        requestBody,
    }: {
        requestBody?: SaveWelloCommentReportRequest,
    }): CancelablePromise<DefaultHttpResponse_Void_> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/wello/v2/auth/wello-comment-report',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                500: `SQL 오류 및 기타 미처리 예외 상황입니다.`,
                502: `외부 API 오류 입니다.`,
            },
        });
    }
    /**
     * 가입 유저용 웰로 디바이스 정보 저장
     * @returns DefaultHttpResponse_Void_ OK
     * @throws ApiError
     */
    public static saveWelloDeviceInfoUsingPost1({
        requestBody,
    }: {
        requestBody?: SaveWelloDeviceInfoRequest,
    }): CancelablePromise<DefaultHttpResponse_Void_> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/wello/v2/auth/wello-device-info',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                500: `SQL 오류 및 기타 미처리 예외 상황입니다.`,
                502: `외부 API 오류 입니다.`,
            },
        });
    }
    /**
     * 웰로 좋아요 등록
     * @returns DefaultHttpResponse_Void_ OK
     * @throws ApiError
     */
    public static saveWelloLikeUsingPost1({
        requestBody,
    }: {
        requestBody?: SaveAndDeleteWelloLikeRequest,
    }): CancelablePromise<DefaultHttpResponse_Void_> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/wello/v2/auth/wello-like',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                500: `SQL 오류 및 기타 미처리 예외 상황입니다.`,
                502: `외부 API 오류 입니다.`,
            },
        });
    }
    /**
     * 웰로 좋아요 삭제
     * @returns DefaultHttpResponse_Void_ OK
     * @throws ApiError
     */
    public static deleteWelloLikeUsingDelete1({
        requestBody,
    }: {
        requestBody?: SaveAndDeleteWelloLikeRequest,
    }): CancelablePromise<DefaultHttpResponse_Void_> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/wello/v2/auth/wello-like',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                500: `SQL 오류 및 기타 미처리 예외 상황입니다.`,
                502: `외부 API 오류 입니다.`,
            },
        });
    }
    /**
     * 웰로멤버 가족 프로필 삭제
     * 웰로멤버 삭제요청을 처리합니다
     * @returns DefaultHttpResponse_Void_ OK
     * @throws ApiError
     */
    public static deleteWelloMemberFamilyUsingDelete1({
        requestBody,
    }: {
        requestBody?: DeleteWelloMemberFamilyRequest,
    }): CancelablePromise<DefaultHttpResponse_Void_> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/wello/v2/auth/family',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                500: `SQL 오류 및 기타 미처리 예외 상황입니다.`,
                502: `외부 API 오류 입니다.`,
            },
        });
    }
    /**
     * 웰로멤버 가족 목록 조회
     * 웰로멤버 가족 목록을 조회합니다
     * @returns DefaultHttpResponse_ListWelloMemberFamilyResponse_ OK
     * @throws ApiError
     */
    public static getWelloMemberFamilyUsingGet(): CancelablePromise<DefaultHttpResponse_ListWelloMemberFamilyResponse_> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/wello/v2/auth/family/profile',
            errors: {
                500: `SQL 오류 및 기타 미처리 예외 상황입니다.`,
                502: `외부 API 오류 입니다.`,
            },
        });
    }
    /**
     * 웰로멤버 가족 프로필 수정
     * 웰로멤버 가족 프로필 정보를 수정합니다
     * @returns DefaultHttpResponse_Void_ OK
     * @throws ApiError
     */
    public static updateWelloMemberFamilyUsingPut1({
        requestBody,
    }: {
        requestBody?: UpdateWelloMemberFamilyInfoRequest,
    }): CancelablePromise<DefaultHttpResponse_Void_> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/wello/v2/auth/family/profile',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                500: `SQL 오류 및 기타 미처리 예외 상황입니다.`,
                502: `외부 API 오류 입니다.`,
            },
        });
    }
    /**
     * 웰로멤버 가족 프로필 추가
     * 웰로멤버 가족 프로필을 추가합니다
     * @returns DefaultHttpResponse_CreateWelloMemberFamilyResponse_ OK
     * @throws ApiError
     */
    public static saveWelloMemberFamilyUsingPost1({
        requestBody,
    }: {
        requestBody?: CreateMemberFamilyInfoRequest,
    }): CancelablePromise<DefaultHttpResponse_CreateWelloMemberFamilyResponse_> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/wello/v2/auth/family/profile',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                500: `SQL 오류 및 기타 미처리 예외 상황입니다.`,
                502: `외부 API 오류 입니다.`,
            },
        });
    }
    /**
     * 웰로 대상별 정책 - 대상 조회
     * 웰로 대상별 정책 - 대상 정보를 조회합니다
     * @returns DefaultHttpResponse_WelloPickListResponse_ OK
     * @throws ApiError
     */
    public static welloPickListUsingGet2(): CancelablePromise<DefaultHttpResponse_WelloPickListResponse_> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/wello/v2/auth/contents/pick',
            errors: {
                500: `SQL 오류 및 기타 미처리 예외 상황입니다.`,
                502: `외부 API 오류 입니다.`,
            },
        });
    }
    /**
     * 웰로 대상별 정책 조회
     * 웰로 대상별 정책 정보를 조회합니다
     * @returns DefaultHttpResponse_WelloPickPolicyListResponse_ OK
     * @throws ApiError
     */
    public static welloPickPolicyListUsingGet2({
        welloPickId,
    }: {
        /**
         * 웰로 대상별 정책 - 대상 번호
         */
        welloPickId: number,
    }): CancelablePromise<DefaultHttpResponse_WelloPickPolicyListResponse_> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/wello/v2/auth/contents/pick/{wello_pick_id}',
            path: {
                'wello_pick_id': welloPickId,
            },
            errors: {
                500: `SQL 오류 및 기타 미처리 예외 상황입니다.`,
                502: `외부 API 오류 입니다.`,
            },
        });
    }
    /**
     * 웰로 스크랩 등록
     * @returns DefaultHttpResponse_Void_ OK
     * @throws ApiError
     */
    public static saveWishlistUsingPost4({
        requestBody,
    }: {
        requestBody?: WishlistRequest,
    }): CancelablePromise<DefaultHttpResponse_Void_> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/wello/v2/auth/member/wishlist',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                500: `SQL 오류 및 기타 미처리 예외 상황입니다.`,
                502: `외부 API 오류 입니다.`,
            },
        });
    }
    /**
     * 웰로 스크랩 삭제
     * @returns DefaultHttpResponse_Void_ OK
     * @throws ApiError
     */
    public static deleteWishlistUsingDelete1({
        requestBody,
    }: {
        requestBody?: WishlistRequest,
    }): CancelablePromise<DefaultHttpResponse_Void_> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/wello/v2/auth/member/wishlist',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                500: `SQL 오류 및 기타 미처리 예외 상황입니다.`,
                502: `외부 API 오류 입니다.`,
            },
        });
    }
    /**
     * 정책소식 스크랩 목록 조회
     * 정책소식 스크랩 목록을 조회합니다
     * @returns DefaultHttpResponse_BestContentsWishlistResponse_ OK
     * @throws ApiError
     */
    public static bestContentsWishlistUsingGet1(): CancelablePromise<DefaultHttpResponse_BestContentsWishlistResponse_> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/wello/v2/auth/member/wishlist/best-contents',
            errors: {
                500: `SQL 오류 및 기타 미처리 예외 상황입니다.`,
                502: `외부 API 오류 입니다.`,
            },
        });
    }
    /**
     * 동네소식 스크랩 목록 조회
     * 동네소식 스크랩 목록을 조회합니다
     * @returns DefaultHttpResponse_HometownNewsWishlistResponse_ OK
     * @throws ApiError
     */
    public static hometownNewsWishlistUsingGet1(): CancelablePromise<DefaultHttpResponse_HometownNewsWishlistResponse_> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/wello/v2/auth/member/wishlist/hometown-news',
            errors: {
                500: `SQL 오류 및 기타 미처리 예외 상황입니다.`,
                502: `외부 API 오류 입니다.`,
            },
        });
    }
    /**
     * 정책 스크랩 목록 조회
     * 정책 스크랩 목록을 조회합니다
     * @returns DefaultHttpResponse_MetaPolicyWishlistResponse_ OK
     * @throws ApiError
     */
    public static metaPolicyWishlistUsingGet1(): CancelablePromise<DefaultHttpResponse_MetaPolicyWishlistResponse_> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/wello/v2/auth/member/wishlist/policy',
            errors: {
                500: `SQL 오류 및 기타 미처리 예외 상황입니다.`,
                502: `외부 API 오류 입니다.`,
            },
        });
    }
}
