import type { HTMLTagProps } from '@/types';

import { forwardRef } from 'react';

const IMAGE_PLACEHOLDER = '/assets/pngs/placeholder.png';

export const Image = forwardRef<
  HTMLImageElement,
  Omit<HTMLTagProps<'img'>, 'ref'>
>(({ src = IMAGE_PLACEHOLDER, onError, ...restProps }, ref) => (
  <img
    {...restProps}
    ref={ref}
    alt={restProps.alt}
    src={src}
    onError={(e) => {
      e.currentTarget.src = IMAGE_PLACEHOLDER;
      onError?.(e);
    }}
  />
));

Image.displayName = 'Image';

export default Image;
