//TODO: 추후 환경변수는 모두 해당 파일에서 관리

export const PRODUCT_DOMAIN = 'https://welfarehello.com';

/**
 * PUBLIC 환경 변수
 */
const ENV = {
  NEXT_PUBLIC_DOMAIN: process.env.NEXT_PUBLIC_DOMAIN as string,

  NEXT_PUBLIC_API_DOMAIN: process.env.NEXT_PUBLIC_API_DOMAIN as string,
  NEXT_PUBLIC_COMMON_API_DOMAIN: process.env
    .NEXT_PUBLIC_COMMON_API_DOMAIN as string,
  NEXT_PUBLIC_GOOGLE_CLIENT_ID: process.env
    .NEXT_PUBLIC_GOOGLE_CLIENT_ID as string,
  NEXT_PUBLIC_KAKAO_CLIENT_ID: process.env
    .NEXT_PUBLIC_KAKAO_CLIENT_ID as string,

  NEXT_PUBLIC_NAVER_CLIENT_ID: process.env
    .NEXT_PUBLIC_NAVER_CLIENT_ID as string,
  NEXT_PUBLIC_APPLE_CLIENT_ID: process.env
    .NEXT_PUBLIC_APPLE_CLIENT_ID as string,

  NEXT_PUBLIC_JEJU_API_DOMAIN: process.env
    .NEXT_PUBLIC_JEJU_API_DOMAIN as string,

  NEXT_PUBLIC_JEJU_YOUTHDREAM_API_KEY: process.env
    .NEXT_PUBLIC_JEJU_YOUTHDREAM_API_KEY as string,
};

export const IS_PRODUCT = ENV.NEXT_PUBLIC_DOMAIN === PRODUCT_DOMAIN;

export { ENV };
