/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { DefaultHttpResponse_DetailBestContentsResponse_ } from '../models/DefaultHttpResponse_DetailBestContentsResponse_';
import type { DefaultHttpResponse_DetailHometownNewsResponse_ } from '../models/DefaultHttpResponse_DetailHometownNewsResponse_';
import type { DefaultHttpResponse_DetailInquiryResponse_ } from '../models/DefaultHttpResponse_DetailInquiryResponse_';
import type { DefaultHttpResponse_DetailMemberVerificationCiResponse_ } from '../models/DefaultHttpResponse_DetailMemberVerificationCiResponse_';
import type { DefaultHttpResponse_DetailMetaPolicyResponse_ } from '../models/DefaultHttpResponse_DetailMetaPolicyResponse_';
import type { DefaultHttpResponse_ListBestContentsResponse_ } from '../models/DefaultHttpResponse_ListBestContentsResponse_';
import type { DefaultHttpResponse_ListHometownNewsResponse_ } from '../models/DefaultHttpResponse_ListHometownNewsResponse_';
import type { DefaultHttpResponse_ListInquiryResponse_ } from '../models/DefaultHttpResponse_ListInquiryResponse_';
import type { DefaultHttpResponse_ListMetaPolicyResponse_ } from '../models/DefaultHttpResponse_ListMetaPolicyResponse_';
import type { DefaultHttpResponse_ListWelloPolicyApplyResponse_ } from '../models/DefaultHttpResponse_ListWelloPolicyApplyResponse_';
import type { DefaultHttpResponse_Void_ } from '../models/DefaultHttpResponse_Void_';
import type { DefaultHttpResponse_WelloFeedbackResponse_ } from '../models/DefaultHttpResponse_WelloFeedbackResponse_';
import type { InquiryRequest } from '../models/InquiryRequest';
import type { SaveWelloEventParticipationRequest } from '../models/SaveWelloEventParticipationRequest';
import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';
export class Service {
    /**
     * 정책 소식 목록 조회
     * 정책 소식 목록을 조회합니다
     * @returns DefaultHttpResponse_ListBestContentsResponse_ OK
     * @throws ApiError
     */
    public static bestContentsListUsingGet2({
        page,
        size,
        bestContentsSort,
        codeProvisionType,
        endAt,
        searchKeyword,
        startAt,
    }: {
        /**
         * 페이지
         */
        page: number,
        /**
         * 사이즈
         */
        size: number,
        /**
         * 정렬 순서(POPULARITY, LATEST)
         */
        bestContentsSort?: 'LATEST' | 'POPULARITY',
        /**
         * 지원유형[C09-XX]
         */
        codeProvisionType?: string,
        /**
         * 정책 종료 일시
         */
        endAt?: string,
        /**
         * 검색어
         */
        searchKeyword?: string,
        /**
         * 정책 시작 일시
         */
        startAt?: string,
    }): CancelablePromise<DefaultHttpResponse_ListBestContentsResponse_> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/wello/v2/auth/contents/best',
            query: {
                'best_contents_sort': bestContentsSort,
                'code_provision_type': codeProvisionType,
                'end_at': endAt,
                'page': page,
                'search_keyword': searchKeyword,
                'size': size,
                'start_at': startAt,
            },
            errors: {
                500: `SQL 오류 및 기타 미처리 예외 상황입니다.`,
                502: `외부 API 오류 입니다.`,
            },
        });
    }
    /**
     * 정책 소식 상세 조회
     * 정책 소식 상세 정보를 조회합니다
     * @returns DefaultHttpResponse_DetailBestContentsResponse_ OK
     * @throws ApiError
     */
    public static bestContentsUsingGet2({
        bestContentsIdIdx,
    }: {
        /**
         * 정책 소식 id idx
         */
        bestContentsIdIdx: string,
    }): CancelablePromise<DefaultHttpResponse_DetailBestContentsResponse_> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/wello/v2/auth/contents/best/{best_contents_id_idx}',
            path: {
                'best_contents_id_idx': bestContentsIdIdx,
            },
            errors: {
                500: `SQL 오류 및 기타 미처리 예외 상황입니다.`,
                502: `외부 API 오류 입니다.`,
            },
        });
    }
    /**
     * 로그인 멤버 정책 소식 좋아요&스크랩 정보 조회
     * @returns DefaultHttpResponse_WelloFeedbackResponse_ OK
     * @throws ApiError
     */
    public static bestContentsFeedbackUsingGet({
        bestContentsId,
    }: {
        /**
         * 정책 소식 ID
         */
        bestContentsId: number,
    }): CancelablePromise<DefaultHttpResponse_WelloFeedbackResponse_> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/wello/v2/auth/contents/feedback/{best_contents_id}',
            path: {
                'best_contents_id': bestContentsId,
            },
            errors: {
                500: `SQL 오류 및 기타 미처리 예외 상황입니다.`,
                502: `외부 API 오류 입니다.`,
            },
        });
    }
    /**
     * 동네 소식 콘텐츠 목록
     * @returns DefaultHttpResponse_ListHometownNewsResponse_ OK
     * @throws ApiError
     */
    public static hometownNewsListUsingGet1({
        page,
        size,
        codeRegion,
        codeSubRegion,
        hometownNewsSort,
        searchKeyword,
    }: {
        /**
         * 페이지
         */
        page: number,
        /**
         * 사이즈
         */
        size: number,
        /**
         * 코드_지역(시도)[C01-XX]
         */
        codeRegion?: string,
        /**
         * 코드_지역(시군구)[C01-XX-YY]
         */
        codeSubRegion?: string,
        /**
         * 정렬순서(LATEST,POPULARITY)
         */
        hometownNewsSort?: 'LATEST' | 'POPULARITY',
        /**
         * 검색어
         */
        searchKeyword?: string,
    }): CancelablePromise<DefaultHttpResponse_ListHometownNewsResponse_> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/wello/v2/auth/hometown-news',
            query: {
                'code_region': codeRegion,
                'code_sub_region': codeSubRegion,
                'hometown_news_sort': hometownNewsSort,
                'page': page,
                'search_keyword': searchKeyword,
                'size': size,
            },
            errors: {
                500: `SQL 오류 및 기타 미처리 예외 상황입니다.`,
                502: `외부 API 오류 입니다.`,
            },
        });
    }
    /**
     * 로그인 멤버 동네 소식 좋아요&스크랩 정보 조회
     * @returns DefaultHttpResponse_WelloFeedbackResponse_ OK
     * @throws ApiError
     */
    public static hometownNewsFeedbackUsingGet({
        hometownNewsId,
    }: {
        /**
         * 동네 소식 ID
         */
        hometownNewsId: number,
    }): CancelablePromise<DefaultHttpResponse_WelloFeedbackResponse_> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/wello/v2/auth/hometown-news/feedback/{hometown_news_id}',
            path: {
                'hometown_news_id': hometownNewsId,
            },
            errors: {
                500: `SQL 오류 및 기타 미처리 예외 상황입니다.`,
                502: `외부 API 오류 입니다.`,
            },
        });
    }
    /**
     * 동네 소식 콘텐츠 상세
     * @returns DefaultHttpResponse_DetailHometownNewsResponse_ OK
     * @throws ApiError
     */
    public static hometownNewsUsingGet2({
        hometownNewsIdIdx,
    }: {
        /**
         * 동네 소식 Id Idx
         */
        hometownNewsIdIdx: string,
    }): CancelablePromise<DefaultHttpResponse_DetailHometownNewsResponse_> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/wello/v2/auth/hometown-news/{hometown_news_id_idx}',
            path: {
                'hometown_news_id_idx': hometownNewsIdIdx,
            },
            errors: {
                500: `SQL 오류 및 기타 미처리 예외 상황입니다.`,
                502: `외부 API 오류 입니다.`,
            },
        });
    }
    /**
     * 문의내역 조회
     * 문의 내역을 조회합니다
     * @returns DefaultHttpResponse_ListInquiryResponse_ OK
     * @throws ApiError
     */
    public static inquiryListUsingGet1({
        page,
        size,
    }: {
        page: number,
        size: number,
    }): CancelablePromise<DefaultHttpResponse_ListInquiryResponse_> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/wello/v2/auth/cs/inquiry',
            query: {
                'page': page,
                'size': size,
            },
            errors: {
                500: `SQL 오류 및 기타 미처리 예외 상황입니다.`,
                502: `외부 API 오류 입니다.`,
            },
        });
    }
    /**
     * 문의하기
     * 문의내용을 등록합니다
     * @returns DefaultHttpResponse_Void_ OK
     * @throws ApiError
     */
    public static saveInquiryUsingPost1({
        requestBody,
    }: {
        requestBody?: InquiryRequest,
    }): CancelablePromise<DefaultHttpResponse_Void_> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/wello/v2/auth/cs/inquiry',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                500: `SQL 오류 및 기타 미처리 예외 상황입니다.`,
                502: `외부 API 오류 입니다.`,
            },
        });
    }
    /**
     * 문의상세 조회
     * 문의 상세 내역을 조회합니다
     * @returns DefaultHttpResponse_DetailInquiryResponse_ OK
     * @throws ApiError
     */
    public static getInquiryUsingGet({
        inquiryId,
    }: {
        /**
         * 문의 번호
         */
        inquiryId: number,
    }): CancelablePromise<DefaultHttpResponse_DetailInquiryResponse_> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/wello/v2/auth/cs/inquiry/{inquiry_id}',
            path: {
                'inquiry_id': inquiryId,
            },
            errors: {
                500: `SQL 오류 및 기타 미처리 예외 상황입니다.`,
                502: `외부 API 오류 입니다.`,
            },
        });
    }
    /**
     * 최근 인증된 멤버 CI 조회
     * @returns DefaultHttpResponse_DetailMemberVerificationCiResponse_ OK
     * @throws ApiError
     */
    public static getLatestCompletedCiUsingPost(): CancelablePromise<DefaultHttpResponse_DetailMemberVerificationCiResponse_> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/wello/v2/auth/member-verification',
            errors: {
                500: `SQL 오류 및 기타 미처리 예외 상황입니다.`,
                502: `외부 API 오류 입니다.`,
            },
        });
    }
    /**
     * 정책 검색목록 조회
     * @returns DefaultHttpResponse_ListMetaPolicyResponse_ OK
     * @throws ApiError
     */
    public static metaPolicyListUsingGet2({
        page,
        size,
        codeProvisionTypeList,
        codeRegion,
        codeSubRegion,
        descAgeList,
        policySort,
        searchKeyword,
    }: {
        /**
         * 페이지
         */
        page: number,
        /**
         * 페이지 사이즈
         */
        size: number,
        /**
         * 지원유형[C09-XX] 목록
         */
        codeProvisionTypeList?: Array<string>,
        /**
         * 메타코드-지역(시도)[C01-XX]
         */
        codeRegion?: string,
        /**
         * 메타코드-지역(시군구)[C01-XX-YY]
         */
        codeSubRegion?: string,
        /**
         * 지원대상 목록
         */
        descAgeList?: Array<string>,
        /**
         * 정렬순서(LATEST,POPULARITY)
         */
        policySort?: 'LATEST' | 'POPULARITY',
        /**
         * 검색어
         */
        searchKeyword?: string,
    }): CancelablePromise<DefaultHttpResponse_ListMetaPolicyResponse_> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/wello/v2/auth/policy',
            query: {
                'codeProvisionTypeList': codeProvisionTypeList,
                'codeRegion': codeRegion,
                'codeSubRegion': codeSubRegion,
                'descAgeList': descAgeList,
                'page': page,
                'policySort': policySort,
                'searchKeyword': searchKeyword,
                'size': size,
            },
            errors: {
                500: `SQL 오류 및 기타 미처리 예외 상황입니다.`,
                502: `외부 API 오류 입니다.`,
            },
        });
    }
    /**
     * 로그인 멤버 정책 좋아요&스크랩 정보 조회
     * @returns DefaultHttpResponse_WelloFeedbackResponse_ OK
     * @throws ApiError
     */
    public static metaPolicyFeedbackUsingGet({
        policyId,
    }: {
        /**
         * 정책 ID
         */
        policyId: number,
    }): CancelablePromise<DefaultHttpResponse_WelloFeedbackResponse_> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/wello/v2/auth/policy/feedback/{policy_id}',
            path: {
                'policy_id': policyId,
            },
            errors: {
                500: `SQL 오류 및 기타 미처리 예외 상황입니다.`,
                502: `외부 API 오류 입니다.`,
            },
        });
    }
    /**
     * 회원 정책 상세 조회
     * @returns DefaultHttpResponse_DetailMetaPolicyResponse_ OK
     * @throws ApiError
     */
    public static metaPolicyUsingGet2({
        metaPolicyIdIdx,
    }: {
        /**
         * 정책 idx
         */
        metaPolicyIdIdx: string,
    }): CancelablePromise<DefaultHttpResponse_DetailMetaPolicyResponse_> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/wello/v2/auth/policy/{meta_policy_id_idx}',
            path: {
                'meta_policy_id_idx': metaPolicyIdIdx,
            },
            errors: {
                500: `SQL 오류 및 기타 미처리 예외 상황입니다.`,
                502: `외부 API 오류 입니다.`,
            },
        });
    }
    /**
     * 웰로 이벤트 참여
     * @returns DefaultHttpResponse_Void_ OK
     * @throws ApiError
     */
    public static saveWelloParticipationUsingPost({
        requestBody,
    }: {
        requestBody?: SaveWelloEventParticipationRequest,
    }): CancelablePromise<DefaultHttpResponse_Void_> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/wello/v2/auth/wello-event-participation',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                500: `SQL 오류 및 기타 미처리 예외 상황입니다.`,
                502: `외부 API 오류 입니다.`,
            },
        });
    }
    /**
     * 정책 바로신청 내역 조회
     * @returns DefaultHttpResponse_ListWelloPolicyApplyResponse_ OK
     * @throws ApiError
     */
    public static getWelloPolicyApplyListUsingGet(): CancelablePromise<DefaultHttpResponse_ListWelloPolicyApplyResponse_> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/wello/v2/auth/policy-apply/history',
            errors: {
                500: `SQL 오류 및 기타 미처리 예외 상황입니다.`,
                502: `외부 API 오류 입니다.`,
            },
        });
    }
}
