import { useShallow } from 'zustand/react/shallow';

import {
  SUB_DOMAIN,
  WEBVIEW_TYPE,
  useDeviceInfoStore,
} from '@/stores/DeviceStore';

export const useCheckWelloApp = () =>
  useDeviceInfoStore(
    useShallow(({ subDomain, webviewType, isMounted }) => ({
      isChecking: !isMounted,
      isWelloApp:
        subDomain === SUB_DOMAIN.WELLO_APP &&
        webviewType === WEBVIEW_TYPE.WELLO_APP,
    })),
  );
