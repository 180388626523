import type { HTMLTagProps } from '@/types';

import { useRef, useState, useEffect } from 'react';

import { cleanClassName } from '@wello-client/common/utils';

import { Skeleton } from '@/components/server';

import styles from './LazyImage.module.scss';

interface LazyImageProps extends HTMLTagProps<'img'> {
  isLoading?: boolean;
  renderLoading?: () => React.ReactNode;
}

const IMAGE_PLACEHOLDER = '/assets/pngs/placeholder.png';

export const LazyImage = ({
  loading = 'lazy',
  className,
  onLoad,
  src = IMAGE_PLACEHOLDER,
  onError,
  isLoading,
  renderLoading: LoadingCompoent,
  ...restProps
}: LazyImageProps) => {
  const [isLoaded, setIsLoaded] = useState(false);

  const image = useRef<HTMLImageElement>(null);

  useEffect(() => {
    if (image.current?.complete) {
      setIsLoaded(true);
    }
  }, []);

  const isWait = !isLoaded || isLoading;

  const isShowRenderLoading = LoadingCompoent && isWait;

  return (
    <>
      {isShowRenderLoading ? <LoadingCompoent /> : null}
      <picture
        className={cleanClassName(
          `${styles.container} ${isShowRenderLoading ? styles['render-loading'] : className}`,
        )}
      >
        <img
          {...restProps}
          ref={image}
          alt={restProps.alt}
          className={cleanClassName(
            `${styles.img} ${isLoaded || styles.invisible}`,
          )}
          loading={loading}
          src={src ?? IMAGE_PLACEHOLDER}
          onError={(e) => {
            e.currentTarget.src = IMAGE_PLACEHOLDER;
            onError?.(e);
          }}
          onLoad={(e) => {
            onLoad?.(e);
            setIsLoaded(true);
          }}
        />
        {isWait ? (
          <Skeleton className={`${styles.img} ${styles.skeleton}`} />
        ) : null}
      </picture>
    </>
  );
};
