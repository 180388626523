import type { CustomRequest } from '../api';

import { hasKey } from './hasKey';
export interface ServerResponse {
  result_code?: number;
  return_code?: number;
  return_message?: string;
  context?: any;
  request?: CustomRequest;
  error?: {
    message?: string;
    name?: string;
    cause?: string;
    stack?: string;
  };
}

export enum ERROR_CODE {
  HIDDEN = 20019,
}

export const isNormalizedResponse = (res: any): res is ServerResponse =>
  hasKey(res, 'result_code') &&
  hasKey(res, 'return_code') &&
  hasKey(res, 'return_message');

export const createError = (params?: ServerResponse) => {
  const errorObject: ServerResponse = {
    ...params,
    result_code: params?.result_code ?? 500,
    return_code: params?.return_code ?? 40000,
    return_message:
      params?.return_message ?? '예상치 못한 오류가 발생했습니다.',
  };

  return errorObject;
};

//* 서버 요청에 대한 응답을 항상 통일된 형태로 받기 위한 함수
export const normalizeServerRequest = <
  TParams extends any[],
  TResponse extends ServerResponse,
>(
  requestFn: (...args: TParams) => Promise<TResponse>,
) => {
  if (!requestFn)
    throw createError({
      return_message: '변환된 요청함수가 없습니다.',
    });

  return async (...args: TParams) => {
    try {
      const res: TResponse = await requestFn(...args);

      const { return_code } = res;

      //* result_code가 0인 경우에만 정상적인 응답
      if (return_code === 0) return res;

      if (return_code) throw res;

      throw createError({
        return_message: '잘못된 형식의 응답을 받았습니다.',
        request: res.request,
      });
    } catch (error) {
      const _error = error as Error & {
        request?: CustomRequest;
      };

      if (hasKey(_error, 'return_code')) throw _error;

      throw createError({
        request: _error.request,
        error: {
          message: _error.message,
          name: _error.name,
          cause: JSON.stringify(_error.cause),
          stack: _error.stack,
        },
      });
    }
  };
};
