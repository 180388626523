import Script from 'next/script';

const isProduction =
  process.env.NEXT_PUBLIC_DOMAIN === 'https://welfarehello.com';

export const GTM_ID = isProduction ? 'GTM-M3MQ3WXD' : 'GTM-WHSL2B7L';

export const AppGlobalScript = () => {
  const GA_ID = isProduction ? 'G-GC218Y7WTL' : 'G-P8EJYEEY2X';

  return (
    <>
      <Script
        async
        src={`https://www.googletagmanager.com/gtag/js?id=${GA_ID}`}
      />
      <Script id="GA">
        {`window.dataLayer = window.dataLayer || [];
        function gtag(){dataLayer.push(arguments);}
        gtag('js', new Date());
        gtag('config', '${GA_ID}');`}
      </Script>
      <Script id="GTM">
        {`(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
})(window,document,'script','dataLayer','${GTM_ID}');`}
      </Script>
    </>
  );
};
