export type SocialType = 'kakao' | 'naver' | 'google' | 'apple';

const SOCIAL_OAUTH_URL = {
  kakao: 'https://kauth.kakao.com/oauth/authorize',
  naver: 'https://nid.naver.com/oauth2.0/authorize',
  google: 'https://accounts.google.com/o/oauth2/auth/oauthchooseaccount',
  apple: 'https://appleid.apple.com/auth/authorize',
} as const;

const encodeOAuthState = (type: SocialType, redirectPath: string) => {
  const obj = { type, redirectPath };
  const json = JSON.stringify(obj);
  const base64Encoded = btoa(unescape(encodeURIComponent(json)));

  return base64Encoded;
};

interface CreateOAuthUrlParams {
  /** NEXT_PUBLIC_DOMAIN */
  domain: string | undefined;
  type: SocialType;
  /** NEXT_PUBLIC_{TYPE}_ID */
  clientId: string | undefined;
  scope?: string;
  /** Apple은 응답을 POST로 보내서 API Route에서 받아야 함 */
  appleApiRoutePath?: string;
  /** OAuth 응답을 핸들링할 페이지 */
  redirectPath?: string;
}

/** 인증 받기위해 들렀다 오는 소셜 로그인 URL 만들어주는 함수 */
export const createOAuthUrl = ({
  domain,
  type,
  clientId,
  scope,
  appleApiRoutePath = '/api/auth/apple',
  redirectPath = '/auth',
}: CreateOAuthUrlParams) => {
  if (!domain || !clientId) throw new Error('domain, clientId 는 필수입니다.');

  const url = SOCIAL_OAUTH_URL[type];

  const searchParams = new URLSearchParams({
    response_type: 'code',
    client_id: clientId,
    state: encodeOAuthState(type, redirectPath),
  });

  if (scope) searchParams.append('scope', scope);

  switch (type) {
    case 'apple': {
      searchParams.append(
        'redirect_uri',
        `${domain.replace('https://', 'https://www.')}${appleApiRoutePath}`,
      );
      searchParams.append('response_mode', 'form_post');
      break;
    }

    default: {
      searchParams.append('redirect_uri', `${domain}${redirectPath}`);
      break;
    }
  }

  return `${url}?${searchParams.toString()}`;
};
