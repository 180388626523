export const isCurrentUrl = (href: string) => {
  if (typeof window === 'undefined') return false;

  const targetUrl = new URL(href, location.origin);

  return (
    targetUrl.pathname === location.pathname &&
    targetUrl.search === location.search
  );
};
