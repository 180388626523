/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { DecryptMemberVerificationRequest } from '../models/DecryptMemberVerificationRequest';
import type { DefaultHttpResponse_DecryptMemberVerificationResponse_ } from '../models/DefaultHttpResponse_DecryptMemberVerificationResponse_';
import type { DefaultHttpResponse_DetailMemberVerificationResponse_ } from '../models/DefaultHttpResponse_DetailMemberVerificationResponse_';
import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';
export class Service {
    /**
     * 본인인증 정보 복호화
     * @returns DefaultHttpResponse_DecryptMemberVerificationResponse_ OK
     * @throws ApiError
     */
    public static decryptMemberVerificationUsingPost({
        requestBody,
    }: {
        requestBody?: DecryptMemberVerificationRequest,
    }): CancelablePromise<DefaultHttpResponse_DecryptMemberVerificationResponse_> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/wello/v2/auth/member-verification',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                500: `SQL 오류 및 기타 미처리 예외 상황입니다.`,
                502: `외부 API 오류 입니다.`,
            },
        });
    }
    /**
     * 본인인증 ID 조회
     * @returns DefaultHttpResponse_DetailMemberVerificationResponse_ OK
     * @throws ApiError
     */
    public static getMemberVerificationIdUsingGet(): CancelablePromise<DefaultHttpResponse_DetailMemberVerificationResponse_> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/wello/v2/auth/member-verification/check',
            errors: {
                500: `SQL 오류 및 기타 미처리 예외 상황입니다.`,
                502: `외부 API 오류 입니다.`,
            },
        });
    }
}
