import type { HTMLTagProps } from '@/types';

import { cleanClassName } from '@wello-client/common/utils';

import { Button, Skeleton, type ButtonProps } from '@/components/server';

import styles from './TabItem.module.scss';
import { useTabContext } from './contexts/TabContext';

type TabItemTheme = ButtonProps['theme'] | 'sub-fill';

export interface TabItemProps
  extends Omit<HTMLTagProps<'li'>, 'children' | 'onClick'>,
    Pick<ButtonProps, 'disabled' | 'children' | 'onClick' | 'icon'> {
  active?: boolean;
  loading?: boolean;
  theme?: TabItemTheme;
}

export const TabItem = ({
  //* TabItem props
  theme = 'unset',
  active = false,
  loading = false,

  //* Button props
  children,
  disabled,
  onClick,
  icon,

  //* HTML li props
  className,
  ...restLiProps
}: TabItemProps) => {
  const buttonProps = useTabContext();

  return (
    <li
      {...restLiProps}
      className={cleanClassName(
        `${!loading && styles['tab-item']} ${theme !== 'unset' && styles[theme]}
       ${active && styles['tab-item-active']} ${
         buttonProps.shape === 'rectangle' && styles['shape-rectangle']
       }${className}`,
      )}
    >
      {loading ? (
        <Skeleton className={styles.skeleton} />
      ) : (
        <Button
          {...buttonProps}
          className={styles['item-content']}
          disabled={disabled}
          icon={icon}
          theme="unset"
          onClick={onClick}
        >
          {children}
        </Button>
      )}
    </li>
  );
};
