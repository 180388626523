export enum SEARCH_PARAMS {
  ORIGINAL_PATH = 'original-path',
  POST_REDIRECT = 'post-redirect',
  APP_LOGIN_TYPE = 'app-login',
  ACCESS_TOKEN = 'accessToken',
  REFRESH_TOKEN = 'refreshToken',
  REDIRECT = 'redirect',
  VERIFICATION = 'verification',
  ENTER_TYPE = 'enter-type',
  SHARE_BY = 'share-by',
  SOCIAL_LOGIN_TOKEN = 'social-login-token',
  LOGIN_TYPE = 'login-type',
}
