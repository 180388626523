import type { TERM_CATEGORY } from '@/api';

import type { Dispatch, SetStateAction } from 'react';

import { create } from 'zustand';

import { STORAGE_KEY } from '@/constants';

export enum LOCAL_STORAGE_KEY {
  RECENT_SEARCH_LIST = 'recent_search_list',
  SEARCH_LOCATION_LIST = 'search_location_list',
}

export type ToastType = 'fail' | 'success' | 'warning' | 'info';

export interface ToastOptions {
  toastKey: string;
  type?: ToastType;
  message: string;
  holdTime?: number;
  createdAt: Date;
}

interface WelloWebLinkModalPros {
  descriptions: string;
  link: string;
}

interface Log {
  data: string;
  time: Date;
}
interface GlobalStore {
  /** 해당 스토어가 Mount 되었는지 여부 */
  isMounted: boolean;
  setIsMounted: (isMounted: boolean) => void;

  currentHistoryKey: number;
  setCurrentHistoryKey: Dispatch<SetStateAction<number>>;

  historyMap: Map<number, string>;
  setHistoryMap: Dispatch<SetStateAction<Map<number, string>>>;

  /** replace 과정에서 손실되는 history state를 대신 저장해주기 위함 */
  tempHistoryKey?: number;
  setTempHistoryKey: (tempHistoryKey: number | undefined) => void;

  isGlobalLoading: boolean;
  setIsGlobalLoading: (isGlobalLoading: boolean) => void;

  termsDrawerType: TERM_CATEGORY | null;
  setTermsDrawerType: (termsDrawerType: TERM_CATEGORY | null) => void;

  welloWebLinkModalProps: WelloWebLinkModalPros | null;
  setWelloWebLinkModalProps: (
    welloWebLinkModal: WelloWebLinkModalPros | null,
  ) => void;

  shareLink: null | string;
  setShareLink: (shareLink: string | null) => void;

  logList: Log[];
  addLog: (log: Log) => void;
}

export const useGlobalStore = create<GlobalStore>((set) => {
  return {
    isMounted: false,
    setIsMounted: (isMounted) => set({ isMounted }),

    currentHistoryKey: 1,
    setCurrentHistoryKey: (setCurrentHistoryKeyAction) => {
      return set((state) => {
        const currentHistoryKey =
          setCurrentHistoryKeyAction instanceof Function
            ? setCurrentHistoryKeyAction(state.currentHistoryKey ?? 1)
            : setCurrentHistoryKeyAction;

        sessionStorage.setItem(
          STORAGE_KEY.CURRENT_HISTORY_KEY,
          String(currentHistoryKey),
        );

        return {
          currentHistoryKey,
        };
      });
    },

    historyMap: new Map(),
    setHistoryMap: (setHistoryMapAction) => {
      return set((state) => {
        const historyMap =
          setHistoryMapAction instanceof Function
            ? setHistoryMapAction(state.historyMap ?? new Map())
            : setHistoryMapAction;

        const historyList = [...historyMap.entries()];

        sessionStorage.setItem(
          STORAGE_KEY.HISTORY_MAP,
          JSON.stringify(historyList),
        );

        return {
          historyMap,
        };
      });
    },

    setTempHistoryKey: (tempHistoryKey) => set({ tempHistoryKey }),

    isGlobalLoading: false,
    setIsGlobalLoading: (isGlobalLoading) => set({ isGlobalLoading }),

    termsDrawerType: null,
    setTermsDrawerType: (termsDrawerType) => set({ termsDrawerType }),

    welloWebLinkModalProps: null,
    setWelloWebLinkModalProps: (welloWebLinkModalProps) =>
      set({ welloWebLinkModalProps }),

    shareLink: null,
    setShareLink: (shareLink) => set({ shareLink }),

    logList: [],
    addLog: (log) => set(({ logList }) => ({ logList: [...logList, log] })),
  };
});
