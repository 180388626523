import type { ButtonProps } from '..';

import { cleanClassName } from '@wello-client/common/utils';

import { Button } from '..';

import styles from './ButtonFooter.module.scss';

export type FooterButtonProps = ButtonProps;

export const ButtonFooter = ({
  className,
  style,
  shape,
  ...restButtonProps
}: FooterButtonProps) => {
  return (
    <footer
      className={cleanClassName(`${styles.footer} ${className}`)}
      style={style}
    >
      <Button {...restButtonProps} className={styles.button} shape={shape} />
    </footer>
  );
};
