/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { CheckCertificationCodeByContractRequest } from '../models/CheckCertificationCodeByContractRequest';
import type { DefaultHttpResponse_boolean_ } from '../models/DefaultHttpResponse_boolean_';
import type { DefaultHttpResponse_DetailAdminResponse_ } from '../models/DefaultHttpResponse_DetailAdminResponse_';
import type { DefaultHttpResponse_DetailCompanyMemberResponse_ } from '../models/DefaultHttpResponse_DetailCompanyMemberResponse_';
import type { DefaultHttpResponse_DetailInstitutionMemberResponse_ } from '../models/DefaultHttpResponse_DetailInstitutionMemberResponse_';
import type { DefaultHttpResponse_DetailMemberResponse_ } from '../models/DefaultHttpResponse_DetailMemberResponse_';
import type { DefaultHttpResponse_Void_ } from '../models/DefaultHttpResponse_Void_';
import type { SendCertificationCodeByCellPhoneRequest } from '../models/SendCertificationCodeByCellPhoneRequest';
import type { SendCertificationEmailRequest } from '../models/SendCertificationEmailRequest';
import type { WelloMessageRejectRequest } from '../models/WelloMessageRejectRequest';
import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';
export class ApiService {
    /**
     * 관리자 상세
     * @returns DefaultHttpResponse_DetailAdminResponse_ OK
     * @throws ApiError
     */
    public static getMyInfoUsingGet1(): CancelablePromise<DefaultHttpResponse_DetailAdminResponse_> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/wello/v2/auth/member-oauth/admin/my-info',
            errors: {
                500: `SQL 오류 및 기타 미처리 예외 상황입니다.`,
                502: `외부 API 오류 입니다.`,
            },
        });
    }
    /**
     * B2B 회원 상세
     * @returns DefaultHttpResponse_DetailCompanyMemberResponse_ OK
     * @throws ApiError
     */
    public static getCompanyMemberInfoUsingGet1({
        companyId,
        adminYn,
    }: {
        /**
         * company_id
         */
        companyId: number,
        /**
         * admin_yn
         */
        adminYn?: boolean,
    }): CancelablePromise<DefaultHttpResponse_DetailCompanyMemberResponse_> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/wello/v2/auth/member-oauth/company/my-info',
            headers: {
                'company_id': companyId,
            },
            query: {
                'admin_yn': adminYn,
            },
            errors: {
                500: `SQL 오류 및 기타 미처리 예외 상황입니다.`,
                502: `외부 API 오류 입니다.`,
            },
        });
    }
    /**
     * 기관 회원 상세
     * @returns DefaultHttpResponse_DetailInstitutionMemberResponse_ OK
     * @throws ApiError
     */
    public static getInstitutionMemberInfoUsingGet1({
        institutionId,
        adminYn,
    }: {
        /**
         * institution_id
         */
        institutionId: number,
        /**
         * admin_yn
         */
        adminYn?: boolean,
    }): CancelablePromise<DefaultHttpResponse_DetailInstitutionMemberResponse_> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/wello/v2/auth/member-oauth/institution/my-info',
            headers: {
                'institution_id': institutionId,
            },
            query: {
                'admin_yn': adminYn,
            },
            errors: {
                500: `SQL 오류 및 기타 미처리 예외 상황입니다.`,
                502: `외부 API 오류 입니다.`,
            },
        });
    }
    /**
     * 사용자 로그아웃
     * @returns DefaultHttpResponse_boolean_ OK
     * @throws ApiError
     */
    public static logoutMemberUsingGet1(): CancelablePromise<DefaultHttpResponse_boolean_> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/wello/v2/auth/member-oauth/logout',
            errors: {
                500: `SQL 오류 및 기타 미처리 예외 상황입니다.`,
                502: `외부 API 오류 입니다.`,
            },
        });
    }
    /**
     * 웰로 회원 상세
     * @returns DefaultHttpResponse_DetailMemberResponse_ OK
     * @throws ApiError
     */
    public static getMemberUsingGet1(): CancelablePromise<DefaultHttpResponse_DetailMemberResponse_> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/wello/v2/auth/member-oauth/my-info',
            errors: {
                500: `SQL 오류 및 기타 미처리 예외 상황입니다.`,
                502: `외부 API 오류 입니다.`,
            },
        });
    }
    /**
     * 웰로 멤버 메세지 수신거부
     * @returns DefaultHttpResponse_Void_ OK
     * @throws ApiError
     */
    public static rejectMessageUsingPut2({
        requestBody,
    }: {
        requestBody?: WelloMessageRejectRequest,
    }): CancelablePromise<DefaultHttpResponse_Void_> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/wello/v2/auth/message-reject/member-wello',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                500: `SQL 오류 및 기타 미처리 예외 상황입니다.`,
                502: `외부 API 오류 입니다.`,
            },
        });
    }
    /**
     * 휴대전화번호 인증 발송
     * @returns DefaultHttpResponse_Void_ OK
     * @throws ApiError
     */
    public static sendCertificationCellphoneUsingPost3({
        requestBody,
    }: {
        requestBody?: SendCertificationCodeByCellPhoneRequest,
    }): CancelablePromise<DefaultHttpResponse_Void_> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/wello/v2/auth/certification/cellphone',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                500: `SQL 오류 및 기타 미처리 예외 상황입니다.`,
                502: `외부 API 오류 입니다.`,
            },
        });
    }
    /**
     * 인증 번호 확인
     * @returns DefaultHttpResponse_Void_ OK
     * @throws ApiError
     */
    public static certificationCheckUsingPost2({
        requestBody,
    }: {
        requestBody?: CheckCertificationCodeByContractRequest,
    }): CancelablePromise<DefaultHttpResponse_Void_> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/wello/v2/auth/certification/check',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                500: `SQL 오류 및 기타 미처리 예외 상황입니다.`,
                502: `외부 API 오류 입니다.`,
            },
        });
    }
    /**
     * email 인증 발송
     * @returns DefaultHttpResponse_Void_ OK
     * @throws ApiError
     */
    public static sendCertificationEmailUsingPost2({
        requestBody,
    }: {
        requestBody?: SendCertificationEmailRequest,
    }): CancelablePromise<DefaultHttpResponse_Void_> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/wello/v2/auth/certification/email',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                500: `SQL 오류 및 기타 미처리 예외 상황입니다.`,
                502: `외부 API 오류 입니다.`,
            },
        });
    }
}
