import type { COMMENT_TYPE } from '@/api';
import type { MyInfoTab, TabType, UnifiedTabType } from '@/types/tabs';

import { ROUTES } from '.';

export const MY_INFO_TAB = [
  'comment',
  'scrap',
  'apply',
] as const satisfies MyInfoTab[];

export const tabs = [
  'policyScrap',
  'hometownNewsScrap',
  'bestContentsScrap',
] as const;

export const tabLabels = {
  policyScrap: '정책',
  hometownNewsScrap: '동네소식',
  bestContentsScrap: '정책소식',
} satisfies Record<TabType, string>;

export const tabToCommentTypeMap = {
  all: undefined,
  policyScrap: 'WELLO_COMMENT_TYPE_POLICY',
  hometownNewsScrap: 'WELLO_COMMENT_TYPE_HOMETOWN_NEWS',
  bestContentsScrap: 'WELLO_COMMENT_TYPE_BEST_CONTENTS',
} satisfies Record<UnifiedTabType, `${COMMENT_TYPE}` | undefined>;

export const tabToRouteMap = (idIdx: string): { [key: string]: string } => ({
  WELLO_COMMENT_TYPE_POLICY: ROUTES.POLICY_DETAIL.pathname({ idIdx }),
  WELLO_COMMENT_TYPE_HOMETOWN_NEWS: ROUTES.HOMETOWN_NEWS_DETAIL.pathname({
    idIdx,
  }),
  WELLO_COMMENT_TYPE_BEST_CONTENTS: ROUTES.POLICY_INFO_DETAIL.pathname({
    idIdx,
  }),
});

export enum MENU_TITLE {
  TARGET = 'TARGET',
  SUPPORT = 'SUPPORT',
  INFO = 'INFO',
  APPLY = 'APPLY',
}
