import type { VERIFICATION_TYPE } from '@/api';
import type { tabs } from '@/constants';
import type {
  BestContentsWishlistResponse,
  HometownNewsWishlistResponse,
  MetaPolicyWishlistResponse,
} from '@generatedapi/auth';

export enum FAMILY_PROFILE_UPDATE_PAGE_TYPE {
  ADD = 'add',
  EDIT = 'edit',
}

export type TabType = (typeof tabs)[number];
export type UnifiedTabType = 'all' | TabType;

export type MyInfoTab = 'scrap' | 'comment' | 'apply';

export type MyInfoScrapList = [
  MetaPolicyWishlistResponse | undefined,
  HometownNewsWishlistResponse | undefined,
  BestContentsWishlistResponse | undefined,
];

export type EasyCertificationParams = {
  verification: VERIFICATION_TYPE;
  applyPolicyId?: number;
};
