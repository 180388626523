import type { HTMLTagProps } from '@/types';

import {
  createContext,
  useContext,
  useEffect,
  useMemo,
  useReducer,
  useState,
  type Dispatch,
} from 'react';
import { createPortal } from 'react-dom';

import { cleanClassName } from '@wello-client/common/utils';
import { type DebouncedFunc, throttle } from 'lodash-es';

import styles from './Tooltip.module.scss';

interface Location {
  top: number;
  left: number;
}

enum OPENING_TRANSITION {
  OPENING = 1,
  OPENED = 2,
  CLOSING = 3,
  CLOSED = 0,
}

interface TooltipContextValue {
  locationState: [Location, DebouncedFunc<Dispatch<Location>>];
  tooltipState: [
    OPENING_TRANSITION,
    (status: boolean, runInstantly?: boolean) => void,
  ];
}

const TooltipContext = createContext<TooltipContextValue | undefined>(
  undefined,
);

export interface TooltipContextProviderProps {
  children: React.ReactNode;
  mouseEnterDelay?: number;
}

const locationReducer = (_: Location, event: Location) => ({
  left: event.left,
  top: event.top + 10,
});

const CLOSING_DURATION = 100;

const TooltipContextProvider = ({
  children,
  mouseEnterDelay = 200,
}: TooltipContextProviderProps) => {
  const [status, setStatus] = useState(OPENING_TRANSITION.CLOSED);

  useEffect(() => {
    switch (status) {
      case OPENING_TRANSITION.CLOSING: {
        const closeTimer = setTimeout(() => {
          setStatus(OPENING_TRANSITION.CLOSED);
        }, CLOSING_DURATION);

        return () => clearTimeout(closeTimer);
      }

      case OPENING_TRANSITION.OPENING: {
        const openTimer = setTimeout(
          () => setStatus(OPENING_TRANSITION.OPENED),
          mouseEnterDelay,
        );

        return () => clearTimeout(openTimer);
      }
    }
  }, [mouseEnterDelay, setStatus, status]);

  const [location, dispatchLocation] = useReducer(locationReducer, {
    left: 0,
    top: 0,
  });

  const [throttledDispatchLocation, handleStatus] = useMemo(
    () => [
      throttle(dispatchLocation, 100),
      (status: boolean, runInstantly?: boolean) => {
        if (status) {
          if (mouseEnterDelay && !runInstantly)
            setStatus(OPENING_TRANSITION.OPENING);
          else setStatus(OPENING_TRANSITION.OPENED);
        } else if (CLOSING_DURATION && !runInstantly)
          setStatus(OPENING_TRANSITION.CLOSING);
        else setStatus(OPENING_TRANSITION.CLOSED);
      },
    ],
    [mouseEnterDelay],
  );

  const tooltipContextValue = useMemo(
    () =>
      ({
        locationState: [location, throttledDispatchLocation] as const,
        tooltipState: [status, handleStatus] as const,
      }) satisfies TooltipContextValue,
    [handleStatus, location, status, throttledDispatchLocation],
  );

  return (
    <TooltipContext.Provider value={tooltipContextValue}>
      <>{children}</>
    </TooltipContext.Provider>
  );
};

const useTooltipContext = () => {
  const context = useContext(TooltipContext);
  if (context === undefined) {
    throw new Error(
      'useTooltipContext must be used within a TooltipContextProvider',
    );
  }

  return context;
};

export type TooltipAreaProps = HTMLTagProps<'div'>;

const TooltipArea = ({
  //* HTML div props
  className,
  onMouseMove,
  onMouseLeave,
  ...restDivProps
}: TooltipAreaProps) => {
  const {
    tooltipState: [, setOpeningTransition],
    locationState: [, dispatchLocation],
  } = useTooltipContext();

  useEffect(() => {
    const scrollEventHandler = () => setOpeningTransition(false, true);
    document.addEventListener('scroll', scrollEventHandler);

    return () => document.removeEventListener('scroll', scrollEventHandler);
  }, [setOpeningTransition]);

  return (
    <div
      {...restDivProps}
      className={cleanClassName(`${styles.tooltip} ${className}`)}
      onMouseLeave={(e) => {
        setOpeningTransition(false);
        onMouseLeave?.(e);
        dispatchLocation({
          left: e.clientX,
          top: e.clientY,
        });
      }}
      onMouseMove={(e) => {
        setOpeningTransition(true);
        dispatchLocation({
          left: e.clientX,
          top: e.clientY,
        });
        onMouseMove?.(e);
      }}
    />
  );
};

export interface TooltipContentProps
  extends Omit<HTMLTagProps<'div'>, 'styles'> {
  align?: 'start' | 'center' | 'end';
}

const TooltipContent = ({
  //* HTML div props
  children,
  className,
  onMouseEnter,
  onMouseLeave,
  align = 'center',
  style,
  ...restDivProps
}: TooltipContentProps) => {
  const {
    tooltipState: [openingTransition, setOpeningTransition],
    locationState: [location],
  } = useTooltipContext();

  return openingTransition
    ? createPortal(
        <div
          {...restDivProps}
          className={`${styles['tooltip-message-container']} ${
            openingTransition === OPENING_TRANSITION.CLOSING && styles.closing
          } ${styles[align]}`}
          style={{
            left: location.left,
            top: location.top,
          }}
          onMouseEnter={(e) => {
            setOpeningTransition(true);
            onMouseEnter?.(e);
          }}
          onMouseLeave={(e) => {
            setOpeningTransition(false);
            onMouseLeave?.(e);
          }}
        >
          <div className={styles.triangle} />
          <div
            className={cleanClassName(
              `${styles['tooltip-message-wrap']} ${styles[align]} ${className}`,
            )}
            style={style}
          >
            {children}
          </div>
        </div>,
        document.body,
      )
    : null;
};

export const Tooltip = Object.assign(TooltipContextProvider, {
  Area: TooltipArea,
  Content: TooltipContent,
});
