'use client';

import { useEffect } from 'react';

import { usePathname } from 'next/navigation';

import { WEBVIEW_TYPE, useDeviceInfoStore } from '@/stores/DeviceStore';

export const KbAppController = () => {
  const pathname = usePathname();
  const isKbWebview = useDeviceInfoStore(
    (state) => state.webviewType === WEBVIEW_TYPE.KB_PAY,
  );

  useEffect(() => {
    if (!isKbWebview) return;

    document.querySelectorAll('a').forEach((el) => {
      const href = el.getAttribute('href');

      if (!href) return;

      if (!href.startsWith('https')) return;

      if (href.startsWith(location.origin)) return;

      el.addEventListener('click', () => {
        window.location.href = `external${href}`;
      });
    });
  }, [isKbWebview, pathname]);

  return <></>;
};
