'use client';

import { useMemo } from 'react';

import { isCurrentUrl } from '@wello-client/common/utils';
import { useRouter } from 'next/navigation';
import { useShallow } from 'zustand/react/shallow';

import { ROUTES } from '@/constants';
import { CUSTOM_SCHEME } from '@/modules/wello-app';
import { useGlobalStore } from '@/stores/GlobalStore';

import { useCheckWelloApp } from './useCheckWelloApp';

interface BackOptions {
  replaceOnEmptyHistory?: string;
}

export const useCustomRouter = () => {
  const router = useRouter();
  const [setIsGlobalLoading, setTempHistoryKey] = useGlobalStore(
    useShallow((state) => [state.setIsGlobalLoading, state.setTempHistoryKey]),
  );

  const { isWelloApp } = useCheckWelloApp();

  const customRouter = useMemo(() => {
    interface Options
      extends Exclude<Parameters<typeof router.push>[1], undefined> {
      globalLoading?: boolean;
    }

    const push = (href: string, options?: Options) => {
      const { globalLoading, ...originalOptions } = options ?? {};
      if (!isCurrentUrl(href)) setIsGlobalLoading(globalLoading ?? true);

      if (isWelloApp) {
        if (href.includes(ROUTES.EASY_CERTIFICATION.pathname)) {
          const url = new URL(href, location.origin);

          location.href = `${CUSTOM_SCHEME.IN_APP_EXTERNAL_PREFIX}${url.href}`;

          return;
        }
      }

      return router.push(href, originalOptions);
    };

    const replace = (href: string, options?: Options) => {
      const { globalLoading, ...originalOptions } = options ?? {};
      if (!isCurrentUrl(href)) setIsGlobalLoading(globalLoading ?? true);

      setTempHistoryKey(history.state.key);

      return router.replace(href, originalOptions);
    };

    const back = (options?: BackOptions) => {
      const { historyMap, currentHistoryKey } = useGlobalStore.getState();

      const prevKeyHistory = historyMap.get(currentHistoryKey - 1);

      if (prevKeyHistory) return router.back();

      return router.replace(
        options?.replaceOnEmptyHistory ?? ROUTES.HOME.pathname,
      );
    };

    return {
      ...router,
      push,
      replace,
      back,
    };
  }, [isWelloApp, router, setIsGlobalLoading, setTempHistoryKey]);

  return customRouter;
};
