import { cleanClassName } from '@wello-client/common/utils';
import Link from 'next/link';

import { LazyImage } from '@/components/client';
import {
  NewsCardFooter,
  Skeleton,
  type NewsCardFooterProps,
} from '@/components/server';

import styles from './NewsCard.module.scss';

interface NewsCardProps extends NewsCardFooterProps {
  title?: string;
  dateText?: string;
  thumbnail?: string;
  href?: string;
  hasAnimation?: boolean;
  onClick?: () => void;
}

export const NewsCard = ({
  title,
  dateText,
  href = '#',
  isLoading,
  thumbnail,
  hasAnimation,
  onClick,
  ...restProps
}: NewsCardProps) => {
  return (
    <article
      className={cleanClassName(
        `${styles['news-card']} ${hasAnimation && styles.animated}`,
      )}
    >
      <Link className={styles.link} href={href} onClick={onClick}>
        <LazyImage
          alt={`news-card-${title}`}
          className={styles.thumbnail}
          isLoading={isLoading}
          src={thumbnail}
        />
        <div className={styles['card-info']}>
          {isLoading ? (
            <div className={styles['title-skeleton-container']}>
              <Skeleton className={styles['title-skeleton']} />
              <Skeleton className={styles['title-skeleton']} />
            </div>
          ) : (
            <h3 className={styles.title}>{title}</h3>
          )}
          {isLoading ? (
            <Skeleton className={styles['date-text-skeleton']} />
          ) : (
            <p className={styles['date-text']}>{dateText}</p>
          )}
        </div>
      </Link>
      <NewsCardFooter {...restProps} isLoading={isLoading} />
    </article>
  );
};
