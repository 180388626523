import type {
  FlutterInAppWebViewWindow,
  MESSAGE_KEYWORD,
  MessageRequest,
  MessageResponse,
} from '..';

import { useCallback, useEffect, useState } from 'react';

import { createUuid } from '@wello-client/common/utils';
import { useShallow } from 'zustand/react/shallow';

import { useCheckWelloApp } from '@/hooks/useCheckWelloApp';
import { useDeviceInfoStore } from '@/stores/DeviceStore';

interface UseHandleAppProps {
  type: MESSAGE_KEYWORD;
}

export const useHandleApp = <TData = unknown>({ type }: UseHandleAppProps) => {
  const { isWelloApp } = useCheckWelloApp();
  const [messageId, setMessageId] = useState('');
  const [appMessageLog, osType] = useDeviceInfoStore(
    useShallow((state) => [state.appMessageLog, state.osType]),
  );
  const [isPending, setIsPending] = useState(false);

  const response = appMessageLog.get(messageId) as
    | MessageResponse<TData>
    | undefined;

  useEffect(() => {
    if (response) {
      setIsPending(false);
    }
  }, [response]);

  const request = useCallback(
    (params?: MessageRequest['params']) => {
      try {
        if (!isWelloApp || !osType) return;
        setIsPending(true);

        const id = createUuid();

        setMessageId(id);

        const _window = window as FlutterInAppWebViewWindow;

        const reqParams = JSON.stringify({
          type,
          id,
          params,
        } satisfies MessageRequest);

        if (navigator.userAgent.includes('swift')) {
          return _window.webkit.messageHandlers['wello-channel'].postMessage(
            reqParams,
          );
        }

        return _window.flutter_inappwebview.callHandler(
          'wello-channel',
          reqParams,
        );

        /**
         * TODO: 🚀 app store 유저 swift 앱으로 이동 후 해당 분기를 이용하도록 해야함
         * switch (osType) {
          case 'android': {
            return _window.flutter_inappwebview.callHandler(
              'wello-channel',
              reqParams,
            );
          }

          case 'ios': {
            return _window.webkit.messageHandlers['wello-channel'].postMessage(
              reqParams,
            );
          }
        }
         */
      } catch (e) {
        setIsPending(false);
      }
    },
    [isWelloApp, osType, type],
  );

  return {
    isPending,
    response,
    request,
  };
};
