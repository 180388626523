import { Fragment, useCallback, useLayoutEffect, useState } from 'react';
import { RotateCw, Users } from 'react-feather';
import { useForm } from 'react-hook-form';
import { scroller } from 'react-scroll';

import { createYupSchemaResolver, customYup } from '@wello-client/common/utils';
import dayjs from 'dayjs';
import { isEqual, uniq } from 'lodash-es';

import { Button } from '@/components/server';
import {
  EXCEPTED_CODE,
  EXCEPTED_HIDDEN_CODE,
  type FilterMetaData,
  type MetaData,
} from '@/hooks/useGetFilterMetaData';
import { useToast } from '@/hooks/useToast';

import { Chip, Input, InputWrap, Modal, Selectbox, Tooltip } from '..';

import styles from './CustomPolicyFilterForm.module.scss';

enum HELP_MODAL {
  FAMILY_COUNT = 'familyCount',
  FAMILY_INCOME = 'familyIncome',
}

export interface FormValues {
  filterValues: MetaData[];
  familyCount: number;
  familyIncome: number;
  unemploymentDate?: string;
}

export interface CustomPolicyFilterFormProps
  extends Pick<
    FilterMetaData,
    'customPolicyMetaDataList' | 'genderMetaData' | 'regionMetaData'
  > {
  initialValues?: Partial<FormValues>;
  onSubmit?: (data: FormValues) => void;
  isSubmitting?: boolean;
  submitButtonLabel?: string;
}

export const CustomPolicyFilterForm = ({
  customPolicyMetaDataList,
  genderMetaData,
  regionMetaData,
  initialValues,
  onSubmit,
  isSubmitting,
  submitButtonLabel,
}: CustomPolicyFilterFormProps) => {
  const rootMetaDataMap = new Map<string, MetaData>();

  const [selectedMetaDataMap, setSelectedMetaDataMap] = useState(
    new Map<string, MetaData>(),
  );

  const selectedCodeList = [...selectedMetaDataMap.keys()];

  const selectedRegionCodeList = selectedCodeList.filter((code) =>
    code.includes(EXCEPTED_CODE.REGION),
  );

  let selectedMainRegionCode = '';
  let selectedSubRegionCode = '';

  selectedRegionCodeList.forEach((code) => {
    if (code.split('-').length === 2) {
      selectedMainRegionCode = code;
    } else {
      selectedSubRegionCode = code;
    }
  });

  //! ⚠️ 상태 값으로 관리할 필요가 없는 값
  const subTitleCodeList: string[] = [];

  const {
    register,
    getValues,
    formState: { errors },
    reset,
    handleSubmit,
  } = useForm<Omit<FormValues, 'filterValues'>>({
    resolver: createYupSchemaResolver<Omit<FormValues, 'filterValues'>>(
      (yup) => ({
        familyCount: yup
          .number()
          .required('가구원 수를 입력해 주세요.')
          .integer('정수만 입력해 주세요.')
          .required('가구원 수를 입력해 주세요.')
          .min(1, '1 이상의 숫자만 입력해 주세요.')
          .max(99, '99 이하의 숫자만 입력해 주세요.'),
        familyIncome: yup
          .number()
          .required('가구 월소득을 입력해 주세요.')
          .integer('정수만 입력해 주세요.')
          .max(9999, '1억 이하의 숫자만 입력해 주세요.'),
        unemploymentDate: selectedMetaDataMap.has(EXCEPTED_CODE.UNEMPLOYMENT)
          ? customYup
              .stringDate({
                onlyPast: true,
              })
              .required('실직일을 입력해 주세요')
          : yup.string().nullable(),
      }),
    ),
    mode: 'onBlur',
    resetOptions: {
      keepDefaultValues: true,
    },
  });

  const resetAll = useCallback(() => {
    if (initialValues) {
      const { filterValues, familyIncome, ...restinitialValues } =
        initialValues;

      setSelectedMetaDataMap(
        new Map(
          filterValues?.map((metaData) => {
            if (!metaData.code) throw new Error('metaData.code가 없습니다.');

            return [metaData.code, metaData];
          }),
        ),
      );

      reset({
        ...restinitialValues,
        familyIncome: familyIncome ? familyIncome / 10000 : undefined,
      });
    }
  }, [initialValues, reset]);

  useLayoutEffect(() => resetAll(), [resetAll]);

  const [openedHelpModal, setOpenedHelpModal] = useState<HELP_MODAL>();
  const closeModal = () => setOpenedHelpModal(undefined);

  const applySelectedMap = () =>
    setSelectedMetaDataMap(
      (selectedMetaDataMap) => new Map(selectedMetaDataMap),
    );

  const { toast } = useToast();

  return (
    <>
      <form
        className={styles.form}
        onSubmit={handleSubmit(
          ({ familyIncome, familyCount, unemploymentDate }) => {
            if (!(selectedMainRegionCode && selectedSubRegionCode)) {
              scroller.scrollTo(EXCEPTED_CODE.REGION, {
                smooth: true,
                offset: -100,
              });

              return toast({
                message: '지역을 선택해주세요.',
                type: 'fail',
              });
            }

            if (
              !selectedMetaDataMap.has(`${EXCEPTED_CODE.GENDER}-01`) &&
              !selectedMetaDataMap.has(`${EXCEPTED_CODE.GENDER}-02`)
            ) {
              scroller.scrollTo(EXCEPTED_CODE.GENDER, {
                smooth: true,
                offset: -100,
              });

              return toast({
                message: '성별을 선택해주세요.',
                type: 'fail',
              });
            }

            const selectedParentCodeOfCodeList: string[] = [];

            selectedMetaDataMap.forEach((metaData) => {
              const { parent_code } = metaData;
              if (parent_code) selectedParentCodeOfCodeList.push(parent_code);
            });

            //* 선택안한 2차 메타데이터 확인
            for (const [rootMetaCode, metaData] of rootMetaDataMap.entries()) {
              if (!selectedParentCodeOfCodeList.includes(rootMetaCode)) {
                scroller.scrollTo(rootMetaCode, {
                  smooth: true,
                  offset: -100,
                });

                return toast({
                  message: `${metaData.value}값을 선택해주세요.`,
                  type: 'fail',
                });
              }
            }

            //* 선택안한 3차 이상 메타데이터 확인
            const tempHasChildrenSubMetaCodeList: string[] = [];

            customPolicyMetaDataList?.forEach(({ level, parent_code }) => {
              if (2 < (level ?? 0) && parent_code) {
                tempHasChildrenSubMetaCodeList.push(parent_code);
              }
            });

            const hasChildrenSubMetaCodeList = uniq(
              tempHasChildrenSubMetaCodeList,
            );

            for (const [code, metaCode] of selectedMetaDataMap.entries()) {
              if ((metaCode.level ?? 0) < 2) continue;
              const hasChildren = hasChildrenSubMetaCodeList.includes(code);

              if (!hasChildren) continue;

              const isChildrenSelected =
                selectedParentCodeOfCodeList.includes(code);

              if (!isChildrenSelected) {
                scroller.scrollTo(code, {
                  smooth: true,
                  offset: -100,
                });

                return toast({
                  message: `${metaCode.value}값을 선택해주세요.`,
                  type: 'fail',
                });
              }
            }
            onSubmit?.({
              familyCount,
              unemploymentDate: unemploymentDate
                ? dayjs(unemploymentDate).format('YYYY-MM-DD')
                : undefined,
              filterValues: [...selectedMetaDataMap.values()],
              familyIncome: familyIncome * 10000,
            });
          },
          (error) => {
            for (const key in error) {
              const message = error[key as keyof typeof error]?.message;

              if (message) {
                scroller.scrollTo(key, {
                  smooth: true,
                  offset: -100,
                });

                return toast({
                  message,
                  type: 'fail',
                });
              }
            }
          },
        )}
      >
        <dl className={styles.filter}>
          <dt id={EXCEPTED_CODE.REGION}>지역</dt>
          <dd className={styles['region-filter']}>
            <Selectbox
              options={regionMetaData.mainRegion.map(({ value, code }) => {
                if (!code || !value) {
                  throw new Error('지역 데이터가 올바르지 않습니다.');
                }

                return { label: value, value: code };
              })}
              placeholder="광역시도 선택"
              size="large"
              value={selectedMainRegionCode}
              onChange={({ value }) => {
                if (value === selectedMainRegionCode) return;

                selectedRegionCodeList.forEach((code) =>
                  selectedMetaDataMap.delete(code),
                );

                const selectedRegionMetaData = regionMetaData.mainRegion.find(
                  ({ code }) => value === code,
                );

                if (selectedRegionMetaData) {
                  selectedMetaDataMap.set(value, selectedRegionMetaData);
                }

                applySelectedMap();
              }}
            />
            <Selectbox
              disabled={!selectedMainRegionCode}
              options={regionMetaData.subRegion
                .filter(
                  ({ parent_code }) => parent_code === selectedMainRegionCode,
                )
                .sort((a, b) => {
                  if (
                    typeof a.value !== 'string' ||
                    typeof b.value !== 'string'
                  ) {
                    return 0;
                  }

                  return a?.value.localeCompare(b?.value);
                })
                .map(({ code, value }) => {
                  if (!code || !value) {
                    throw new Error('지역 데이터가 올바르지 않습니다.');
                  }

                  return {
                    label: value,
                    value: code,
                  };
                })}
              placeholder="시군구 선택"
              size="large"
              value={selectedSubRegionCode}
              onChange={({ value }) => {
                if (value === selectedSubRegionCode) return;

                selectedMetaDataMap.delete(selectedSubRegionCode);

                const selectedRegionMetaData = regionMetaData.subRegion.find(
                  ({ code }) => value === code,
                );

                if (selectedRegionMetaData) {
                  selectedMetaDataMap.set(value, selectedRegionMetaData);
                }

                applySelectedMap();
              }}
            />
          </dd>
          <dt id={EXCEPTED_CODE.GENDER}>성별</dt>
          <dd className={styles['gender-filter']}>
            {genderMetaData.map((meta, index) => {
              const { code, value } = meta;

              if (!code) return null;

              return (
                <Chip
                  key={index}
                  checked={selectedMetaDataMap.has(code)}
                  className={styles['genger-filter-item']}
                  shape="rectangle"
                  size="large"
                  onChange={(e) => {
                    const { checked } = e.target;

                    if (checked) {
                      selectedCodeList
                        .filter((code) => code.includes(EXCEPTED_CODE.GENDER))
                        .forEach((code) => selectedMetaDataMap.delete(code));

                      selectedMetaDataMap.set(code, meta);
                      applySelectedMap();
                    } else {
                      selectedMetaDataMap.delete(code);
                      applySelectedMap();
                    }
                  }}
                >
                  {value}
                </Chip>
              );
            })}
          </dd>
          {customPolicyMetaDataList?.map((metaData, index) => {
            const { code, value, level, duplicate_avail_yn, parent_code } =
              metaData;

            if (!code) return null;

            switch (level) {
              case 1: {
                rootMetaDataMap.set(code, metaData);

                return (
                  <Fragment key={index}>
                    {code === 'C12' ? (
                      <>
                        {selectedCodeList.includes(
                          EXCEPTED_CODE.UNEMPLOYMENT,
                        ) ? (
                          <>
                            <dt
                              className={`${styles['input-title']} ${styles['sub-title']}`}
                            >
                              실직일
                              <Tooltip>
                                <Tooltip.Area>
                                  <div className={styles['help-icon']}>?</div>
                                </Tooltip.Area>
                                <Tooltip.Content align="start">
                                  4대보험 기준 실직 시작일
                                  <br />
                                  취업 경험이 없는 경우 성년(만19세)이 된 시점
                                </Tooltip.Content>
                              </Tooltip>
                            </dt>
                            <dd
                              className={`${styles['input-filter']} ${styles['unemployment-input']}`}
                            >
                              <InputWrap
                                validationMessage={
                                  errors.unemploymentDate?.message
                                }
                              >
                                <Input
                                  {...register('unemploymentDate')}
                                  placeholder="실직일자를 입력해주세요(YYYYMMDD)"
                                  type="text-date"
                                  value={getValues('unemploymentDate')}
                                />
                              </InputWrap>
                            </dd>
                          </>
                        ) : null}
                        <dt className={styles['input-title']}>가구원 수</dt>
                        <dd className={styles['input-filter']}>
                          <InputWrap
                            validationMessage={errors.familyCount?.message}
                          >
                            <Input
                              {...register('familyCount')}
                              max={99}
                              placeholder="가구원 수를 입력해 주세요"
                              type="number"
                              value={getValues('familyCount')}
                            />
                            명
                          </InputWrap>
                          <button
                            className={styles['help-button']}
                            type="button"
                            onClick={() =>
                              setOpenedHelpModal(HELP_MODAL.FAMILY_COUNT)
                            }
                          >
                            <div className={styles['help-icon']}>?</div>
                            가구원수 기준을 모르겠어요.
                          </button>
                        </dd>
                        <dt className={styles['input-title']}>가구 월소득</dt>
                        <dd className={styles['input-filter']}>
                          <InputWrap
                            validationMessage={errors.familyIncome?.message}
                          >
                            <Input
                              {...register('familyIncome')}
                              maxLength={4}
                              placeholder="가구 월소득을 입력해 주세요"
                              type="large-number"
                              value={getValues('familyIncome') ?? 0}
                            />
                            <div className={styles['input-text']}>만원</div>
                          </InputWrap>
                          <button
                            className={styles['help-button']}
                            type="button"
                            onClick={() =>
                              setOpenedHelpModal(HELP_MODAL.FAMILY_INCOME)
                            }
                          >
                            <div className={styles['help-icon']}>?</div>
                            가구 월소득은 가구원의 월소득을 모두 합산한
                            금액입니다.
                          </button>
                        </dd>
                      </>
                    ) : null}
                    <dt id={code}>
                      {value}
                      {duplicate_avail_yn ? <span>(중복선택 가능)</span> : null}
                    </dt>
                  </Fragment>
                );
              }

              case 2: {
                if (!parent_code) return null;

                const rootMetaData = rootMetaDataMap.get(parent_code);

                if (!rootMetaData) return null;

                const isDuplicatable = rootMetaData.duplicate_avail_yn;

                return (
                  <dd key={index}>
                    <Chip
                      checked={selectedMetaDataMap.has(code)}
                      size="small"
                      onChange={(e) => {
                        const { checked } = e.target;

                        //* 최신 선택된 코드 리스트를 가져오기 위한 함수
                        const getSelectedCodeList = () => [
                          ...selectedMetaDataMap.keys(),
                        ];

                        if (checked) {
                          if (isDuplicatable) {
                            selectedMetaDataMap.set(code, metaData);
                          } else {
                            const duplicatedCode = getSelectedCodeList().filter(
                              (code) => code.includes(parent_code),
                            );

                            duplicatedCode.forEach((code) => {
                              selectedMetaDataMap.delete(code);
                            });

                            selectedMetaDataMap.set(code, metaData);
                          }
                        } else {
                          getSelectedCodeList().forEach((selectedCode) => {
                            if (selectedCode.includes(code))
                              selectedMetaDataMap.delete(selectedCode);
                          });
                        }

                        //* 해당사항 없음에 대한 예외처리
                        if (
                          code.includes(EXCEPTED_CODE.TARGET_CHARACTERISTICS)
                        ) {
                          const isTargetEmpty =
                            code ===
                            EXCEPTED_HIDDEN_CODE.TARGET_CHARACTERISTICS_EMPTY;

                          if (checked) {
                            //* 해당 사항 없음 선택시 다른 선택된 대상 특성 삭제
                            if (isTargetEmpty) {
                              getSelectedCodeList().forEach((code) => {
                                if (
                                  code.includes(
                                    EXCEPTED_CODE.TARGET_CHARACTERISTICS,
                                  ) &&
                                  code !==
                                    EXCEPTED_HIDDEN_CODE.TARGET_CHARACTERISTICS_EMPTY
                                )
                                  selectedMetaDataMap.delete(code);
                              });
                            } //* 다른 대상 특성 선택시 해당 사항 없음 삭제
                            else {
                              selectedMetaDataMap.delete(
                                EXCEPTED_HIDDEN_CODE.TARGET_CHARACTERISTICS_EMPTY,
                              );
                            }
                          } //* 대상 특성에 아무것도 선택하지 않았을 때 해당 사항 없음 추가
                          else {
                            const hasSelectedTargetCharacteristics =
                              getSelectedCodeList().some((code) =>
                                code.includes(
                                  EXCEPTED_CODE.TARGET_CHARACTERISTICS,
                                ),
                              );

                            if (!hasSelectedTargetCharacteristics) {
                              const targetCharacteristicsEmptyMetaData =
                                customPolicyMetaDataList.find(
                                  ({ code }) =>
                                    code ===
                                    EXCEPTED_HIDDEN_CODE.TARGET_CHARACTERISTICS_EMPTY,
                                );

                              if (!targetCharacteristicsEmptyMetaData)
                                throw new Error(
                                  'meta data에 대상 특성 해당 없음이 없습니다.',
                                );

                              selectedMetaDataMap.set(
                                EXCEPTED_HIDDEN_CODE.TARGET_CHARACTERISTICS_EMPTY,
                                targetCharacteristicsEmptyMetaData,
                              );
                            }
                          }
                        }

                        applySelectedMap();
                      }}
                    >
                      {value}
                    </Chip>
                  </dd>
                );
              }

              default: {
                if (!parent_code) return null;

                const hasSelectedParentMetaData =
                  selectedMetaDataMap.has(parent_code);

                if (!hasSelectedParentMetaData) return null;

                //! ⚠️ 서버에서 받아온 선택했던 값들은 메타 데이터 값의 정보들이 완벽하지 않음 (ex. duplicate_avail_yn가 모두 false로 나오는 등)
                const parentMetaData = selectedMetaDataMap.get(parent_code);

                if (!parentMetaData) return null;

                const isFirstChild = !subTitleCodeList.includes(parent_code);

                const isDuplicatable = parentMetaData.duplicate_avail_yn;

                if (isFirstChild) {
                  subTitleCodeList.push(parent_code);
                }

                return (
                  <Fragment key={index}>
                    {isFirstChild ? (
                      <dt className={styles['sub-title']} id={parent_code}>
                        {parentMetaData.value}
                        {isDuplicatable ? <span>(중복선택 가능)</span> : null}
                      </dt>
                    ) : null}
                    <dd className={styles['sub-meta-data']}>
                      <Chip
                        checked={selectedMetaDataMap.has(code)}
                        size="small"
                        onChange={(e) => {
                          const { checked } = e.target;

                          if (checked) {
                            if (isDuplicatable) {
                              selectedMetaDataMap.set(code, metaData);
                            } else {
                              const duplicatedCode = selectedCodeList.filter(
                                (code) =>
                                  code !== parent_code &&
                                  code.includes(parent_code),
                              );

                              duplicatedCode.forEach((code) => {
                                selectedMetaDataMap.delete(code);
                              });

                              selectedMetaDataMap.set(code, metaData);
                            }
                          } else {
                            selectedCodeList.forEach((selectedCode) => {
                              if (selectedCode.includes(code))
                                selectedMetaDataMap.delete(selectedCode);
                            });
                          }

                          applySelectedMap();
                        }}
                      >
                        {value}
                      </Chip>
                    </dd>
                  </Fragment>
                );
              }
            }
          })}
        </dl>
        <footer className={styles.footer}>
          <Button
            className={styles['reset-button']}
            icon={<RotateCw height="1em" width="1em" />}
            theme="unset"
            onClick={() => {
              const NewInitialValues = {
                ...initialValues,
                familyIncome: initialValues?.familyIncome
                  ? initialValues?.familyIncome / 10000
                  : undefined,
              };

              const currentValue = {
                ...getValues(),
                filterValues: [...selectedMetaDataMap.values()],
              };

              if (isEqual(NewInitialValues, currentValue)) {
                toast({
                  message: '변경사항이 없어요',
                });

                return;
              }
              resetAll();
              toast({
                message: '초기화되었습니다.',
              });
            }}
          >
            초기화
          </Button>
          <Button
            className={styles['submit-button']}
            loading={isSubmitting}
            shape="capsule"
            type="submit"
          >
            {submitButtonLabel}
          </Button>
        </footer>
      </form>
      <Modal
        className={styles['help-modal']}
        opened={openedHelpModal === HELP_MODAL.FAMILY_COUNT}
        onClose={closeModal}
      >
        <Users className={styles['modal-title-icon']} />
        <h2>가구원 수란?</h2>
        <section>
          한 가구에서 함께 살고 있는 구성원 수를 계산합니다.
          <br />
          주민등록 여부와 관계 없이 같이 살고 있을 경우 <br />
          (예 : 친구)에도 포함됩니다.
          <br />
          <span>1인 가구의 경우 &apos;1명&apos;</span>으로 작성해주세요!
        </section>
        <Button
          className={styles['confirm-button']}
          shape="capsule"
          onClick={closeModal}
        >
          확인
        </Button>
      </Modal>
      <Modal
        className={styles['help-modal']}
        opened={openedHelpModal === HELP_MODAL.FAMILY_INCOME}
        onClose={closeModal}
      >
        <Users className={styles['modal-title-icon']} />
        <h2>가구 월소득이란?</h2>
        <section>
          가구 모든 구성원의 근로소득, 건강보험료 납입고지서 등으로 확인되는
          최근 6개월 간의 평균소득을 의미합니다.
        </section>
        <section>가구의 월 소득을 정확하게 입력하기 어려우시다면</section>
        <section>
          <span>
            [본인소득 + (200만원 X 본인을 제외한 소득이 있는 가구원 수)]
          </span>
          를 입력해 주세요
        </section>
        <p>· 200만원은 2023년 최저임금 기준입니다.</p>
        <Button
          className={styles['confirm-button']}
          shape="capsule"
          onClick={closeModal}
        >
          확인
        </Button>
      </Modal>
    </>
  );
};
