export enum STORAGE_KEY {
  HISTORY_MAP = 'historyMap',
  CURRENT_HISTORY_KEY = 'currentHistoryKey',
  LAST_HISTORY_LENGTH = 'lastHistoryLength',
  SEARCH_HISTORY = 'searchHistory',
  EASY_CERTIFICATION = 'easyCertification',
  KOSAF_PROCESS = 'kosafProcess',
  JEJU_YOUTHDREAM = 'jejuYouthdream',
  NICE_FORM_VALUES = 'niceFormValues',
}
