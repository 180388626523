import type { FooterButtonProps } from '@/components/server';

import { memo } from 'react';

import { cleanClassName } from '@wello-client/common/utils';

import Logo from '@/assets/svgs/logo.svg';
import { ButtonFooter, Spinner } from '@/components/server';

import styles from './LoadingPageTemplate.module.scss';

interface LoadingPageTemplateProps {
  blur?: boolean;
  loading?: boolean;
  hasFooterButton?: boolean;
  buttonFooterProps?: Pick<FooterButtonProps, 'onClick' | 'children'>;
}

export const LoadingPageTemplate = memo(
  ({
    blur = false,
    loading = true,
    hasFooterButton = false,
    buttonFooterProps,
  }: LoadingPageTemplateProps) => (
    <article className={styles['loading-root']}>
      <div className={styles['loading-main']}>
        <div
          className={cleanClassName(
            `${styles['loading-container']} ${blur && styles.blur}`,
          )}
        >
          <Logo className={styles.logo} />
          <div className={!loading ? styles['loading-off'] : ''}>
            <Spinner size="x-large" />
          </div>
          {hasFooterButton ? (
            <ButtonFooter
              {...buttonFooterProps}
              className={styles['button-footer']}
              theme="primary"
            />
          ) : null}
        </div>
      </div>
    </article>
  ),
);

LoadingPageTemplate.displayName = 'LoadingPageTemplate';
