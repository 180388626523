import { useEffect, useRef } from 'react';

/**
 * @description 컴포넌트가 마운트된 이후에만 실행되는 useEffect입니다.
 */
export function useMountedEffect(
  effect: () => void | (() => void),
  deps?: React.DependencyList,
) {
  const isMounted = useRef(false);

  useEffect(() => {
    if (isMounted.current) {
      return effect();
    } else {
      isMounted.current = true;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, deps);
}
