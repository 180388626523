/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { DefaultHttpResponse_SplitRunTestingResponse_ } from '../models/DefaultHttpResponse_SplitRunTestingResponse_';
import type { DefaultHttpResponse_Void_ } from '../models/DefaultHttpResponse_Void_';
import type { EntrySplitRunTestingRequest } from '../models/EntrySplitRunTestingRequest';
import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';
export class AbApiService {
    /**
     * AB 테스트 - 테스트타입 반환
     * @returns DefaultHttpResponse_SplitRunTestingResponse_ OK
     * @throws ApiError
     */
    public static getSplitRunTestingTypeUsingGet1({
        subjectTypeCd,
    }: {
        /**
         * subject_type_cd
         */
        subjectTypeCd: string,
    }): CancelablePromise<DefaultHttpResponse_SplitRunTestingResponse_> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/wello/v2/allows/split-run-testing',
            query: {
                'subject_type_cd': subjectTypeCd,
            },
            errors: {
                500: `SQL 오류 및 기타 미처리 예외 상황입니다.`,
                502: `외부 API 오류 입니다.`,
            },
        });
    }
    /**
     * AB 테스트 집입 회수 증가
     * @returns DefaultHttpResponse_Void_ OK
     * @throws ApiError
     */
    public static addEnterCountUsingPost1({
        requestBody,
    }: {
        requestBody?: EntrySplitRunTestingRequest,
    }): CancelablePromise<DefaultHttpResponse_Void_> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/wello/v2/allows/split-run-testing/entry',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                500: `SQL 오류 및 기타 미처리 예외 상황입니다.`,
                502: `외부 API 오류 입니다.`,
            },
        });
    }
}
