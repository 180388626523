/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { CertificationCompanyMemberRequest } from '../models/CertificationCompanyMemberRequest';
import type { CheckCertificationCodeByContractRequest } from '../models/CheckCertificationCodeByContractRequest';
import type { CheckCertificationCodeByTokenRequest } from '../models/CheckCertificationCodeByTokenRequest';
import type { DefaultHttpResponse } from '../models/DefaultHttpResponse';
import type { DefaultHttpResponse_CertificationEmailResponse_ } from '../models/DefaultHttpResponse_CertificationEmailResponse_';
import type { DefaultHttpResponse_DetailTermResponse_ } from '../models/DefaultHttpResponse_DetailTermResponse_';
import type { DefaultHttpResponse_ListCommonCodeResponse_ } from '../models/DefaultHttpResponse_ListCommonCodeResponse_';
import type { DefaultHttpResponse_ListLogTermResponse_ } from '../models/DefaultHttpResponse_ListLogTermResponse_';
import type { DefaultHttpResponse_Void_ } from '../models/DefaultHttpResponse_Void_';
import type { SendCertificationCodeByCellPhoneRequest } from '../models/SendCertificationCodeByCellPhoneRequest';
import type { SendCertificationCodeByTokenRequest } from '../models/SendCertificationCodeByTokenRequest';
import type { SendCertificationEmailRequest } from '../models/SendCertificationEmailRequest';
import type { WelloAllowsMessageRejectRequest } from '../models/WelloAllowsMessageRejectRequest';
import type { WellobizMessageRejectRequest } from '../models/WellobizMessageRejectRequest';
import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';
export class ApiService {
    /**
     * 블록리스트 초기화
     * 블록리스트 추가된 경우 초기화 합니다
     * @returns DefaultHttpResponse_Void_ OK
     * @throws ApiError
     */
    public static commonCodeInitUsingPost2({
        password,
    }: {
        /**
         * password
         */
        password: string,
    }): CancelablePromise<DefaultHttpResponse_Void_> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/wello/v2/allows/blocklist/reset',
            query: {
                'password': password,
            },
            errors: {
                500: `SQL 오류 및 기타 미처리 예외 상황입니다.`,
                502: `외부 API 오류 입니다.`,
            },
        });
    }
    /**
     * 공통코드 목록 조회
     * 공통코드 목록을 조회합니다
     * @returns DefaultHttpResponse_ListCommonCodeResponse_ OK
     * @throws ApiError
     */
    public static commonCodeListUsingGet1({
        parentCodeType,
        displayYn,
    }: {
        /**
         * parent_code_type
         */
        parentCodeType: string,
        /**
         * display_yn
         */
        displayYn?: boolean,
    }): CancelablePromise<DefaultHttpResponse_ListCommonCodeResponse_> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/wello/v2/allows/common-code',
            query: {
                'parent_code_type': parentCodeType,
                'display_yn': displayYn,
            },
            errors: {
                500: `SQL 오류 및 기타 미처리 예외 상황입니다.`,
                502: `외부 API 오류 입니다.`,
            },
        });
    }
    /**
     * 이미 가입한 sns 기업멤버 인증(웰로 SNS 인증 라이브러리)
     * @returns DefaultHttpResponse_Void_ OK
     * @throws ApiError
     */
    public static companyMemberCertificationUsingPost1({
        companyMemberId,
        requestBody,
    }: {
        /**
         * 기업멤버 ID
         */
        companyMemberId: number,
        requestBody?: CertificationCompanyMemberRequest,
    }): CancelablePromise<DefaultHttpResponse_Void_> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/wello/v2/allows/company-member/certification/{company_member_id}',
            path: {
                'company_member_id': companyMemberId,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                500: `SQL 오류 및 기타 미처리 예외 상황입니다.`,
                502: `외부 API 오류 입니다.`,
            },
        });
    }
    /**
     * 약관 변경이력 조회
     * 약관 변경 이력을 조회합니다.
     * @returns DefaultHttpResponse_ListLogTermResponse_ OK
     * @throws ApiError
     */
    public static termHistoryUsingGet1({
        termCategory,
    }: {
        /**
         * term_category
         */
        termCategory: string,
    }): CancelablePromise<DefaultHttpResponse_ListLogTermResponse_> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/wello/v2/allows/log-term/{term_category}',
            path: {
                'term_category': termCategory,
            },
            errors: {
                500: `SQL 오류 및 기타 미처리 예외 상황입니다.`,
                502: `외부 API 오류 입니다.`,
            },
        });
    }
    /**
     * 웰로비즈 멤버 메세지 수신거부
     * @returns DefaultHttpResponse_Void_ OK
     * @throws ApiError
     */
    public static wellobizRejectMessageUsingPost1({
        requestBody,
    }: {
        requestBody?: WellobizMessageRejectRequest,
    }): CancelablePromise<DefaultHttpResponse_Void_> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/wello/v2/allows/message-reject',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                500: `SQL 오류 및 기타 미처리 예외 상황입니다.`,
                502: `외부 API 오류 입니다.`,
            },
        });
    }
    /**
     * 웰로 멤버 메세지 수신거부
     * @returns DefaultHttpResponse_Void_ OK
     * @throws ApiError
     */
    public static rejectMessageUsingPut3({
        requestBody,
    }: {
        requestBody?: WelloAllowsMessageRejectRequest,
    }): CancelablePromise<DefaultHttpResponse_Void_> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/wello/v2/allows/message-reject/member-wello',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                500: `SQL 오류 및 기타 미처리 예외 상황입니다.`,
                502: `외부 API 오류 입니다.`,
            },
        });
    }
    /**
     * 공통코드 초기화
     * 공통코드가 추가된 경우 초기화 합니다
     * @returns DefaultHttpResponse OK
     * @throws ApiError
     */
    public static commonCodeInitUsingPost3({
        password,
    }: {
        /**
         * password
         */
        password: string,
    }): CancelablePromise<DefaultHttpResponse> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/wello/v2/allows/meta-code/reset',
            query: {
                'password': password,
            },
            errors: {
                500: `SQL 오류 및 기타 미처리 예외 상황입니다.`,
                502: `외부 API 오류 입니다.`,
            },
        });
    }
    /**
     * 약관 조회
     * 약관을 조회합니다.
     * @returns DefaultHttpResponse_DetailTermResponse_ OK
     * @throws ApiError
     */
    public static termUsingGet1({
        termCategory,
    }: {
        /**
         * term_category
         */
        termCategory: string,
    }): CancelablePromise<DefaultHttpResponse_DetailTermResponse_> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/wello/v2/allows/term/{term_category}',
            path: {
                'term_category': termCategory,
            },
            errors: {
                500: `SQL 오류 및 기타 미처리 예외 상황입니다.`,
                502: `외부 API 오류 입니다.`,
            },
        });
    }
    /**
     * 휴대전화번호 인증 발송
     * @returns DefaultHttpResponse_Void_ OK
     * @throws ApiError
     */
    public static sendCertificationCellphoneUsingPost4({
        requestBody,
    }: {
        requestBody?: SendCertificationCodeByTokenRequest,
    }): CancelablePromise<DefaultHttpResponse_Void_> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/wello/v2/allows/certification/cellphone',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                500: `SQL 오류 및 기타 미처리 예외 상황입니다.`,
                502: `외부 API 오류 입니다.`,
            },
        });
    }
    /**
     * 인증 번호 확인
     * @returns DefaultHttpResponse_Void_ OK
     * @throws ApiError
     */
    public static certificationCheckUsingPost3({
        requestBody,
    }: {
        requestBody?: CheckCertificationCodeByTokenRequest,
    }): CancelablePromise<DefaultHttpResponse_Void_> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/wello/v2/allows/certification/check',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                500: `SQL 오류 및 기타 미처리 예외 상황입니다.`,
                502: `외부 API 오류 입니다.`,
            },
        });
    }
    /**
     * 웰로 이메일 인증
     * @returns DefaultHttpResponse_Void_ OK
     * @throws ApiError
     */
    public static welloEmailCertificationUsingPost1({
        requestBody,
    }: {
        requestBody?: CheckCertificationCodeByContractRequest,
    }): CancelablePromise<DefaultHttpResponse_Void_> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/wello/v2/allows/certification/wello-email-check',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                500: `SQL 오류 및 기타 미처리 예외 상황입니다.`,
                502: `외부 API 오류 입니다.`,
            },
        });
    }
    /**
     * 휴대전화번호 인증 발송
     * @returns DefaultHttpResponse_Void_ OK
     * @throws ApiError
     */
    public static sendCertificationCellphoneUsingPost5({
        requestBody,
    }: {
        requestBody?: SendCertificationCodeByCellPhoneRequest,
    }): CancelablePromise<DefaultHttpResponse_Void_> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/wello/v2/allows/member-oauth/certification/certification-cellphone',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                500: `SQL 오류 및 기타 미처리 예외 상황입니다.`,
                502: `외부 API 오류 입니다.`,
            },
        });
    }
    /**
     * email 인증 발송
     * @returns DefaultHttpResponse_CertificationEmailResponse_ OK
     * @throws ApiError
     */
    public static sendCertificationEmailUsingPost3({
        requestBody,
    }: {
        requestBody?: SendCertificationEmailRequest,
    }): CancelablePromise<DefaultHttpResponse_CertificationEmailResponse_> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/wello/v2/allows/member-oauth/certification/certification-email',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                500: `SQL 오류 및 기타 미처리 예외 상황입니다.`,
                502: `외부 API 오류 입니다.`,
            },
        });
    }
    /**
     * 웰로비즈 멤버인증 번호 확인
     * @returns DefaultHttpResponse_Void_ OK
     * @throws ApiError
     */
    public static checkCertificationCodeUsingPost1({
        requestBody,
    }: {
        requestBody?: CheckCertificationCodeByContractRequest,
    }): CancelablePromise<DefaultHttpResponse_Void_> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/wello/v2/allows/member-oauth/certification/check/certification-code',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                500: `SQL 오류 및 기타 미처리 예외 상황입니다.`,
                502: `외부 API 오류 입니다.`,
            },
        });
    }
}
