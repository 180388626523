export enum CUSTOM_SCHEME {
  EXTERNAL_PREFIX = 'external-app::',
  IN_APP_EXTERNAL_PREFIX = 'in-app-external-app::',
  WELLO_APP_PREFIX = 'welfarehello://action?web_link=',
}

export enum MESSAGE_KEYWORD {
  CHECK_KAKAO_INSTALLED = 'check-kakao-installed',
  CHECK_PERMISSION = 'check-permission',
  LOCATION = 'location',
  NOTIFICATION = 'notification',
  CAMERA = 'camera',
  IMAGE = 'image-picker',
  DOCUMENT = 'document-picker',
  LOGIN = 'login',
  VERSION = 'version',
  SHARE_CONTENT = 'share-content',

  SHARE_LIST = 'share-list',

  EXIT_APP = 'exit-app',

  RESTART_APP = 'restart-app',
}

export enum MESSAGE_RESPONSE_KEYWORD {
  PERMISSION_DENIED = 'permission-denied',
}

export interface MessageRequest<
  TParams = {
    [key: string]: any;
  },
> {
  id: string;
  type: MESSAGE_KEYWORD;
  params?: TParams;
}

export interface MessageResponse<TData = unknown> extends MessageRequest {
  id: string;
  type: MESSAGE_KEYWORD;
  isSuccess: boolean;
  message: string;
  data?: TData;
}
