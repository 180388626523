import { createError, type ServerResponse } from '@wello-client/common/utils';
import axios from 'axios';

import { ENV } from '@/constants';

interface cancelConsultProps {
  id: number;
}

export const postJejuCancelConsult = async ({ id }: cancelConsultProps) => {
  try {
    const response = await axios.post<ServerResponse>(
      `${ENV.NEXT_PUBLIC_JEJU_API_DOMAIN}/api/wello/api103`,
      { id },
      {
        headers: {
          Authorization: `Basic ${ENV.NEXT_PUBLIC_JEJU_YOUTHDREAM_API_KEY}`,
        },
      },
    );

    return response.data.result_code;
  } catch (error) {
    throw createError();
  }
};
