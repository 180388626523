import {
  type PropsWithChildren,
  createContext,
  useContext,
  useMemo,
} from 'react';

import {
  useSubscribedState,
  type SubscribedState,
} from '@/hooks/useSubscribedState';

type Size = 'small' | 'medium' | 'large';
interface AccordionContextValue {
  openedState: SubscribedState<boolean>;
  size?: Size;
}

const AccordionContext = createContext<AccordionContextValue | undefined>(
  undefined,
);

export interface AccordionContextProviderProps {
  opened?: boolean;
  size?: Size;
  ellipsis?: boolean;
}

export const AccordionContextProvider = ({
  opened,
  size,
  children,
}: PropsWithChildren<AccordionContextProviderProps>) => {
  if (size === undefined || opened === undefined)
    throw new Error('size, opened must be defined.');

  const openedState = useSubscribedState(opened);

  const accordionContextValue = useMemo(
    () => ({
      openedState,
      size,
    }),
    [openedState, size],
  );

  return (
    <AccordionContext.Provider value={accordionContextValue}>
      {children}
    </AccordionContext.Provider>
  );
};

export const useAccordionContext = () => {
  const context = useContext(AccordionContext);

  if (context === undefined) {
    throw new Error(
      'useAccordionContext must be used within a AccordionContextProvider',
    );
  }

  return context;
};
