import type { DeviceTokenData } from '@/stores/DeviceStore';

import { useEffect, useState } from 'react';

import { useMutation } from '@tanstack/react-query';
import { useShallow } from 'zustand/react/shallow';

import { AUTH_API, UNAUTH_API } from '@/api';
import { useToast } from '@/hooks/useToast';
import { useAuthStore } from '@/stores/AuthStore';
import { useDeviceInfoStore } from '@/stores/DeviceStore';

import { MESSAGE_KEYWORD, useHandleApp } from '..';

export const useMountAppData = () => {
  const [
    appVersion,
    osType,
    setAppVersion,
    tempDeviceTokenData,
    setTempDeviceTokenData,
    deviceTokenData,
    setDeviceTokenData,
  ] = useDeviceInfoStore(
    useShallow((state) => [
      state.appVersion,
      state.osType,
      state.setAppVersion,
      state.tempDeviceTokenData,
      state.setTempDeviceTokenData,
      state.deviceTokenData,
      state.setDeviceTokenData,
    ]),
  );
  const { request: requestAppVersion, response: responseAppVersion } =
    useHandleApp<{
      version: string;
      isFirstLoading: 'Y' | 'N';
    }>({
      type: MESSAGE_KEYWORD.VERSION,
    });

  const { request: requestNotification, response: responseNotification } =
    useHandleApp<DeviceTokenData>({
      type: MESSAGE_KEYWORD.NOTIFICATION,
    });

  useEffect(() => {
    if (!osType) return;

    if (!appVersion) {
      requestAppVersion();
    }
  }, [appVersion, requestAppVersion, osType]);

  const [isFirstLoading, setIsFirstLoading] = useState<'Y' | 'N'>('Y');

  useEffect(() => {
    if (responseAppVersion?.isSuccess && responseAppVersion?.data) {
      const { version, isFirstLoading } = responseAppVersion.data;
      setAppVersion(version);
      setIsFirstLoading(isFirstLoading);
    }
  }, [responseAppVersion, setAppVersion, setIsFirstLoading]);

  useEffect(() => {
    if (!osType) return;

    if (!deviceTokenData && isFirstLoading === 'N') {
      requestNotification();
    }
  }, [deviceTokenData, requestNotification, isFirstLoading, osType]);

  const isLogin = useAuthStore((state) => state.isLogin);

  const { toastError } = useToast();

  const { mutate: saveDeviceTokenMutate } = useMutation({
    mutationFn: isLogin ? AUTH_API.saveDeviceToken : UNAUTH_API.saveDeviceToken,
    onSuccess: () => {
      if (tempDeviceTokenData) {
        setDeviceTokenData(tempDeviceTokenData);
      }
    },
    onError: (error) =>
      toastError({
        error,
      }),
  });

  useEffect(() => {
    if (!responseNotification || isLogin === undefined) return;

    const { isSuccess, data } = responseNotification;

    if (!isSuccess || !data) return;

    setTempDeviceTokenData(data);
  }, [
    isLogin,
    responseNotification,
    saveDeviceTokenMutate,
    setTempDeviceTokenData,
  ]);

  useEffect(() => {
    if (tempDeviceTokenData) {
      saveDeviceTokenMutate({
        requestBody: tempDeviceTokenData,
      });
    }
  }, [saveDeviceTokenMutate, tempDeviceTokenData]);
};
