import { useRef } from 'react';
import { XCircle, AlertTriangle, Info, Check } from 'react-feather';
import { toast as toastifyToast } from 'react-toastify';

import * as Sentry from '@sentry/nextjs';
import { isDev, type ServerResponse } from '@wello-client/common/utils';

import { useGlobalStore } from '@/stores/GlobalStore';

import styles from './useToast.module.scss';
interface ToastOptions {
  type?: 'fail' | 'success' | 'warning' | 'info';
  message: string;
  holdTime?: number;
}

interface ToastErrorOptions extends Omit<ToastOptions, 'message'> {
  error: unknown;
  exceptionOptions?: { [code: number]: string };
}

export const useToast = () => {
  const toast = ({
    message,
    holdTime = 5000,
    type = 'success',
  }: Omit<ToastOptions, 'toastKey' | 'createdAt'>) => {
    toastifyToast(message, {
      autoClose: holdTime,
      className: `${styles.toast} ${styles[type]}`,
      bodyClassName: styles['toast-body'],
      progressClassName: `${styles['progress-bar']} ${styles[type]}`,
      icon: {
        success: <Check className={styles['toast-icon']} />,
        fail: <XCircle className={styles['toast-icon']} />,
        warning: <AlertTriangle className={styles['toast-icon']} />,
        info: <Info className={styles['toast-icon']} />,
      }[type],
    });
  };

  const addLog = useGlobalStore((state) => state.addLog);

  const { current } = useRef({
    toast,
    toastError: ({
      error,
      type = 'fail',
      exceptionOptions,
      ...restOptions
    }: ToastErrorOptions) => {
      const { return_code: errorCode, return_message: errorMessage } =
        error as ServerResponse;

      interface BrowserError {
        name: string;
        message: string;
        stack: string;
      }

      addLog({
        data: JSON.stringify({
          name: (error as BrowserError).name,
          message: (error as BrowserError).message,
          stack: (error as BrowserError).stack,
          error,
        }),
        time: new Date(),
      });

      const toastErrorMessage = (message: string) =>
        toast({ ...restOptions, type, message });

      if (!errorCode || !errorMessage) {
        return toastErrorMessage(
          '서버로 부터 정상적인 응답을 받지 못했습니다.',
        );
      }

      const exceptedErrorMessage = exceptionOptions?.[errorCode];

      if (exceptedErrorMessage) {
        return toastErrorMessage(exceptedErrorMessage);
      }

      //TODO: 30000 이상 에러 메시지 백엔드와 논의 재필요
      if (30000 <= errorCode) {
        return toastErrorMessage(errorMessage);
      }

      //!: 해당 에러에 잡히면 예외처리 해줘야함 (Sentry에서 확인 가능)
      Sentry.captureException(error);

      return toastErrorMessage(
        `예상치 못한 오류가 발생했습니다.\n관리자에게 문의해주세요. (${errorCode})`,
      );
    },
    devToast: (message: string) => {
      if (isDev) {
        toast({ message, type: 'warning' });
      }
    },
  });

  return current;
};
