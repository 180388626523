/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { DefaultHttpResponse_DetailBestContentsResponse_ } from '../models/DefaultHttpResponse_DetailBestContentsResponse_';
import type { DefaultHttpResponse_DetailHometownNewsResponse_ } from '../models/DefaultHttpResponse_DetailHometownNewsResponse_';
import type { DefaultHttpResponse_DetailMetaPolicyAllowsResponse_ } from '../models/DefaultHttpResponse_DetailMetaPolicyAllowsResponse_';
import type { DefaultHttpResponse_LatestMetaPolicyResponse_ } from '../models/DefaultHttpResponse_LatestMetaPolicyResponse_';
import type { DefaultHttpResponse_ListBestContentsResponse_ } from '../models/DefaultHttpResponse_ListBestContentsResponse_';
import type { DefaultHttpResponse_ListHometownNewsResponse_ } from '../models/DefaultHttpResponse_ListHometownNewsResponse_';
import type { DefaultHttpResponse_ListMetaPolicyResponse_ } from '../models/DefaultHttpResponse_ListMetaPolicyResponse_';
import type { DefaultHttpResponse_ListWelloPolicyCodeAllResponse_ } from '../models/DefaultHttpResponse_ListWelloPolicyCodeAllResponse_';
import type { DefaultHttpResponse_ListWelloPolicyCodeResponse_ } from '../models/DefaultHttpResponse_ListWelloPolicyCodeResponse_';
import type { DefaultHttpResponse_MetaPolicyCountResponse_ } from '../models/DefaultHttpResponse_MetaPolicyCountResponse_';
import type { DefaultHttpResponse_MetaPolicyRankingResponse_ } from '../models/DefaultHttpResponse_MetaPolicyRankingResponse_';
import type { DefaultHttpResponse_ReverseGeocodingResponse_ } from '../models/DefaultHttpResponse_ReverseGeocodingResponse_';
import type { DefaultHttpResponse_WelloFeedbackResponse_ } from '../models/DefaultHttpResponse_WelloFeedbackResponse_';
import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';
export class Service {
    /**
     * 정책 소식 목록 조회
     * @returns DefaultHttpResponse_ListBestContentsResponse_ OK
     * @throws ApiError
     */
    public static bestContentsListUsingGet3({
        page,
        size,
        bestContentsSort,
        codeProvisionType,
        endAt,
        searchKeyword,
        startAt,
    }: {
        /**
         * 페이지
         */
        page: number,
        /**
         * 사이즈
         */
        size: number,
        /**
         * 정렬 순서(POPULARITY, LATEST)
         */
        bestContentsSort?: 'LATEST' | 'POPULARITY',
        /**
         * 지원유형[C09-XX]
         */
        codeProvisionType?: string,
        /**
         * 정책 종료 일시
         */
        endAt?: string,
        /**
         * 검색어
         */
        searchKeyword?: string,
        /**
         * 정책 시작 일시
         */
        startAt?: string,
    }): CancelablePromise<DefaultHttpResponse_ListBestContentsResponse_> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/wello/v2/allows/contents/best',
            query: {
                'best_contents_sort': bestContentsSort,
                'code_provision_type': codeProvisionType,
                'end_at': endAt,
                'page': page,
                'search_keyword': searchKeyword,
                'size': size,
                'start_at': startAt,
            },
            errors: {
                500: `SQL 오류 및 기타 미처리 예외 상황입니다.`,
                502: `외부 API 오류 입니다.`,
            },
        });
    }
    /**
     * 정책 소식 내 고잭정보 조회
     * @returns DefaultHttpResponse_WelloFeedbackResponse_ OK
     * @throws ApiError
     */
    public static bestContentsFeedbackUsingGet1({
        bestContentsId,
    }: {
        /**
         * 정책 소식 ID
         */
        bestContentsId: number,
    }): CancelablePromise<DefaultHttpResponse_WelloFeedbackResponse_> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/wello/v2/allows/contents/best/feedback/{best_contents_id}',
            path: {
                'best_contents_id': bestContentsId,
            },
            errors: {
                500: `SQL 오류 및 기타 미처리 예외 상황입니다.`,
                502: `외부 API 오류 입니다.`,
            },
        });
    }
    /**
     * 정책 소식 상세 조회
     * @returns DefaultHttpResponse_DetailBestContentsResponse_ OK
     * @throws ApiError
     */
    public static getBestContentsUsingGet({
        bestContentsIdIdx,
    }: {
        /**
         * 정책 소식 ID
         */
        bestContentsIdIdx: string,
    }): CancelablePromise<DefaultHttpResponse_DetailBestContentsResponse_> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/wello/v2/allows/contents/best/{best_contents_id_idx}',
            path: {
                'best_contents_id_idx': bestContentsIdIdx,
            },
            errors: {
                500: `SQL 오류 및 기타 미처리 예외 상황입니다.`,
                502: `외부 API 오류 입니다.`,
            },
        });
    }
    /**
     * 동네 소식 목록
     * @returns DefaultHttpResponse_ListHometownNewsResponse_ OK
     * @throws ApiError
     */
    public static hometownNewsListUsingGet2({
        page,
        size,
        codeRegion,
        codeSubRegion,
        hometownNewsSort,
        searchKeyword,
    }: {
        /**
         * 페이지
         */
        page: number,
        /**
         * 사이즈
         */
        size: number,
        /**
         * 코드_지역(시도)[C01-XX]
         */
        codeRegion?: string,
        /**
         * 코드_지역(시군구)[C01-XX-YY]
         */
        codeSubRegion?: string,
        /**
         * 정렬순서(LATEST,POPULARITY)
         */
        hometownNewsSort?: 'LATEST' | 'POPULARITY',
        /**
         * 검색어
         */
        searchKeyword?: string,
    }): CancelablePromise<DefaultHttpResponse_ListHometownNewsResponse_> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/wello/v2/allows/hometown-news',
            query: {
                'code_region': codeRegion,
                'code_sub_region': codeSubRegion,
                'hometown_news_sort': hometownNewsSort,
                'page': page,
                'search_keyword': searchKeyword,
                'size': size,
            },
            errors: {
                500: `SQL 오류 및 기타 미처리 예외 상황입니다.`,
                502: `외부 API 오류 입니다.`,
            },
        });
    }
    /**
     * 비로그인 멤버 동네 소식 좋아요&스크랩 정보 조회
     * @returns DefaultHttpResponse_WelloFeedbackResponse_ OK
     * @throws ApiError
     */
    public static hometownNewsFeedbackUsingGet1({
        hometownNewsId,
    }: {
        /**
         * 동네 소식 ID
         */
        hometownNewsId: number,
    }): CancelablePromise<DefaultHttpResponse_WelloFeedbackResponse_> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/wello/v2/allows/hometown-news/feedback/{hometown_news_id}',
            path: {
                'hometown_news_id': hometownNewsId,
            },
            errors: {
                500: `SQL 오류 및 기타 미처리 예외 상황입니다.`,
                502: `외부 API 오류 입니다.`,
            },
        });
    }
    /**
     * 동네 소식 상세
     * @returns DefaultHttpResponse_DetailHometownNewsResponse_ OK
     * @throws ApiError
     */
    public static hometownNewsUsingGet3({
        hometownNewsIdIdx,
    }: {
        /**
         * 동네 소식 id idx
         */
        hometownNewsIdIdx: string,
    }): CancelablePromise<DefaultHttpResponse_DetailHometownNewsResponse_> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/wello/v2/allows/hometown-news/{hometown_news_id_idx}',
            path: {
                'hometown_news_id_idx': hometownNewsIdIdx,
            },
            errors: {
                500: `SQL 오류 및 기타 미처리 예외 상황입니다.`,
                502: `외부 API 오류 입니다.`,
            },
        });
    }
    /**
     * 정책 검색목록 조회
     * 정책 검색목록을 조회합니다
     * @returns DefaultHttpResponse_ListMetaPolicyResponse_ OK
     * @throws ApiError
     */
    public static metaPolicyListUsingGet3({
        page,
        size,
        codeProvisionTypeList,
        codeRegion,
        codeSubRegion,
        descAgeList,
        policySort,
        searchKeyword,
    }: {
        /**
         * 페이지
         */
        page: number,
        /**
         * 페이지 사이즈
         */
        size: number,
        /**
         * 지원유형[C09-XX] 목록
         */
        codeProvisionTypeList?: Array<string>,
        /**
         * 메타코드-지역(시도)[C01-XX]
         */
        codeRegion?: string,
        /**
         * 메타코드-지역(시군구)[C01-XX-YY]
         */
        codeSubRegion?: string,
        /**
         * 지원대상 목록
         */
        descAgeList?: Array<string>,
        /**
         * 정렬순서(LATEST,POPULARITY)
         */
        policySort?: 'LATEST' | 'POPULARITY',
        /**
         * 검색어
         */
        searchKeyword?: string,
    }): CancelablePromise<DefaultHttpResponse_ListMetaPolicyResponse_> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/wello/v2/allows/policy',
            query: {
                'codeProvisionTypeList': codeProvisionTypeList,
                'codeRegion': codeRegion,
                'codeSubRegion': codeSubRegion,
                'descAgeList': descAgeList,
                'page': page,
                'policySort': policySort,
                'searchKeyword': searchKeyword,
                'size': size,
            },
            errors: {
                500: `SQL 오류 및 기타 미처리 예외 상황입니다.`,
                502: `외부 API 오류 입니다.`,
            },
        });
    }
    /**
     * 누적 정책 & 정보 제공기관 수 조회
     * 누적 정책 & 정보 제공기관 수를 조회합니다
     * @returns DefaultHttpResponse_MetaPolicyCountResponse_ OK
     * @throws ApiError
     */
    public static metaPolicyCountUsingGet1(): CancelablePromise<DefaultHttpResponse_MetaPolicyCountResponse_> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/wello/v2/allows/policy-count',
            errors: {
                500: `SQL 오류 및 기타 미처리 예외 상황입니다.`,
                502: `외부 API 오류 입니다.`,
            },
        });
    }
    /**
     * 비로그인 멤버 정책 좋아요&스크랩 정보 조회
     * @returns DefaultHttpResponse_WelloFeedbackResponse_ OK
     * @throws ApiError
     */
    public static metaPolicyFeedbackUsingGet1({
        policyId,
    }: {
        /**
         * 정책 ID
         */
        policyId: number,
    }): CancelablePromise<DefaultHttpResponse_WelloFeedbackResponse_> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/wello/v2/allows/policy/feedback/{policy_id}',
            path: {
                'policy_id': policyId,
            },
            errors: {
                500: `SQL 오류 및 기타 미처리 예외 상황입니다.`,
                502: `외부 API 오류 입니다.`,
            },
        });
    }
    /**
     * 신규정책 목록 조회
     * 신규정책 목록을 조회합니다
     * @returns DefaultHttpResponse_LatestMetaPolicyResponse_ OK
     * @throws ApiError
     */
    public static latestMetaPolicyListUsingGet1({
        codeProvisionType,
    }: {
        /**
         * 지원유형[C09-XX]
         */
        codeProvisionType?: string,
    }): CancelablePromise<DefaultHttpResponse_LatestMetaPolicyResponse_> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/wello/v2/allows/policy/latest',
            query: {
                'code_provision_type': codeProvisionType,
            },
            errors: {
                500: `SQL 오류 및 기타 미처리 예외 상황입니다.`,
                502: `외부 API 오류 입니다.`,
            },
        });
    }
    /**
     * 실시간 핫정책 TOP10 조회
     * 실시간 핫정책 TOP10을 조회합니다
     * @returns DefaultHttpResponse_MetaPolicyRankingResponse_ OK
     * @throws ApiError
     */
    public static metaPolicyTop10ListUsingGet1(): CancelablePromise<DefaultHttpResponse_MetaPolicyRankingResponse_> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/wello/v2/allows/policy/top10',
            errors: {
                500: `SQL 오류 및 기타 미처리 예외 상황입니다.`,
                502: `외부 API 오류 입니다.`,
            },
        });
    }
    /**
     * 비회원 정책 상세 조회
     * @returns DefaultHttpResponse_DetailMetaPolicyAllowsResponse_ OK
     * @throws ApiError
     */
    public static metaPolicyUsingGet3({
        metaPolicyIdIdx,
    }: {
        /**
         * 정책번호
         */
        metaPolicyIdIdx: string,
    }): CancelablePromise<DefaultHttpResponse_DetailMetaPolicyAllowsResponse_> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/wello/v2/allows/policy/{meta_policy_id_idx}',
            path: {
                'meta_policy_id_idx': metaPolicyIdIdx,
            },
            errors: {
                500: `SQL 오류 및 기타 미처리 예외 상황입니다.`,
                502: `외부 API 오류 입니다.`,
            },
        });
    }
    /**
     * 웰로 정책 코드 목록
     * @returns DefaultHttpResponse_ListWelloPolicyCodeResponse_ OK
     * @throws ApiError
     */
    public static welloPolicyCodeListUsingGet1({
        level,
        welloPolicyCodeTypeCd,
        keyList,
        userDisplayYn,
        welloPolicyCodeFilterCd,
    }: {
        /**
         * 차수
         */
        level: number,
        /**
         * 웰로 정책 코드 타입 - 코드(WELLO_POLICY_CODE_TYPE)
         */
        welloPolicyCodeTypeCd: string,
        /**
         * 검색 코드 목록(,)
         */
        keyList?: string,
        /**
         * 사용자 노출 여부
         */
        userDisplayYn?: boolean,
        /**
         * 웰로 정책 코드 필터 - 코드(WELLO_POLICY_CODE_FILTER)
         */
        welloPolicyCodeFilterCd?: string,
    }): CancelablePromise<DefaultHttpResponse_ListWelloPolicyCodeResponse_> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/wello/v2/allows/wello-policy-code',
            query: {
                'key_list': keyList,
                'level': level,
                'user_display_yn': userDisplayYn,
                'wello_policy_code_filter_cd': welloPolicyCodeFilterCd,
                'wello_policy_code_type_cd': welloPolicyCodeTypeCd,
            },
            errors: {
                500: `SQL 오류 및 기타 미처리 예외 상황입니다.`,
                502: `외부 API 오류 입니다.`,
            },
        });
    }
    /**
     * 웰로 정책 코드 전체 목록
     * @returns DefaultHttpResponse_ListWelloPolicyCodeAllResponse_ OK
     * @throws ApiError
     */
    public static welloPolicyCodeAllListUsingGet(): CancelablePromise<DefaultHttpResponse_ListWelloPolicyCodeAllResponse_> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/wello/v2/allows/wello-policy-code/all',
            errors: {
                500: `SQL 오류 및 기타 미처리 예외 상황입니다.`,
                502: `외부 API 오류 입니다.`,
            },
        });
    }
    /**
     * IP로 현재 위치의 지역정보 조회
     * @returns DefaultHttpResponse_ReverseGeocodingResponse_ OK
     * @throws ApiError
     */
    public static geolocationUsingGet1({
        ip,
    }: {
        /**
         * IP
         */
        ip: string,
    }): CancelablePromise<DefaultHttpResponse_ReverseGeocodingResponse_> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/wello/v2/allows/wello-policy-code/geolocation',
            query: {
                'ip': ip,
            },
            errors: {
                500: `SQL 오류 및 기타 미처리 예외 상황입니다.`,
                502: `외부 API 오류 입니다.`,
            },
        });
    }
    /**
     * 경도&위도로 현재 위치의 지역정보 조회
     * @returns DefaultHttpResponse_ReverseGeocodingResponse_ OK
     * @throws ApiError
     */
    public static reverseGeocodingUsingGet1({
        coords,
    }: {
        /**
         * 입력 좌표
         */
        coords: string,
    }): CancelablePromise<DefaultHttpResponse_ReverseGeocodingResponse_> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/wello/v2/allows/wello-policy-code/reverse-geocoding',
            query: {
                'coords': coords,
            },
            errors: {
                500: `SQL 오류 및 기타 미처리 예외 상황입니다.`,
                502: `외부 API 오류 입니다.`,
            },
        });
    }
}
