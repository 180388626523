import type { HTMLTagProps } from '@/types';

import { forwardRef, useState } from 'react';

import { cleanClassName } from '@wello-client/common/utils';

import { useSubscribedState } from '@/hooks/useSubscribedState';

import styles from './Input.module.scss';

export type InputType =
  | 'text'
  | 'number'
  | 'large-number'
  | 'phone-number'
  | 'text-date'
  | 'password'
  | 'button'
  | 'search';

export interface InputProps
  extends Omit<
    HTMLTagProps<'input'>,
    'type' | 'value' | 'width' | 'height' | 'size'
  > {
  type?: InputType;
  value?: number | string;
  ref?: React.ForwardedRef<HTMLInputElement>;
  textAlign?: 'left' | 'center' | 'right';
}

export const Input = forwardRef<HTMLInputElement, Omit<InputProps, 'ref'>>(
  (
    {
      //* Input props
      type = 'text',
      value,
      textAlign = 'left',

      //* HTML input props
      placeholder,
      className,
      onFocus,
      onBlur,
      onInvalid,
      onChange,
      ...restInputProps
    },
    ref,
  ) => {
    const [isFormattedValue, setIsFormattedValue] = useState(true);
    const [inputValue, setInputValue] = useSubscribedState(value);

    const formatValue = (value: typeof inputValue) => {
      if (type === 'button' && !value) return placeholder;

      if (value !== 0 && !value) return '';

      const valueString = String(value);

      if (!isFormattedValue) return valueString;

      switch (type) {
        case 'number':
          return valueString;

        case 'large-number':
          return Number(valueString).toLocaleString();

        case 'phone-number':
          if (valueString.length === 10)
            return valueString.replace(/(\d{3})(\d{3})(\d{4})/, '$1-$2-$3');

          return valueString.replace(/(\d{3})(\d{4})(\d{4})/, '$1-$2-$3');

        case 'text-date': {
          const textDate = valueString.replace(
            /(\d{4})(\d{2})(\d{2})/,
            '$1-$2-$3',
          );

          return isNaN(new Date(textDate).getTime()) ? valueString : textDate;
        }

        default:
          return valueString;
      }
    };

    const convertChangeHandlerParam = (value: string) => {
      switch (type) {
        case 'number':
        case 'large-number': {
          if (value === '') return value;
          const isValidNumber = /^[0-9]+$/.test(value);

          return isValidNumber ? value : inputValue;
        }

        case 'phone-number': {
          let numberString = value.replace(/[^0-9]/g, '');
          if (numberString.length > 11)
            numberString = numberString.slice(0, 11);

          return value && numberString;
        }

        case 'text-date': {
          let numberString = value.replace(/[^0-9]/g, '');
          if (numberString.length > 8) numberString = numberString.slice(0, 8);

          return value && numberString;
        }

        default:
          return value;
      }
    };

    return (
      <input
        {...restInputProps}
        ref={ref}
        className={cleanClassName(
          `${styles.input} ${type === 'button' && styles.button} ${
            !value && styles.empty
          } ${styles[textAlign]} ${className}`,
        )}
        placeholder={placeholder}
        type={type}
        value={formatValue(inputValue)}
        onBlur={(e) => {
          setIsFormattedValue(true);
          onBlur?.(e);
        }}
        onChange={(e) => {
          const convertedValue = convertChangeHandlerParam(e.target.value);

          if (convertedValue !== null) {
            setInputValue(convertedValue);
            onChange?.(e);
          }
        }}
        onFocus={(e) => {
          setIsFormattedValue(false);
          onFocus?.(e);
        }}
        onInvalid={(e) => {
          e.preventDefault();
          onInvalid?.(e);
        }}
      />
    );
  },
);

Input.displayName = 'Input';
