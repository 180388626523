import { useState } from 'react';
import { MoreVertical, ThumbsUp } from 'react-feather';

import { cleanClassName } from '@wello-client/common/utils';

import { Drawer, LazyImage } from '@/components/client';
import { Button } from '@/components/server';

import { Modal } from '../Modal/Modal';

import styles from './CommentCard.module.scss';

interface CommentCardProps {
  nickname?: string;
  createdAt?: string;
  profileImage?: string;
  thumbUpCount?: number;
  contents?: string;
  onClickThumbUpButton?: () => void;
  onClickReplyButton?: () => void;
  onClickDeleteButton?: () => void;
  onClickReportButton?: () => void;
  isDeleted?: boolean;
  isMyComment?: boolean;
  isMyThumbUp?: boolean;
  hasMenu?: boolean;
  maxDepth?: boolean;
}

export const CommentCard = ({
  nickname,
  createdAt,
  profileImage,
  contents,
  thumbUpCount = 0,
  onClickThumbUpButton,
  onClickReplyButton,
  onClickDeleteButton,
  onClickReportButton,
  isDeleted,
  isMyThumbUp,
  hasMenu,
  isMyComment,
  maxDepth = false,
}: CommentCardProps) => {
  const [isDrawerOpened, setIsDrawerOpened] = useState(false);
  const [isModalOpened, setIsModalOpened] = useState(false);

  const handleAction = () => {
    if (isMyComment) {
      onClickDeleteButton?.();
    } else {
      onClickReportButton?.();
    }

    handleCancel();
  };

  const handleCancel = () => {
    setIsModalOpened(false);
    setIsDrawerOpened(false);
  };

  return (
    <article className={styles['comment-card']}>
      <LazyImage className={styles['profile-image']} src={profileImage} />
      <section className={styles.comment}>
        <header className={styles.header}>
          <section className={styles['comment-info']}>
            <p className={styles.nickname}>{nickname}</p>
            <p className={styles['created-at']}>{createdAt}</p>
          </section>
          {hasMenu ? (
            <button
              className={styles['menu-button']}
              onClick={() => setIsDrawerOpened(true)}
            >
              <MoreVertical className={styles['menu-icon']} />
            </button>
          ) : null}
        </header>
        <section
          className={cleanClassName(
            `${styles['comment-contents']} ${isDeleted && styles.deleted}`,
          )}
        >
          {isDeleted ? '작성자에 의해 삭제된 댓글입니다.' : contents}
        </section>
        {isDeleted ? null : (
          <footer className={styles.footer}>
            <Button
              aria-label="좋아요"
              className={styles['thumb-up-button']}
              theme="unset"
              onClick={onClickThumbUpButton}
            >
              <ThumbsUp
                className={cleanClassName(
                  `${styles['thumb-up-icon']} ${isMyThumbUp && styles.on}`,
                )}
              />
              좋아요 {thumbUpCount}
            </Button>
            {!maxDepth ? (
              <Button
                aria-label="답글"
                className={styles['reply-button']}
                theme="unset"
                onClick={onClickReplyButton}
              >
                답글쓰기
              </Button>
            ) : null}
          </footer>
        )}
      </section>
      <Drawer opened={isDrawerOpened} onClose={() => setIsDrawerOpened(false)}>
        <button
          aria-label={isMyComment ? '삭제하기' : '신고하기'}
          className={styles.button}
          onClick={() => setIsModalOpened(true)}
        >
          {isMyComment ? '삭제하기' : '신고하기'}
        </button>
        <Modal
          className={styles.modal}
          opened={isModalOpened}
          onClose={() => setIsModalOpened(false)}
        >
          <h2 className={styles.title}>알림</h2>
          {isMyComment ? (
            <>
              삭제한 댓글은 복구할 수 없습니다.
              <br />
              그래도 삭제하시겠습니까?
            </>
          ) : (
            <>
              이 댓글을 신고 하시겠습니까?
              <br />
              댓글 검토 후 신고가 완료 됩니다
            </>
          )}
          <div className={styles['modal-footer']}>
            <Button
              shape="capsule"
              size="medium"
              theme="sub-outline"
              onClick={handleCancel}
            >
              취소
            </Button>
            <Button shape="capsule" size="medium" onClick={handleAction}>
              {isMyComment ? '삭제' : '신고'}
            </Button>
          </div>
        </Modal>
      </Drawer>
    </article>
  );
};
