/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { DefaultHttpResponseListWelloPolicyApplyHisResponse } from '../models/DefaultHttpResponseListWelloPolicyApplyHisResponse';
import type { DefaultHttpResponseVoid } from '../models/DefaultHttpResponseVoid';
import type { SendJejuYouthDreamRequest } from '../models/SendJejuYouthDreamRequest';
import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';
export class ApiService {
    /**
     * 제주청년드림 상담 신청 등록
     * @returns DefaultHttpResponseVoid OK
     * @throws ApiError
     */
    public static sendJejuYouthDreamService({
        memberOAuthId,
        policyApplyId,
        requestBody,
    }: {
        /**
         * 멤버 ID
         */
        memberOAuthId: number,
        /**
         * 정책신청 ID
         */
        policyApplyId: number,
        requestBody: SendJejuYouthDreamRequest,
    }): CancelablePromise<DefaultHttpResponseVoid> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/wello/v1/allows/jeju-youth-dream',
            headers: {
                'memberOAuthId': memberOAuthId,
                'policyApplyId': policyApplyId,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                401: `Unauthorized`,
                500: `Internal Server Error`,
                502: `Bad Gateway`,
            },
        });
    }
    /**
     * 정책 신청 목록 API
     * @returns DefaultHttpResponseListWelloPolicyApplyHisResponse OK
     * @throws ApiError
     */
    public static getWelloPolicyApplyHisList({
        memberOAuthId,
    }: {
        memberOAuthId: number,
    }): CancelablePromise<DefaultHttpResponseListWelloPolicyApplyHisResponse> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/wello/v1/allows/policy-apply-his/{memberOAuthId}',
            path: {
                'memberOAuthId': memberOAuthId,
            },
            errors: {
                401: `Unauthorized`,
                500: `Internal Server Error`,
                502: `Bad Gateway`,
            },
        });
    }
}
