import type { HTMLTagProps, Size } from '@/types';

import { cleanClassName } from '@wello-client/common/utils';

import { useSubscribedState } from '@/hooks/useSubscribedState';

import styles from './Chip.module.scss';

export interface ChipProps
  extends Pick<HTMLTagProps<'div'>, 'className' | 'style'>,
    Omit<
      HTMLTagProps<'input'>,
      'size' | 'type' | 'style' | 'value' | 'children'
    > {
  children?: React.ReactNode;
  size?: Size;
  shape?: 'rectangle' | 'capsule';
  clickable?: boolean;
}

export const Chip = ({
  //* Chip props
  children,
  size = 'medium',
  shape = 'capsule',
  clickable = true,

  //* HTML div props
  className,
  style,

  //* HTML input props
  checked = false,
  onChange,
  disabled,
  ...restInputProps
}: ChipProps) => {
  const [turned, setTurned] = useSubscribedState(checked);

  return (
    <label
      className={cleanClassName(
        `${styles.chip} ${styles[size]} ${turned ? styles.on : styles.off} ${
          disabled && styles.disabled
        } ${styles[shape]} ${className}`,
      )}
      style={style}
    >
      {children}
      <input
        {...restInputProps}
        checked={turned}
        className={styles['chip-input']}
        disabled={disabled}
        type="checkbox"
        onChange={(e) => {
          if (!clickable) {
            e.preventDefault();

            return;
          }

          if (onChange) {
            onChange(e);
          } else {
            setTurned(e.target.checked);
          }
        }}
      />
    </label>
  );
};
