import { createUuid } from '@wello-client/common/utils';

export const queryKeys = {
  loginToken: createUuid(),
  unifiedAccount: createUuid(),
  latestPolicyList: createUuid(),
  recommendPolicyList: createUuid(),
  policyCodeList: createUuid(),
  policyFeedback: createUuid(),
  policyInfoFeedback: createUuid(),
  policyDetail: createUuid(),
  hometownNewsList: createUuid(),
  hometownNewsFeedback: createUuid(),
  myGeolocation: createUuid(),
  profileScrap: createUuid(),
  familyList: createUuid(),
  inquiryList: createUuid(),
  inquiry: createUuid(),
  pickPolicyList: createUuid(),
  situationPolicyList: createUuid(),
  mainPage: createUuid(),
  liveComment: createUuid(),
  myCustomPolicyFilter: createUuid(),
  myInterestedPolicyFilter: createUuid(),
  appPushList: createUuid(),
  commentList: createUuid(),
  policyCount: createUuid(),
};
