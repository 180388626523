import { type Faker, fakerKO } from '@faker-js/faker';

export const createFakerList = <T>(
  count: number,
  factory: (faker: Faker) => T,
) => {
  return Array.from({ length: count }, () => factory(fakerKO));
};

export { fakerKO as faker };
