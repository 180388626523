export const KbGtmNoScript = () => (
  <noscript>
    <iframe
      height="0"
      src="https://www.googletagmanager.com/ns.html?id=GTM-NZNZZLF2"
      style={{ display: 'none', visibility: 'hidden' }}
      title="GTM"
      width="0"
    />
  </noscript>
);
