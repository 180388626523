import type { CardUserActionFooterProps } from '@/components/server';

import { cleanClassName } from '@wello-client/common/utils';

import { LazyImage } from '@/components/client';
import { Badge, LinkableBox } from '@/components/server';

import { CardUserActionFooter } from '../Footer/CardUserActionFooter';

import styles from './PolicyInfoLargeCard.module.scss';

export interface PolicyInfoLargeCardProps extends CardUserActionFooterProps {
  thumbnail?: string;
  title?: string;
  badgeList?: string[];
  createdAt?: string;
  period?: string;
  hasAnimation?: boolean;
  href?: string;
  isLoading?: boolean;
  onClick?: () => void;
}

export const PolicyInfoLargeCard = ({
  thumbnail,
  title,
  badgeList,
  createdAt,
  period,
  hasAnimation,
  href,
  isLoading = false,
  onClick,
  ...cardUserActionProps
}: PolicyInfoLargeCardProps) => {
  return (
    <article
      className={cleanClassName(
        `${styles.card} ${hasAnimation && styles.animated}`,
      )}
    >
      <LinkableBox className={styles['link-box']} href={href} onClick={onClick}>
        <LazyImage
          className={styles.thumbnail}
          isLoading={isLoading}
          src={thumbnail}
        />
        <section className={styles['info-container']}>
          <ul className={styles['badge-list']}>
            {badgeList?.map((badge, index) => (
              <Badge key={index}>{badge}</Badge>
            ))}
          </ul>
          <h1 className={styles.title}>{title}</h1>
          {period ? (
            <p className={styles['date-text']}>
              <span>신청일 | </span>
              {period}
            </p>
          ) : null}
          <p className={styles['date-text']}>
            <span>작성일 | </span>
            {createdAt}
          </p>
        </section>
      </LinkableBox>
      <CardUserActionFooter {...cardUserActionProps} />
    </article>
  );
};
