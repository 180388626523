export const timeAgo = (dateString?: string | null): string => {
  if (!dateString) {
    return '';
  }

  const isoRegex = /^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}$/;
  const standardRegex = /^\d{4}-\d{2}-\d{2} \d{2}:\d{2}$/;

  if (isoRegex.test(dateString)) {
    return dateString.replace('T', ' ').substring(0, 16);
  } else if (!standardRegex.test(dateString)) {
    throw new Error(
      'Invalid date format. Expected format: YYYY-MM-DD HH:MM or iso',
    );
  }

  const now = new Date();
  const past = new Date(dateString);
  const diff = now.getTime() - past.getTime();

  const seconds = Math.floor(diff / 1000);
  const minutes = Math.floor(seconds / 60);
  const hours = Math.floor(minutes / 60);
  const days = Math.floor(hours / 24);
  const weeks = Math.floor(days / 7);
  const months =
    now.getMonth() -
    past.getMonth() +
    12 * (now.getFullYear() - past.getFullYear());

  if (seconds < 60) {
    return `${seconds}초 전`;
  } else if (minutes < 60) {
    return `${minutes}분 전`;
  } else if (hours < 24) {
    return `${hours}시간 전`;
  } else if (days < 7) {
    return `${days}일 전`;
  } else if (weeks < 4) {
    return `${weeks}주 전`;
  } else if (months < 12) {
    return `${months}달 전`;
  }

  return `${past.getFullYear()}년 ${past.getMonth() + 1}월 ${past.getDate()}일`;
};
