/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { DefaultHttpResponse_ListS3FileUploadResponse_ } from '../models/DefaultHttpResponse_ListS3FileUploadResponse_';
import type { DefaultHttpResponse_object_ } from '../models/DefaultHttpResponse_object_';
import type { DefaultHttpResponse_S3FileCopyResponse_ } from '../models/DefaultHttpResponse_S3FileCopyResponse_';
import type { DefaultHttpResponse_S3FileUploadResponse_ } from '../models/DefaultHttpResponse_S3FileUploadResponse_';
import type { FileHttpResponse } from '../models/FileHttpResponse';
import type { S3FileCopyRequest } from '../models/S3FileCopyRequest';
import type { S3FileDeleteRequest } from '../models/S3FileDeleteRequest';
import type { S3FileDownloadRequest } from '../models/S3FileDownloadRequest';
import type { S3FileRenameRequest } from '../models/S3FileRenameRequest';
import type { StreamingResponseBody } from '../models/StreamingResponseBody';
import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';
export class S3FileApiService {
    /**
     * 파일 템프 저장 여부 상태 수정
     * @returns FileHttpResponse OK
     * @throws ApiError
     */
    public static updateFileTempSaveYnUsingPut2({
        requestBody,
    }: {
        requestBody?: Record<string, Array<string>>,
    }): CancelablePromise<FileHttpResponse> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/wello/v2/auth/s3',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                500: `SQL 오류 및 기타 미처리 예외 상황입니다.`,
                502: `외부 API 오류 입니다.`,
            },
        });
    }
    /**
     * 파일 업로드
     * @returns DefaultHttpResponse_ListS3FileUploadResponse_ OK
     * @throws ApiError
     */
    public static fileUploadUsingPost2({
        formData,
    }: {
        formData?: {
            /**
             * 파일
             */
            fileUrlList: Array<Blob>;
            /**
             * S3 cdn 타입(부모:S3_CDN_TYPE), default = S3_CDN_TYPE_WELFAREHELLO
             */
            s3CdnTypeCd: string;
            /**
             * 파일저장경로
             */
            type: string;
        },
    }): CancelablePromise<DefaultHttpResponse_ListS3FileUploadResponse_> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/wello/v2/auth/s3',
            formData: formData,
            mediaType: 'multipart/form-data',
            errors: {
                500: `SQL 오류 및 기타 미처리 예외 상황입니다.`,
                502: `외부 API 오류 입니다.`,
            },
        });
    }
    /**
     * 파일 삭제
     * @returns DefaultHttpResponse_object_ OK
     * @throws ApiError
     */
    public static fileDeleteUsingDelete2({
        requestBody,
    }: {
        requestBody?: S3FileDeleteRequest,
    }): CancelablePromise<DefaultHttpResponse_object_> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/wello/v2/auth/s3',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                500: `SQL 오류 및 기타 미처리 예외 상황입니다.`,
                502: `외부 API 오류 입니다.`,
            },
        });
    }
    /**
     * 파일 복사
     * @returns DefaultHttpResponse_S3FileCopyResponse_ OK
     * @throws ApiError
     */
    public static fileCopyUsingPost1({
        requestBody,
    }: {
        requestBody?: S3FileCopyRequest,
    }): CancelablePromise<DefaultHttpResponse_S3FileCopyResponse_> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/wello/v2/auth/s3/copy',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                500: `SQL 오류 및 기타 미처리 예외 상황입니다.`,
                502: `외부 API 오류 입니다.`,
            },
        });
    }
    /**
     * 파일 복호화 다운로드
     * @returns StreamingResponseBody OK
     * @throws ApiError
     */
    public static fileDecryptionDownloadUsingGet2({
        fileUrl,
        originName,
    }: {
        /**
         * 파일 url
         */
        fileUrl: string,
        /**
         * 파일 명
         */
        originName: string,
    }): CancelablePromise<StreamingResponseBody> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/wello/v2/auth/s3/decryption',
            query: {
                'file_url': fileUrl,
                'origin_name': originName,
            },
            errors: {
                500: `SQL 오류 및 기타 미처리 예외 상황입니다.`,
                502: `외부 API 오류 입니다.`,
            },
        });
    }
    /**
     * 파일 복호화 다운로드 & 워터마크 추가
     * @returns any OK
     * @throws ApiError
     */
    public static fileDecryptionDownloadWriteWatermarkUsingPost1({
        companyId,
        requestBody,
    }: {
        /**
         * company_id
         */
        companyId: number,
        requestBody?: S3FileDownloadRequest,
    }): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/wello/v2/auth/s3/decryption/watermark',
            headers: {
                'company_id': companyId,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                500: `SQL 오류 및 기타 미처리 예외 상황입니다.`,
                502: `외부 API 오류 입니다.`,
            },
        });
    }
    /**
     * 경로변수 파일 업로드
     * @returns DefaultHttpResponse_ListS3FileUploadResponse_ OK
     * @throws ApiError
     */
    public static pathVariableFileUploadUsingPost({
        companyId,
        formData,
    }: {
        /**
         * company_id
         */
        companyId?: number,
        formData?: {
            /**
             * 파일
             */
            fileUrlList: Array<Blob>;
            /**
             * S3 cdn 타입(부모:S3_CDN_TYPE), default = S3_CDN_TYPE_WELFAREHELLO
             */
            s3CdnTypeCd: string;
            /**
             * 파일저장경로
             */
            type: string;
        },
    }): CancelablePromise<DefaultHttpResponse_ListS3FileUploadResponse_> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/wello/v2/auth/s3/path-variable',
            headers: {
                'company_id': companyId,
            },
            formData: formData,
            mediaType: 'multipart/form-data',
            errors: {
                500: `SQL 오류 및 기타 미처리 예외 상황입니다.`,
                502: `외부 API 오류 입니다.`,
            },
        });
    }
    /**
     * 파일 재 업로드
     * @returns DefaultHttpResponse_S3FileUploadResponse_ OK
     * @throws ApiError
     */
    public static fileReUploadUsingPost1({
        formData,
    }: {
        formData?: {
            /**
             * 파일
             */
            file: Blob;
            /**
             * 파일 web
             */
            web: string;
        },
    }): CancelablePromise<DefaultHttpResponse_S3FileUploadResponse_> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/wello/v2/auth/s3/re',
            formData: formData,
            mediaType: 'multipart/form-data',
            errors: {
                500: `SQL 오류 및 기타 미처리 예외 상황입니다.`,
                502: `외부 API 오류 입니다.`,
            },
        });
    }
    /**
     * 파일 이름 변경
     * @returns DefaultHttpResponse_S3FileUploadResponse_ OK
     * @throws ApiError
     */
    public static fileRenameUsingPut1({
        requestBody,
    }: {
        requestBody?: S3FileRenameRequest,
    }): CancelablePromise<DefaultHttpResponse_S3FileUploadResponse_> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/wello/v2/auth/s3/rename',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                500: `SQL 오류 및 기타 미처리 예외 상황입니다.`,
                502: `외부 API 오류 입니다.`,
            },
        });
    }
}
