/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { DefaultHttpResponse_Void_ } from '../models/DefaultHttpResponse_Void_';
import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';
export class NiceService {
    /**
     * Nice 신용인증 성공시 이동될 URL
     * @returns DefaultHttpResponse_Void_ OK
     * @throws ApiError
     */
    public static checkPlusSuccessUsingGet({
        encodeData,
    }: {
        /**
         * EncodeData
         */
        encodeData: string,
    }): CancelablePromise<DefaultHttpResponse_Void_> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/wello/v2/allows/nice-safe-key/check-plus_success',
            query: {
                'EncodeData': encodeData,
            },
            errors: {
                500: `SQL 오류 및 기타 미처리 예외 상황입니다.`,
                502: `외부 API 오류 입니다.`,
            },
        });
    }
}
