import type { TextareaProps } from '@/components/client';

import { forwardRef, useState } from 'react';
import { Send, ThumbsUp } from 'react-feather';

import { cleanClassName } from '@wello-client/common/utils';

import { Textarea } from '@/components/client';
import { Button } from '@/components/server';

import styles from './CommentBar.module.scss';

interface CommentBarProps extends Pick<TextareaProps, 'onFocus'> {
  isThumbUp?: boolean;
  onClickSend?: (value: string) => void;
  isLoading?: boolean;
  thumbUpButton?: boolean;
  thumbUpCount?: number;
  onClickThumbUp?: () => void;
  inputMode?:
    | 'none'
    | 'search'
    | 'text'
    | 'tel'
    | 'url'
    | 'email'
    | 'numeric'
    | 'decimal'
    | undefined;
}

export const CommentBar = forwardRef<HTMLTextAreaElement, CommentBarProps>(
  (
    {
      isThumbUp,
      onClickSend,
      isLoading,
      onFocus,
      thumbUpButton = true,
      thumbUpCount = 0,
      onClickThumbUp,
      inputMode = 'text',
    },
    ref,
  ) => {
    const [value, setValue] = useState('');

    return (
      <div className={styles['comment-bar']}>
        {thumbUpButton ? (
          <Button
            className={styles['thumb-up-button']}
            theme="unset"
            onClick={() => {
              if (isLoading) return;
              onClickThumbUp?.();
            }}
          >
            <ThumbsUp
              className={cleanClassName(
                `${styles.icon} ${isThumbUp && styles.on}`,
              )}
            />
            {thumbUpCount}
          </Button>
        ) : null}
        <Textarea
          ref={ref}
          className={styles.text}
          decorationAlign="bottom"
          inputMode={inputMode}
          maxLength={100}
          placeholder="댓글을 입력해주세요."
          size="medium"
          textMaxHeight="6em"
          value={value}
          onChange={({ target: { value } }) => setValue(value)}
          onFocus={onFocus}
        >
          <button
            className={styles['send-button']}
            onClick={async () => {
              if (isLoading || !value) return;
              onClickSend?.(value);
              setValue('');
            }}
          >
            <Send className={styles['send-icon']} />
          </button>
        </Textarea>
        <div className={styles['limit_content']}>{value.length} / 100</div>
      </div>
    );
  },
);

CommentBar.displayName = 'CommentBar';
