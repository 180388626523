import { createError, type ServerResponse } from '@wello-client/common/utils';
import axios from 'axios';

import { ENV } from '@/constants';

interface LabeledCode {
  code: string;
  value: string;
}

type CommonFields =
  | 'userParticipationTypes'
  | 'userInflowTypes'
  | 'youthPolicySupportCategoryTypes'
  | 'adviceSpaceTypes'
  | 'userWorkTypeTypes'
  | 'adviceEducationTypes'
  | 'adviceParticipationTypes'
  | 'adviceResidenceTypes'
  | 'userImmigrantPeriodTypes'
  | 'userImmigrantTypes'
  | 'userChildTypes'
  | 'adviceWelfareTypes'
  | 'adviceCultureTypes'
  | 'userEducationTypes'
  | 'adviceFinanceTypes'
  | 'adviceWorkTypes'
  | 'userMarriageTypes';

interface CommonApiResponse extends ServerResponse {
  context: Record<CommonFields, LabeledCode[]>;
}

export const fetchJejuCommon = async () => {
  try {
    const response = await axios.get<CommonApiResponse>(
      `${ENV.NEXT_PUBLIC_JEJU_API_DOMAIN}/api/wello/api000`,
      {
        headers: {
          Authorization: `Basic ${ENV.NEXT_PUBLIC_JEJU_YOUTHDREAM_API_KEY}`,
        },
      },
    );

    return response.data.context;
  } catch (error) {
    throw createError();
  }
};
