/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { DefaultHttpResponse_ListFaqResponse_ } from '../models/DefaultHttpResponse_ListFaqResponse_';
import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';
export class FaqApiService {
    /**
     * FAQ 목록 조회
     * FAQ 목록을 조회합니다
     * @returns DefaultHttpResponse_ListFaqResponse_ OK
     * @throws ApiError
     */
    public static faqListUsingGet1(): CancelablePromise<DefaultHttpResponse_ListFaqResponse_> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/wello/v2/allows/cs/faq',
            errors: {
                500: `SQL 오류 및 기타 미처리 예외 상황입니다.`,
                502: `외부 API 오류 입니다.`,
            },
        });
    }
}
