import type { BANNER_CONTENTS_TYPE } from './types';

import {
  ApiService as CommonAuthApiService,
  OpenAPI as CommonAuthOpenApi,
  Service as CommonAuthService,
} from '@wello-client/common/generated/api/auth';
import {
  MemberOauthApiService as CommonMemberOauthApiService,
  ApiService as CommonUnauthApiService,
  OpenAPI as CommonUnauthOpenApi,
} from '@wello-client/common/generated/api/unauth';
import { normalizeServerRequest } from '@wello-client/common/utils';

import {
  NiceService as AuthNiceService,
  ApiService as WelloAuthApiService,
  OpenAPI as WelloAuthOpenApi,
  Service as WelloAuthService,
  KbPayApiService as KbPayAuthApiService,
} from '@generated/api/auth';
import {
  Service as DpgService,
  ApiService as DpgApiService,
} from '@generated/api/dpg';
import {
  NiceService as UnauthNiceService,
  FaqApiService,
  WelloSeoService,
  ApiService as WelloUnauthApiService,
  OpenAPI as WelloUnauthOpenApi,
  Service as WelloUnauthService,
  KbPayApiService as KbPayUnauthApiService,
} from '@generated/api/unauth';

import {
  fetchJejuApplyList,
  fetchJejuCommon,
  fetchJejuSchedule,
  fetchJejuTermList,
  postJejuApply,
  postJejuCancelConsult,
} from './jeju-youthdream';

const COMMON_API_DOMAIN = process.env.NEXT_PUBLIC_COMMON_API_DOMAIN;
if (!COMMON_API_DOMAIN) throw new Error('COMMON_API_DOMAIN is not defined');

CommonAuthOpenApi.BASE = COMMON_API_DOMAIN;
CommonUnauthOpenApi.BASE = COMMON_API_DOMAIN;

const AUTH_API_DOMAIN = process.env.NEXT_PUBLIC_API_DOMAIN;
if (!AUTH_API_DOMAIN) throw new Error('AUTH_API_DOMAIN is not defined');

WelloAuthOpenApi.BASE = AUTH_API_DOMAIN;
WelloUnauthOpenApi.BASE = AUTH_API_DOMAIN;

/** 인증이 필요하지 않은 API */
export const UNAUTH_API = {
  getBannerList: normalizeServerRequest(
    (contentsTypeCd: BANNER_CONTENTS_TYPE) =>
      WelloUnauthApiService.bannerListUsingGet1({
        contentsTypeCd,
      }),
  ),

  //* 로그인 관련 API
  getUnifiedAccount: normalizeServerRequest(
    CommonMemberOauthApiService.welloBeforeLoginUsingPost1,
  ),
  getAccessToken: normalizeServerRequest(
    CommonMemberOauthApiService.loginWelloSnsMemberUsingPost1,
  ),
  getRefreshToken: normalizeServerRequest(
    CommonMemberOauthApiService.snsRefreshTokenUsingGet1,
  ),
  getKbMemberLoginType: normalizeServerRequest(
    CommonMemberOauthApiService.getKbMemberLoginTypeListUsingGet,
  ),

  //* 정책 소식 관련 API
  getPolicyInfoList: normalizeServerRequest(
    WelloUnauthService.bestContentsListUsingGet3,
  ),
  getPolicyInfoDetail: normalizeServerRequest(
    WelloUnauthService.getBestContentsUsingGet,
  ),
  getPolicyInfoFeedback: normalizeServerRequest(
    WelloUnauthService.bestContentsFeedbackUsingGet1,
  ),

  //* 대상별 정책 API
  getWelloPickList: normalizeServerRequest(
    WelloUnauthApiService.welloPickListUsingGet3,
  ),

  getPickPolicyList: normalizeServerRequest(
    WelloUnauthApiService.welloPickPolicyListUsingGet3,
  ),

  //* 정책 관련 API
  getPolicyTop10List: normalizeServerRequest(
    WelloUnauthService.metaPolicyTop10ListUsingGet1,
  ),
  getPolicyDetail: normalizeServerRequest(
    WelloUnauthService.metaPolicyUsingGet3,
  ),
  getPolicyFeedback: normalizeServerRequest(
    WelloUnauthService.metaPolicyFeedbackUsingGet1,
  ),
  getPolicyCount: normalizeServerRequest(
    WelloUnauthService.metaPolicyCountUsingGet1,
  ),

  //* 정책코드 관련 API
  getWelloPolicyCodeList: normalizeServerRequest(
    WelloUnauthService.welloPolicyCodeListUsingGet1,
  ),

  getWelloPolicyCodeAllList: normalizeServerRequest(
    WelloUnauthService.welloPolicyCodeAllListUsingGet,
  ),

  //* 정책 꾸러미 API
  getSituationList: normalizeServerRequest(
    WelloUnauthApiService.situationListUsingGet3,
  ),

  getSituationPolicyList: normalizeServerRequest(
    WelloUnauthApiService.getSituationPolicyListUsingGet3,
  ),

  //* 댓글 관련 API
  getCommentList: normalizeServerRequest(
    WelloUnauthApiService.getMetaPolicyCommentListUsingGet1,
  ),

  getGeolocation: normalizeServerRequest(
    WelloUnauthService.geolocationUsingGet1,
  ),
  getGeocoding: normalizeServerRequest(
    WelloUnauthService.reverseGeocodingUsingGet1,
  ),

  getLiveCommentList: normalizeServerRequest(
    WelloUnauthApiService.getLiveWelloCommentListUsingGet3,
  ),

  //* 동네 소식 콘텐츠 관련 API
  getHometownList: normalizeServerRequest(
    WelloUnauthService.hometownNewsListUsingGet2,
  ),
  getHometownNewsDetail: normalizeServerRequest(
    WelloUnauthService.hometownNewsUsingGet3,
  ),
  getHometownNewsFeedback: normalizeServerRequest(
    WelloUnauthService.hometownNewsFeedbackUsingGet1,
  ),

  //* 메타코드 관련 API
  getMetaCode: normalizeServerRequest(
    WelloUnauthApiService.metaCodeAllUsingGet1,
  ),

  //* FAQ 관련 API
  getFaq: normalizeServerRequest(FaqApiService.faqListUsingGet1),

  //* 공지사항 관련 API
  getNotice: normalizeServerRequest(WelloUnauthApiService.noticeListUsingGet2),
  getNoticeDetail: normalizeServerRequest(
    WelloUnauthApiService.noticeUsingGet1,
  ),

  //* 웰로 멤버 API
  getNickname: normalizeServerRequest(
    WelloUnauthApiService.noticeListUsingGet1,
  ),

  //* 약관 API
  getTermsList: normalizeServerRequest(
    CommonUnauthApiService.termHistoryUsingGet1,
  ),

  //* 푸시 API
  getAppPushCount: normalizeServerRequest(
    WelloUnauthApiService.newWelloAppPushCntUsingGet1,
  ),
  getAppPushList: normalizeServerRequest(
    WelloUnauthApiService.welloAppPushListUsingGet1,
  ),
  putAppPush: normalizeServerRequest(
    WelloUnauthApiService.updateWelloAppPushReadUsingPut3,
  ),

  //*SEO 관련 API
  getSeoList: normalizeServerRequest(
    WelloSeoService.metaPolicyWishlistUsingGet2,
  ),
  getSeoTotalCount: normalizeServerRequest(
    WelloSeoService.metaPolicyWishlistUsingGet3,
  ),

  //* 검색 관련 API
  getSearchRecommendPolicyList: normalizeServerRequest(
    WelloUnauthService.metaPolicyListUsingGet3,
  ),

  //* Push 설정
  saveDeviceToken: normalizeServerRequest(
    WelloUnauthApiService.saveAllowsWelloDeviceInfoUsingPost1,
  ),

  //* NICE API
  checkNiceSuccess: normalizeServerRequest(
    UnauthNiceService.checkPlusSuccessUsingGet,
  ),

  checkAccessTokenStatus: normalizeServerRequest(
    CommonMemberOauthApiService.tokenStatusCheckUsingPost,
  ),

  //* KB JOIN EVENT
  joinKbEventPost: normalizeServerRequest(
    KbPayUnauthApiService.joinEventUsingPost,
  ),
};

/** 인증이 필요한 API */
export const AUTH_API = {
  //* 로그인 관련 API
  getMyInfo: normalizeServerRequest(WelloAuthApiService.memberUsingGet1),
  getSocialAccounts: normalizeServerRequest(
    WelloAuthApiService.memberSocialAccountsUsingGet1,
  ),
  deleteSocialAccounts: normalizeServerRequest(
    WelloAuthApiService.deleteMemberSocialAccountsUsingDelete1,
  ),
  postImageUpload: normalizeServerRequest(
    WelloAuthApiService.fileUploadUsingPost1,
  ),
  putProfileChange: normalizeServerRequest(
    WelloAuthApiService.updateMemberNickNameUsingPut1,
  ),
  putMessageReceive: normalizeServerRequest(
    WelloAuthApiService.messageReceiveUsingPut1,
  ),
  postMemberVerification: normalizeServerRequest(
    CommonAuthService.decryptMemberVerificationUsingPost,
  ),
  putVerification: normalizeServerRequest(
    WelloAuthApiService.updateMemberVerificationUsingPut,
  ),

  resignMember: normalizeServerRequest(
    WelloAuthApiService.resignMemberUsingDelete1,
  ),

  //* CI 정보 관련 API
  getMemberVerification: normalizeServerRequest(
    WelloAuthService.getLatestCompletedCiUsingPost,
  ),

  logout: normalizeServerRequest(CommonAuthApiService.logoutMemberUsingGet1),

  //* 푸시 관련 API
  getAppPush: normalizeServerRequest(
    WelloAuthApiService.getWebWelloAppPushListUsingGet,
  ),
  putAppPush: normalizeServerRequest(
    WelloAuthApiService.updateWelloAppPushReadUsingPut2,
  ),

  //* 회원가입 관련 API
  postProfile: normalizeServerRequest(
    WelloAuthApiService.saveMemberNickNameUsingPost1,
  ),

  //* 회원 신규 맞춤정책 조회 API
  getRecommendPolicyList: normalizeServerRequest(
    WelloAuthApiService.memberNewPolicyListUsingGet1,
  ),

  //* 정책
  getPolicyFeedback: normalizeServerRequest(
    WelloAuthService.metaPolicyFeedbackUsingGet,
  ),

  //* 대상별 정책 API
  getPickPolicyList: normalizeServerRequest(
    WelloAuthApiService.welloPickPolicyListUsingGet2,
  ),

  //* 정책 꾸러미 API
  getSituationPolicyList: normalizeServerRequest(
    WelloAuthApiService.getSituationPolicyListUsingGet2,
  ),

  //* 정책 소식 관련 API
  getPolicyInfoDetail: normalizeServerRequest(
    WelloAuthService.bestContentsListUsingGet2,
  ),

  getPolicyAuthDetail: normalizeServerRequest(
    WelloAuthService.metaPolicyUsingGet2,
  ),

  getPolicyInfoFeedback: normalizeServerRequest(
    WelloAuthService.bestContentsFeedbackUsingGet,
  ),

  //* 댓글 관련 API
  getCommentList: normalizeServerRequest(
    WelloAuthApiService.getWelloCommentListUsingGet1,
  ),
  postComment: normalizeServerRequest(
    WelloAuthApiService.saveWelloCommentUsingPost1,
  ),
  getMyCommentList: normalizeServerRequest(
    WelloAuthApiService.getMyWelloCommentListUsingGet1,
  ),
  getLiveCommentList: normalizeServerRequest(
    WelloAuthApiService.getLiveWelloCommentListUsingGet2,
  ),
  putDeleteComment: normalizeServerRequest(
    WelloAuthApiService.deleteWelloCommentUsingPut1,
  ),
  postCommentReport: normalizeServerRequest(
    WelloAuthApiService.saveWelloCommentReportUsingPost1,
  ),

  //* 좋아요 관련 API
  postLike: normalizeServerRequest(WelloAuthApiService.saveWelloLikeUsingPost1),
  deleteLike: normalizeServerRequest(
    WelloAuthApiService.deleteWelloLikeUsingDelete1,
  ),

  //* 북마크 관련 API
  postBookmark: normalizeServerRequest(
    WelloAuthApiService.saveWishlistUsingPost4,
  ),
  deleteBookmark: normalizeServerRequest(
    WelloAuthApiService.deleteWishlistUsingDelete1,
  ),

  //* 가족 관련 API
  getFamilyList: normalizeServerRequest(
    WelloAuthApiService.getWelloMemberFamilyUsingGet,
  ),

  //! ⚠️ 없어질 수 있음
  getFamily: normalizeServerRequest(
    WelloAuthApiService.getWelloMemberFamilyUsingGet,
  ),

  postFamily: normalizeServerRequest(
    WelloAuthApiService.saveWelloMemberFamilyUsingPost1,
  ),

  putFamily: normalizeServerRequest(
    WelloAuthApiService.updateWelloMemberFamilyUsingPut1,
  ),

  deleteFamily: normalizeServerRequest(
    WelloAuthApiService.deleteWelloMemberFamilyUsingDelete1,
  ),

  //* 동네 소식 콘텐츠 관련 API
  getHometownNewsFeedback: normalizeServerRequest(
    WelloAuthService.hometownNewsFeedbackUsingGet,
  ),
  getHometownList: normalizeServerRequest(
    WelloAuthService.hometownNewsListUsingGet1,
  ),
  getHometownNewsDetail: normalizeServerRequest(
    WelloAuthService.hometownNewsUsingGet2,
  ),

  //* 스크랩 관련 API
  getBestContentsWishlist: normalizeServerRequest(
    WelloAuthApiService.bestContentsWishlistUsingGet1,
  ),
  getHometownNewsWishlist: normalizeServerRequest(
    WelloAuthApiService.hometownNewsWishlistUsingGet1,
  ),
  getPolicyWishlist: normalizeServerRequest(
    WelloAuthApiService.metaPolicyWishlistUsingGet1,
  ),

  //* 문의하기 관련 API
  getInquiryList: normalizeServerRequest(WelloAuthService.inquiryListUsingGet1),

  postInquiry: normalizeServerRequest(WelloAuthService.saveInquiryUsingPost1),

  getInquiry: normalizeServerRequest(WelloAuthService.getInquiryUsingGet),

  //* 멤버 인증 관련 API
  postCertificationCellphone: normalizeServerRequest(
    CommonAuthApiService.sendCertificationCellphoneUsingPost3,
  ),
  postCertificationCheck: normalizeServerRequest(
    CommonAuthApiService.certificationCheckUsingPost2,
  ),

  //* 맞춤 필터 관련 API
  getCustomPolicyFilterData: normalizeServerRequest(
    WelloAuthApiService.requiredFilterUsingGet1,
  ),
  getInterestedFilterData: normalizeServerRequest(
    WelloAuthApiService.interestFilterUsingGet1,
  ),
  postInterestedFilterData: normalizeServerRequest(
    WelloAuthApiService.saveInterestFilterUsingPost,
  ),
  postCustomPolicyFilterData: normalizeServerRequest(
    WelloAuthApiService.saveFitFilterUsingPost,
  ),

  //* 검색 관련 API
  getSearchRecommendPolicyList: normalizeServerRequest(
    WelloAuthService.metaPolicyListUsingGet2,
  ),

  //* PEEC
  postApplyEvent: normalizeServerRequest(
    WelloAuthService.saveWelloParticipationUsingPost,
  ),
  // * KB JOIN EVENT
  postKbPinKey: normalizeServerRequest(KbPayAuthApiService.sendPinkeyUsingPost),

  //* Push 설정
  saveDeviceToken: normalizeServerRequest(
    WelloAuthApiService.saveWelloDeviceInfoUsingPost1,
  ),

  //* 정책 바로신청 API
  getPolicyApplyHistoryList: normalizeServerRequest(
    WelloAuthService.getWelloPolicyApplyListUsingGet,
  ),

  //* NICE API
  sendNiceMessage: normalizeServerRequest(
    AuthNiceService.sendNiceSafeKeyUsingPost,
  ),

  getVerificationId: normalizeServerRequest(
    CommonAuthService.getMemberVerificationIdUsingGet,
  ),
};

/** 외부(장학재단) API */
export const DPG_API = {
  postEligibilityCheck: DpgService.getEligibilityCheck,
  getNotice: DpgService.getNotice,
  getLoanInterestList: DpgService.getLoanInterestList,
  getAgreement: DpgService.getAgreement,
  getReportHistoryList: DpgService.getReportHistoryList,
  getReportHistory: DpgService.getReportHistory,
  postReport: DpgService.saveReport,
  getAddress: DpgService.getAddress,
};

/** 제주청년드림 API */
export const JEJU_DREAM_API = {
  fetchJejuCommon,
  fetchJejuTermList,
  fetchJejuApplyList,
  postJejuCancelConsult,
  fetchJejuSchedule,
  postJejuApply,
  postJejuYouthdreamApply: DpgApiService.sendJejuYouthDreamService,
};

export * from './types';
export { CommonAuthOpenApi, WelloAuthOpenApi };
