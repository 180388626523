import type { ButtonProps } from '@/components/server';

import {
  type PropsWithChildren,
  createContext,
  useContext,
  useMemo,
} from 'react';

type TabContextValue = Pick<ButtonProps, 'size' | 'shape'>;

const TabContext = createContext<TabContextValue | undefined>(undefined);

export const TabContextProvider = ({
  size,
  shape,
  children,
}: PropsWithChildren<TabContextValue>) => {
  const value = useMemo(
    () => ({
      size,
      shape,
    }),
    [size, shape],
  );

  return <TabContext.Provider value={value}>{children}</TabContext.Provider>;
};

export const useTabContext = () => {
  const context = useContext(TabContext);

  if (context === undefined)
    throw new Error('useTabContext must be used within a TabContextProvider');

  return context;
};
