import { createError, type ServerResponse } from '@wello-client/common/utils';
import axios from 'axios';

import { ENV } from '@/constants';

export const postJejuApply = async (postData: JejuPostData) => {
  try {
    const response = await axios.post<ServerResponse>(
      `${ENV.NEXT_PUBLIC_JEJU_API_DOMAIN}/api/wello/api102`,
      postData,
      {
        headers: {
          Authorization: `Basic ${process.env.NEXT_PUBLIC_JEJU_YOUTHDREAM_API_KEY}`,
        },
      },
    );

    return response.data;
  } catch (error) {
    throw createError();
  }
};

// 제주청년 API
interface User {
  uniqId: string;
  name: string;
  phone: string;
  email: string;
  adres: string;
  detailAdres: string;
  birth1: string;
  birth2: string;
}

interface Data {
  adviceFormDate: string;
  adviceFormTime: string;
  adviceUserMemo: string;
  adviceCategory1: string;
  adviceCategory2: string;
  adviceCategory3: string;
  userEducation: string;
  userWorkType: string;
  userImmigrant: string;
  userMarriage: string;
  userChild: string;
  userParticipation: string;
  userInflowTypes: string[];
}

interface JejuPostData {
  user: User;
  data: Data;
}
