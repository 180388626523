import type { FilterMetaData, MetaData } from '@/hooks/useGetFilterMetaData';

import { useState } from 'react';
import { RotateCw } from 'react-feather';

import { Button } from '@/components/server';
import { useToast } from '@/hooks/useToast';

import { Chip } from '..';

import styles from './InterestedPolicyFilterForm.module.scss';

export interface InterestPolicyFilterFormProps
  extends Pick<FilterMetaData, 'interestedCategoryMetaDataList'> {
  initialFilterValues?: MetaData[];
  isSubmitting?: boolean;
  submitButtonLabel?: string;
  onSubmit?: (data: MetaData[]) => void;
}

export const InterestPolicyFilterForm = ({
  interestedCategoryMetaDataList,
  initialFilterValues,
  isSubmitting,
  submitButtonLabel,
  onSubmit,
}: InterestPolicyFilterFormProps) => {
  const initSelectedMetaDataMap = () =>
    new Map<string, MetaData>(
      initialFilterValues?.map((metaData) => {
        if (!metaData.code) throw new Error('metaData.code가 없습니다.');

        return [metaData.code, metaData];
      }),
    );

  const [selectedMetaDataMap, setSelectedMetaDataMap] = useState(
    initSelectedMetaDataMap,
  );

  const selectedCodeList = [...selectedMetaDataMap.keys()];

  const parentsMap = new Map<string, MetaData>();

  const { toast } = useToast();

  return (
    <form
      className={styles.form}
      onSubmit={(e) => {
        e.preventDefault();
        onSubmit?.([...selectedMetaDataMap.values()]);
      }}
    >
      <dl className={styles.filter}>
        {interestedCategoryMetaDataList.map((metaData, index) => {
          const { code, value, parent_code } = metaData;

          if (!code) throw new Error('metaData.code가 없습니다.');

          switch (metaData.level) {
            case 2:
              parentsMap.set(code, metaData);

              return <dt key={index}>{value}</dt>;
            case 3:
              return (
                <dd key={index}>
                  <Chip
                    checked={selectedMetaDataMap.has(code)}
                    size="small"
                    onChange={(e) => {
                      const { checked } = e.target;

                      if (!parent_code)
                        throw new Error('metaData.parent_code가 없습니다.');

                      const isDuplicatable =
                        parentsMap.get(parent_code)?.duplicate_avail_yn;

                      if (checked) {
                        if (isDuplicatable) {
                          selectedMetaDataMap.set(code, metaData);
                        } else {
                          const duplicatedCode = selectedCodeList.filter(
                            (code) => code.includes(parent_code),
                          );

                          duplicatedCode.forEach((code) => {
                            selectedMetaDataMap.delete(code);
                          });

                          selectedMetaDataMap.set(code, metaData);
                        }
                      } else {
                        selectedCodeList.forEach((selectedCode) => {
                          if (selectedCode.includes(code))
                            selectedMetaDataMap.delete(selectedCode);
                        });
                      }

                      setSelectedMetaDataMap(new Map(selectedMetaDataMap));
                    }}
                  >
                    {value}
                  </Chip>
                </dd>
              );
          }
        })}
      </dl>
      <footer className={styles.footer}>
        <Button
          className={styles['reset-button']}
          icon={<RotateCw height="1em" width="1em" />}
          theme="unset"
          onClick={() => {
            setSelectedMetaDataMap(initSelectedMetaDataMap);
            toast({
              message: '초기화되었습니다.',
            });
          }}
        >
          초기화
        </Button>
        <Button
          className={styles['submit-button']}
          loading={isSubmitting}
          shape="capsule"
          type="submit"
        >
          {submitButtonLabel}
        </Button>
      </footer>
    </form>
  );
};
