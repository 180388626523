import { isEnumValue } from '@wello-client/common/utils';

import {
  UNAUTH_API,
  WELLO_POLICY_CODE_FILTER,
  WELLO_POLICY_CODE_TYPE,
} from '@/api';

export type CustomPolicyFilterType = 'basic' | 'detail';

export enum EXCEPTED_CODE {
  REGION = 'C01',
  GENDER = 'C02',
  UNEMPLOYMENT = 'C04-05',
  TARGET_CHARACTERISTICS = 'C12',
  //! ⚠️ 실업일 실제 날짜만 보내면 백엔드에서 처리함
  UNEMPLOYMENT_6_MONTH_UNDER = 'C04-05-01',
  UNEMPLOYMENT_6_MONTH_OVER = 'C04-05-02',
}

export enum EXCEPTED_HIDDEN_CODE {
  //!세종특별자치시 전체
  REGION_SEJONG_ALL = 'C01-08-99',
  //!대상 특성 해당 없음
  TARGET_CHARACTERISTICS_EMPTY = 'C12-99',
}

export type MetaData = Exclude<
  Exclude<
    Awaited<ReturnType<typeof UNAUTH_API.getWelloPolicyCodeAllList>>['context'],
    undefined
  >['contents'],
  undefined
>[number];

interface UseGetFilterMetaDataParams {
  isSearchFilter?: boolean;
  type?: CustomPolicyFilterType;
}

export const useGetFilterMetaData = async (
  params?: UseGetFilterMetaDataParams,
) => {
  const { isSearchFilter } = params ?? {};
  const { context } = await UNAUTH_API.getWelloPolicyCodeAllList();

  let { contents } = context ?? {};

  if (!contents) throw new Error('contents is undefined');

  if (params?.type === 'basic') {
    contents = contents.filter((content) => {
      return (
        content.wello_policy_code_filter_cd === WELLO_POLICY_CODE_FILTER.BASIC
      );
    });
  }

  const tempMetaDataList: MetaData[][] = [];

  contents.forEach((content) => {
    const { level, code } = content;

    if (level === 1) return tempMetaDataList.push([content]);

    const rootMetaData = tempMetaDataList.find(([rootMetaData]) => {
      const rootMetaDataCode = rootMetaData.code;

      return rootMetaDataCode && code ? code.includes(rootMetaDataCode) : false;
    });

    return rootMetaData?.push(content);
  });

  const sortedMetaDataList = tempMetaDataList.flat();

  const customPolicyMetaDataList: MetaData[] = [];

  const tempInterestedCategoryMetaDataList: MetaData[][] = [];

  const regionMetaData: {
    mainRegion: MetaData[];
    subRegion: MetaData[];
  } = {
    mainRegion: [],
    subRegion: [],
  };

  const genderMetaData: MetaData[] = [];

  sortedMetaDataList.forEach((metaData) => {
    const { code, level } = metaData;

    const isHidden =
      code?.includes('99') && !isEnumValue(EXCEPTED_HIDDEN_CODE, code);

    if (metaData.wello_policy_code_filter_cd) {
      if (code?.includes(EXCEPTED_CODE.REGION)) {
        switch (level) {
          case 2:
            if (!isHidden || (isHidden && isSearchFilter))
              regionMetaData.mainRegion.push(metaData);

            return;

          case 3:
            {
              if (!isHidden || (isHidden && isSearchFilter))
                regionMetaData.subRegion.push(metaData);
            }

            return;
        }

        return;
      }

      if (code?.includes(EXCEPTED_CODE.GENDER)) {
        if (level === 2) {
          if (!isHidden || (isHidden && isSearchFilter))
            genderMetaData.push(metaData);
        }

        return;
      }

      if (level === 3 && code?.includes(EXCEPTED_CODE.UNEMPLOYMENT)) return;

      if (isHidden) return;

      return customPolicyMetaDataList.push(metaData);
    }

    if (isHidden) return;

    if (
      metaData.wello_policy_code_type_cd === WELLO_POLICY_CODE_TYPE.INTEREST
    ) {
      switch (level) {
        case 2:
          return tempInterestedCategoryMetaDataList.push([metaData]);

        case 3: {
          const parentMetaData = tempInterestedCategoryMetaDataList.find(
            ([parentsMetaDat]) => {
              const parentMetaDataCode = parentsMetaDat.code;

              return metaData.parent_code === parentMetaDataCode;
            },
          );

          parentMetaData?.push(metaData);
        }
      }
    }
  });

  const interestedCategoryMetaDataList =
    tempInterestedCategoryMetaDataList.flat();

  return {
    customPolicyMetaDataList,
    interestedCategoryMetaDataList,
    genderMetaData,
    regionMetaData,
  };
};

export type FilterMetaData = Awaited<ReturnType<typeof useGetFilterMetaData>>;
